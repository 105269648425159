import { ConfigApi, createApiRef } from "@backstage/core-plugin-api";
import qs from "qs";
import { KmxProxyApi } from "../../onrampCommon";
import { ProposedOnboardConfig } from "../types";

export interface KmxServiceApi {
    validateCatalogFile(repository: string, instanceId?: string): Promise<Response>;
    validateMkdocsFile(repository: string, instanceId?: string): Promise<Response>;
    getOnboardProposedData(onboardingConfig: ProposedOnboardConfig): Promise<Response>;
    writeOnboardProposedData(onboardingConfig: ProposedOnboardConfig): Promise<Response>;
}

export class KmxServiceClient implements KmxServiceApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly serviceApiBaseUrl: string;
    private readonly retryTimeout: number;
    private readonly retryLimit: number;

    constructor(options: {
        kmxProxyApi: KmxProxyApi;
        configApi: ConfigApi;
    }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.serviceApiBaseUrl = options.configApi.getString('serviceApi.baseUrl');
        this.retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
        this.retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');
    }

    async validateCatalogFile(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/ValidateCatalogFile'
        const params = qs.stringify({repository: repository, instanceId: instanceId}, { addQueryPrefix: true, skipNulls: true });
        const metricsUrl = `${this.serviceApiBaseUrl}${path}${params}`;

        const request = { url: metricsUrl, method: 'GET'}
        return await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async validateMkdocsFile(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/ValidateMkdocsFile'
        const params = qs.stringify({repository: repository, instanceId: instanceId}, { addQueryPrefix: true, skipNulls: true });
        const metricsUrl = `${this.serviceApiBaseUrl}${path}${params}`;

        const request = { url: metricsUrl, method: 'GET'}
        return await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async getOnboardProposedData(onboardingConfig: ProposedOnboardConfig): Promise<Response> {
        const path = '/api/GetOnboardProposedData'
        const metricsUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: onboardingConfig};
        return await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async writeOnboardProposedData(onboardingConfig: ProposedOnboardConfig): Promise<Response> {
        const path = '/api/WriteOnboardProposedData'
        const metricsUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: onboardingConfig};
        return await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }
}

export const kmxServiceApiRef = createApiRef<KmxServiceApi>({
    id: 'kmxserviceapi',
});

