import React, { useMemo } from 'react';
import {
    Select as MaterialSelect,
    MenuItem as MaterialMenuItem,
    SelectChangeEvent,
    InputLabel,
    FormControl,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';
import { v4 as uuid } from 'uuid';

type SelectProps<T> = {
    label: string;
    options: { label: string; value: T }[];
    value: T | undefined;
    onChange: (event: SelectChangeEvent<T>, child: React.ReactNode) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

const useStyles = makeStyles<OnrampTheme>(theme => ({
    label: {
        width: '100%',
        textAlign: 'left',
    },
    default: { width: '100%', marginBottom: theme.spacing(1) },
    wrapper: { marginBottom: theme.spacing(0.5) },
}));

export const Select = <T extends string | number>({
    label,
    options,
    value,
    onChange,
    onBlur,
    disabled,
}: SelectProps<T>) => {
    const styles = useStyles();
    const labelId = useMemo(() => uuid(), []);

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel id={labelId}>{label}</InputLabel>
            <MaterialSelect
                labelId={labelId}
                label={label}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                className={styles.default}
                disabled={disabled}
                margin="dense"
            >
                {options.map((o, i) => (
                    <MaterialMenuItem key={i} value={o.value}>
                        {o.label}
                    </MaterialMenuItem>
                ))}
            </MaterialSelect>
        </FormControl>
    );
};
