// Import React.
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import { useApi, configApiRef } from '@backstage/core-plugin-api';

// Import Backstage.
import {
    Sidebar,
    SidebarPage,
    sidebarConfig,
    SidebarItem,
    SidebarDivider,
    SidebarSpace,
    SidebarSubmenu,
    useSidebarOpenState,
    SidebarGroup,
} from '@backstage/core-components';
import { Shortcuts } from '@backstage/plugin-shortcuts';
import { SearchModalProvider, SidebarSearchModal } from '@backstage/plugin-search';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSubmenuItem } from './SidebarSubmenuItem-BS/SidebarSubmenuItem';

// Import Material UI.
import { Link } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Import custom.
import { PLUGIN_KEYS, isPluginEnabled } from '../../plugins/index';
import { KmxLogoFull, KmxLogoIcon } from '../logo';
import { navigationItems, NavigationItem } from './navigationItems';
import { SearchWithShortcutKey } from './SearchWithShortcutKey';
import { makeStyles } from '@mui/styles';
import { useNavigateToHash } from './useNavigateToHash';
import { useUpdateHorizonTheme } from './useUpdateHorizonTheme';

const useSidebarLogoStyles = makeStyles({
    root: {
        width: sidebarConfig.drawerWidthClosed,
        height: 3 * sidebarConfig.logoHeight,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: -14,
    },
    link: {
        width: sidebarConfig.drawerWidthClosed,
        marginLeft: 24,
    },
    subMenuItem: {
        '& button': {
            '& span': {
                justifyContent: 'left',
                overflow: 'visible',
                textOverflow: 'unset',
                textTransform: 'none',
            },
            '& svg:nth-of-type(2)': {
                right: '15px',
                position: 'relative',
            },
        },
        width: '100%',
    },
});

const SidebarLogo = () => {
    const classes = useSidebarLogoStyles();
    const { isOpen } = useSidebarOpenState();

    return (
        <div className={classes.root}>
            <Link component={NavLink} to="/" underline="none" className={classes.link}>
                {isOpen ? <KmxLogoFull /> : <KmxLogoIcon />}
            </Link>
        </div>
    );
};

const OnrampNavigationItem = ({ item }: { item: NavigationItem }) => {
    const items = item.items ?? [];
    const classes = useSidebarLogoStyles();

    return (
        <SidebarItem icon={item.icon} text={item.title} to={item.link}>
            {items.length === 0 ? null : (
                <SidebarSubmenu title={item.title}>
                    {items.map((subItem, j) => (
                        <div key={j} className={classes.subMenuItem}>
                            <SidebarSubmenuItem
                                icon={subItem.icon}
                                to={subItem.link}
                                title={subItem.title}
                                dropdownItems={subItem.items?.map(subSubItem => ({
                                    title: subSubItem.title,
                                    to: subSubItem.link,
                                }))}
                            />
                        </div>
                    ))}
                </SidebarSubmenu>
            )}
        </SidebarItem>
    );
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
    const config = useApi(configApiRef);

    // always default to enabled plugins
    const isShortcutsEnabled = isPluginEnabled(config, PLUGIN_KEYS.shortcuts);

    useNavigateToHash();
    useUpdateHorizonTheme();

    return (
        <SidebarPage>
            <Sidebar>
                <SidebarLogo />
                <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
                    <SearchModalProvider>
                        <SidebarSearchModal />
                        <SearchWithShortcutKey />
                    </SearchModalProvider>
                </SidebarGroup>
                <SidebarDivider />
                {navigationItems.map((item, i) => (
                    <OnrampNavigationItem key={i} item={item} />
                ))}
                <SidebarSpace />
                <SidebarDivider />
                {isShortcutsEnabled ? <Shortcuts /> : <></>}
                <SidebarSettings />
            </Sidebar>
            {children}
        </SidebarPage>
    );
};
