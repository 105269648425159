/* eslint-disable no-param-reassign */
import { useState } from 'react';

type HistoryUpdateMode = 'push' | 'replace';
type Options = { mode?: HistoryUpdateMode; nullDeletes?: boolean };

const handleParam = (key: string, value: string | undefined, options: Options = {}) => {
    const { mode = 'replace', nullDeletes = true } = options;
    // Parse current query string using the browser's URLSearchParams API.
    const params = new URLSearchParams(location.search);

    // If the passed value is undefined, check if the URL already contains
    // a value for it. This is important on initial page load.
    if (value === undefined) value = params.get(key) ?? '';
    // If the passed value is null and the nullDeletes option is
    // set to true, delete the corresponding query parameter.
    if (value === null && nullDeletes) params.delete(key);
    // Else use the provided value and key to set the query parameter.
    else params.set(key, value!);

    // Construct URL containing the updated query parameter(s).
    const target = location.pathname.replace(/\/$/, '') + (params.toString() ? `?${params.toString()}` : '');

    // Update the browser URL. Supports the option 'historyState = (replace|push)'
    // to either add to the browser history or replace the last item.
    if (mode === 'replace') {
        history.replaceState({ path: value }, '', target);
    } else if (mode === 'push') {
        history.pushState({ path: value }, '', target);
    }

    return value;
};

const useQueryParam = (
    key: string,
    value?: string,
    options?: Options,
): [string | undefined, (newValue: string, override?: Options) => void] => {
    // Relies on useState to trigger component rerenders on calls to setParam.
    const [param, setParam] = useState(handleParam(key, value, options));
    return [
        param,
        // 'override' allows changing the hook-level 'options' for
        // individual 'setQueryParam' calls.
        (newValue: string, override?: Options) => setParam(handleParam(key, newValue, { ...options, ...override })),
    ];
};

export default useQueryParam;

