import { Theme } from '@mui/material';
import React from 'react';
import { OnrampTheme, useOnrampTheme } from '../../../../theme';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme, OnrampTheme>(_theme => ({
    default: {
        color: onrampTheme => onrampTheme.palette.link,
        '&:hover': {
            color: onrampTheme => onrampTheme.palette.linkHover,
        },
    },
}));

export function LinkText({ children, className, ...rest }: any) {
    const onrampTheme = useOnrampTheme();
    const styles = useStyles(onrampTheme);

    return (
        <a {...rest} className={classNames(styles.default, className)}>
            {children}
        </a>
    );
}
