import { Config } from '@backstage/config';

export type AzureCostPluginConfig = {
    costUrl: string;
    baseUrl: string;
    issuesUrl: string;
}

export function readCostPluginConfig(
    config: Config,
  ): AzureCostPluginConfig {
    
    const provider: AzureCostPluginConfig = { costUrl: "", baseUrl: "", issuesUrl: "" };

    provider.costUrl = `${config.getString('metricsApi.url')}/api/costs`;
    provider.issuesUrl = `${config.getString('metricsApi.url')}/api/azure-resources/issues`;
    provider.baseUrl = config.getString('app.baseUrl');

    return provider;
}