import { Config } from '@backstage/config';

export type AppInsightsPluginConfig = {
  metricsUrl: string;
  baseUrl: string;
}

export function readAppInsightsPluginConfig(
    config: Config,
  ): AppInsightsPluginConfig {
    
    const provider: AppInsightsPluginConfig = { metricsUrl: "", baseUrl: "" };

    provider.metricsUrl = `${config.getString('metricsApi.url')}/api/metrics`;
    provider.baseUrl = config.getString('app.baseUrl');

    return provider;
}