import { createApiRef } from '@backstage/core-plugin-api';
import {
    OrchestrationEntity,
    OrchestrationEntityId,
    FunctionInstance,
    ActivityTrackingStateEntity,
    OrchStatusResponse,
    ApprovalDetail,
    CallbackEventsStateEntity,
} from '../types/orchestration';
import { ApxApiClient } from './ApxApiClient';

export interface ApxOrchestrationApi {
    getActivityTrackingEntity(id: string): Promise<ActivityTrackingStateEntity>;
    getOrchStatus(id: string): Promise<OrchStatusResponse>;
    getOrchApprovalStatus(request: {
        id: string;
        callbackEventName: string;
        requestType: string;
    }): Promise<ApprovalDetail[]>;
    getCallbackTrackingEntity(id: string): Promise<CallbackEventsStateEntity>;
    getEntities(entityName?: string): Promise<OrchestrationEntity[]>;
    updateEntityState(entityId: OrchestrationEntityId, operationName: string, contentJson: any): Promise<void>;
    getEntityState<T>(entityId: OrchestrationEntityId): Promise<T>;
    getApxInstances(): Promise<FunctionInstance[]>;
    getApxInstancesByStatus(status: string): Promise<FunctionInstance[]>;
    getInstance(instanceId: string): Promise<FunctionInstance>;
    terminateInstance(instanceId: string): Promise<void>;
}

export class ApxOrchestrationClient implements ApxOrchestrationApi {
    private readonly apxApiClient: ApxApiClient;

    constructor(options: { apxApiClient: ApxApiClient }) {
        this.apxApiClient = options.apxApiClient;
    }

    async getActivityTrackingEntity(id: string): Promise<ActivityTrackingStateEntity> {
        return await this.getEntityState<ActivityTrackingStateEntity>({ name: 'activitytrackingstate', key: id });
    }

    async getOrchStatus(id: string): Promise<OrchStatusResponse> {
        return await this.apxApiClient
            .fetch(`/api/orchestratorquery/orch-status`, {
                method: 'POST',
                body: JSON.stringify({ id }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getOrchApprovalStatus(request: {
        id: string;
        callbackEventName: string;
        requestType: string;
    }): Promise<ApprovalDetail[]> {
        return await this.apxApiClient
            .fetch(`/api/orchestratorquery/orch-approval-status`, {
                method: 'POST',
                body: JSON.stringify({
                    id: request.id,
                    orchestrationType: request.requestType,
                    callbackName: request.callbackEventName,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getCallbackTrackingEntity(id: string): Promise<CallbackEventsStateEntity> {
        return await this.getEntityState<CallbackEventsStateEntity>({ name: 'callbackeventsstate', key: id });
    }

    async getEntities(entityName: string): Promise<OrchestrationEntity[]> {
        const response = await this.apxApiClient.fetch(`/api/orchestratorquery/entities/${entityName ?? ''}`);

        return await response.json();
    }

    async updateEntityState(entityId: OrchestrationEntityId, operationName: string, contentJson: any): Promise<void> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/entities/${entityId.name}/${entityId.key}/${operationName}`, {
                method: 'POST',
                body: contentJson,
            })
            .then(async response => {
                if (!response.ok) {
                    throw Error(`Http ${response.status}`);
                }
            });
    }

    async getEntityState<T>(entityId: OrchestrationEntityId): Promise<T> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/entities/${entityId.name}/${entityId.key}`)
            .then(async response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const text = await response.text();
                return text ? JSON.parse(text) : text;
            });
    }

    async getApxInstances(): Promise<FunctionInstance[]> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/apx-instances`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getApxInstancesByStatus(status: string): Promise<FunctionInstance[]> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/apx-instances-by-status?status=${status}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async getInstance(instanceId: string): Promise<FunctionInstance> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/instances/${instanceId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            });
    }

    async terminateInstance(instanceId: string): Promise<void> {
        return this.apxApiClient
            .fetch(`/api/orchestratorquery/apx-terminate-instance/${instanceId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
            });
    }
}

export const apxOrchestrationApiRef = createApiRef<ApxOrchestrationApi>({
    id: 'applicationprovisioning-orchestration',
});

