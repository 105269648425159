import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { readOnrampUsagePluginConfig } from './config';
import { TermCount, DateCount, NameCountDate } from './types';
import { KmxProxyApi } from '../onrampCommon';

export interface OnrampUsageApi {
    getSearchTerms(): Promise<TermCount[]>;
    getUniqueVisitors(): Promise<DateCount[]>;
    getComponentPageViews(): Promise<NameCountDate[]>;
    getNumberOfTeamsOnboarded(): Promise<DateCount[]>;
    getNumberOfComponentsOnboarded(): Promise<DateCount[]>;
}

export class OnrampUsageClient implements OnrampUsageApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly configApi: ConfigApi;
    private readonly retryTimeout: number;
    private readonly retryLimit: number;

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.configApi = options.configApi;
        this.retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
        this.retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');
    }

    async getSearchTerms(): Promise<TermCount[]> {
        const path = '/search-terms';

        const payload = await this.getUsageApiResponse(path);
        return payload.map((value: any) => {
            return { term: value.extractedName, count: value.count };
        });
    }

    async getUniqueVisitors(): Promise<DateCount[]> {
        const path = '/unique-visitors';

        const payload = await this.getUsageApiResponse(path);
        return payload.map((value: any) => {
            return { time: value.timeGenerated, count: value.uniqueVisitors };
        });
    }

    async getComponentPageViews(): Promise<NameCountDate[]> {
        const path = '/component-page-views';

        const payload = await this.getUsageApiResponse(path);
        return payload.map((value: any) => {
            return {
                name: value.extractedName,
                time: value.timeGenerated,
                count: value.count,
            };
        });
    }

    async getNumberOfTeamsOnboarded(): Promise<DateCount[]> {
        const path = '/teams-onboarded';

        const payload = await this.getUsageApiResponse(path);
        return payload.map((value: any) => {
            return {
                time: value.timeGenerated,
                count: value.count,
            };
        });
    }

    async getNumberOfComponentsOnboarded(): Promise<DateCount[]> {
        const path = '/components-onboarded';

        const payload = await this.getUsageApiResponse(path);
        return payload.map((value: any) => {
            return {
                time: value.timeGenerated,
                count: value.count,
            };
        });
    }

    private async getUsageApiResponse(path: string): Promise<any> {
        const metricsBaseUrl = readOnrampUsagePluginConfig(
            this.configApi,
        ).usageEndpoint;

        const metricsUrl = `${metricsBaseUrl}${path}`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy',
            {
                url: metricsUrl,
                method: 'GET',
            },
            this.retryLimit,
            this.retryTimeout
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return await response.json();
    }
}

export const onrampUsageApiRef = createApiRef<OnrampUsageApi>({
    id: 'onrampusage',
});

