import { rootRouteRef } from './routes';
import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';
import { 
    kmxServiceApiRef, 
    KmxServiceClient, 
    kmxTechdocsApiRef, 
    KmxTechdocsClient 
} from './apis';
import { kmxProxyApiRef } from '../onrampCommon';


export const onrampSupportPlugin = createPlugin({
    id: 'onramp-support-plugin',
    apis: [
        createApiFactory({
            api: kmxServiceApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new KmxServiceClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
        createApiFactory({
            api: kmxTechdocsApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new KmxTechdocsClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const SupportPage = onrampSupportPlugin.provide(
    createRoutableExtension({
        name: 'SupportPage',
        component: () =>
            import('./pages/SupportPage').then(
                m => m.SupportPage,
            ),
        mountPoint: rootRouteRef,
    }),
);
