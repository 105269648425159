import React from 'react';
import Alert from '@mui/lab/Alert';
import { ErrorPanel, Progress } from '@backstage/core-components';
import { useAsync } from 'react-use';

type AsyncDataProps<T> = {
    fetchData: () => Promise<T>;
} & (
    | {
          renderData: (data: T) => React.ReactElement;
          alwaysRender?: false;
      }
    | {
          renderData: (data?: T) => React.ReactElement;
          alwaysRender: true;
      }
);

export const AsyncData = <T extends unknown>({ fetchData, renderData, alwaysRender }: AsyncDataProps<T>) => {
    const { value, loading, error } = useAsync(async () => await fetchData());

    if (loading) {
        return <Progress />;
    } else if (error) {
        return <ErrorPanel error={error} />;
    } else if (value === undefined && !alwaysRender) {
        return <Alert severity="error">Unable to load data</Alert>;
    }

    return renderData(value!);
};
