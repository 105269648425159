import { Config } from '@backstage/config';

export type OnrampUsagePluginConfig = {
  usageEndpoint: string;
}

export function readOnrampUsagePluginConfig(
    config: Config,
  ): OnrampUsagePluginConfig {
    
    const provider: OnrampUsagePluginConfig = { usageEndpoint: "" };

    provider.usageEndpoint = `${config.getString('metricsApi.url')}/api/backstage-metrics`;

    return provider;
}