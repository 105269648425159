import { createAsyncThunk } from '@reduxjs/toolkit';

import { values, propOr, head } from 'ramda';
import { apxFetch } from '../../../apis/ApxApiClient';
import {
    BaseAppArchetype,
    AppArchetype,
    InProgressRequest,
    CreateProvisioningRequestResponse,
    ProvisioningRequestSubmission,
} from './types';

function getProvisioningRequestBody(request: InProgressRequest): ProvisioningRequestSubmission | undefined {
    switch (request.request.kind) {
        case BaseAppArchetype.AppProvisioning:
            return { draftRequestId: request.id, applicationProvisioningRequest: request.request };
        case BaseAppArchetype.Apigee:
            return { draftRequestId: request.id, apigeeRequest: request.request };
        case BaseAppArchetype.Package:
            switch (request.appArchetypeDetails.appArchetype) {
                case AppArchetype.NpmPackage:
                    return { draftRequestId: request.id, npmPackageRequest: request.request };
                case AppArchetype.NuGetPackage:
                    return { draftRequestId: request.id, nuGetPackageRequest: request.request };
                default:
                    break;
            }
            break;
        case BaseAppArchetype.SalesforceDxPackage:
            return { draftRequestId: request.id, salesforceDxRequest: request.request };
        case BaseAppArchetype.ConsoleApp:
            return {
                draftRequestId: request.id,
                consoleApplicationRequest: request.request,
            };
        case BaseAppArchetype.ApplicationTransfer:
            return {
                draftRequestId: request.id,
                applicationTransferRequest: request.request,
            };
        case BaseAppArchetype.CloudAccount:
            return {
                draftRequestId: request.id,
                cloudAccountRequest: request.request,
            };
        case BaseAppArchetype.AzureTagging:
            return {
                draftRequestId: request.id,
                azureTaggingRequest: request.request,
            };
        case BaseAppArchetype.AzureSecurityGroups:
            return {
                draftRequestId: request.id,
                azureSecurityGroupsRequest: request.request,
            };
        case BaseAppArchetype.IamRequest:
            return {
                draftRequestId: request.id,
                iamRequest: request.request,
            };
        case BaseAppArchetype.ServicePrincipalRequest:
            return {
                draftRequestId: request.id,
                servicePrincipalRequest: request.request,
            };
        case BaseAppArchetype.EnterpriseGatewayApi:
            return {
                draftRequestId: request.id,
                enterpriseGatewayRequest: request.request,
            };
        case BaseAppArchetype.GitHubRepo:
            return {
                draftRequestId: request.id,
                gitHubRepoRequest: request.request,
            };
        default:
            console.error(`Unsupported app archetype: ${request.appArchetypeDetails.baseAppArchetype ?? 'N/A'}`);
            break;
    }

    return undefined;
}

export const fetchDraftProvisioningRequests = createAsyncThunk<InProgressRequest[]>(
    'inProgressProvisioningRequests/fetchDraftProvisioningRequests',
    async () => {
        return await apxFetch('/api/draft-provisioning-requests', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        }).then(response => response.json());
    },
);

export const fetchDraftProvisioningRequest = createAsyncThunk<InProgressRequest, string>(
    'inProgressProvisioningRequests/fetchDraftProvisioningRequest',
    async id => {
        return await apxFetch(`/api/draft-provisioning-requests/${id}`, {
            method: 'GET',
        }).then(response => response.json());
    },
);

export const upsertDraftProvisioningRequest = createAsyncThunk<void, InProgressRequest>(
    'inProgressProvisioningRequests/upsertDraftProvisioningRequest',
    async request => {
        if (!request.request || !request.id) {
            // No need to persist at this stage in the process, or for requests that don't have an "id" property
            return;
        }

        await apxFetch(`/api/draft-provisioning-requests/${request.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
        }).then(() => {});
    },
);

export const deleteDraftProvisioningRequest = createAsyncThunk<void, string>(
    'inProgressProvisioningRequests/deleteDraftProvisioningRequest',
    async id => {
        return await apxFetch(`/api/draft-provisioning-requests/${id}`, {
            method: 'DELETE',
        }).then(() => {});
    },
);

export const createProvisioningRequest = createAsyncThunk<CreateProvisioningRequestResponse, InProgressRequest>(
    'inProgressProvisioningRequests/createProvisioningRequest',
    async (request: InProgressRequest) => {
        return await apxFetch('/api/provisioning-requests', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(getProvisioningRequestBody(request)),
        }).then(async response => {
            if (response.status >= 200 && response.status < 300) {
                const responseContent = await response.json();
                const requestId = propOr('', 'id', responseContent) as string;

                return {
                    index: request.index,
                    id: request.id,
                    success: true,
                    errorMessages: [] as string[],
                    requestId,
                };
            }

            const responseContent = await response.json();
            const errors: { [key: string]: string[] } = propOr({}, 'errors', responseContent);
            const errorMessages = values(errors).map<string>(head);

            return { index: request.index, id: request.id, success: false, errorMessages };
        });
    },
);
