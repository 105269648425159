import { CatalogTable } from '@backstage/plugin-catalog';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import React from 'react';

const defaultNameSort: 'asc' | 'desc' | undefined = 'asc';

const createGroupNameColumn = (options?: { defaultKind?: string }) => {
    return {
        title: 'Name',
        field: 'resolved.name',
        highlight: true,
        defaultSort: defaultNameSort,
        render: ({ entity }: { entity: Entity }) => (
            <EntityRefLink
                entityRef={entity}
                defaultKind={options?.defaultKind || 'Group'}
                title={entity.metadata?.title}
            />
        ),
    };
};

const createParentColumn = () => {
    return {
        title: 'Parent',
        field: 'entity.spec.parent',
    };
};

const customFactory = {
    createParentColumn,
    createGroupNameColumn,
    ...CatalogTable.columns,
};

export const groupColumns = [
    {
        ...customFactory.createGroupNameColumn(),
        width: '25%',
        sorting: true,
    },
    { ...customFactory.createMetadataDescriptionColumn(), width: '40%' },
    { ...customFactory.createParentColumn(), width: '25%' },
    { ...customFactory.createSpecTypeColumn(), width: '10%' },
];
