import React from 'react';
import { Theme, Typography } from '@mui/material';
import { OnrampTheme, useOnrampTheme } from '../../../../theme';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';

export interface DisplayProps {
    level: 1 | 2 | 3 | 4;
    className?: string;
    children: React.ReactNode;
}

const useStyles = makeStyles<Theme, OnrampTheme>(_theme => ({
    default: {
        color: onrampTheme => onrampTheme.palette.primary?.main,
    },
}));

export function Display(props: DisplayProps) {
    const onrampTheme = useOnrampTheme();
    const styles = useStyles(onrampTheme);

    switch (props.level) {
        case 1:
            return (
                <Typography className={classNames(styles.default, props.className)} variant="h3">
                    {props.children}
                </Typography>
            );
        case 2:
            return (
                <Typography className={classNames(styles.default, props.className)} variant="h4">
                    {props.children}
                </Typography>
            );
        case 3:
            return (
                <Typography className={classNames(styles.default, props.className)} variant="h5">
                    {props.children}
                </Typography>
            );
        case 4:
            return (
                <Typography className={classNames(styles.default, props.className)} variant="h6">
                    {props.children}
                </Typography>
            );
        default:
            return <div>{props.children}</div>;
    }
}
