import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import { FinePrint } from '../typography';
import { makeStyles, createStyles } from '@mui/styles';

const SAVING_TIMEOUT = 2000;
const SAVED_VISIBILITY_DURATION = 2000;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'absolute',
            bottom: '0',
            right: '0',
            paddingRight: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        fadeOutItem: {
            animation: `$effectAppearDisappear ${SAVED_VISIBILITY_DURATION}ms ${theme.transitions.easing.easeInOut}`,
            animationFillMode: 'forwards',
        },
        '@keyframes effectAppearDisappear': {
            '0%': {
                opacity: 0,
            },
            '10%': {
                opacity: 1,
            },
            '66%': {
                opacity: 1,
            },
            '100%': {
                opacity: 0,
            },
        },
    }),
);

export function PersistenceIndicator(props: { valid: boolean; persisting: boolean }) {
    const styles = useStyles();
    const [didPersist, setDidPersist] = useState(false);

    const [_valid, setValid] = useState(props.valid);
    const isMounted = useRef<boolean>(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(
        debounce(() => {
            if (!isMounted.current) {
                return;
            }

            setValid(oldValid => {
                if (oldValid) {
                    setDidPersist(true);

                    setTimeout(() => {
                        if (isMounted.current) {
                            setDidPersist(false);
                        }
                    }, SAVED_VISIBILITY_DURATION);
                }

                return oldValid;
            });
        }, SAVING_TIMEOUT),
        [],
    );

    useEffect(() => {
        setValid(props.valid);
        if (props.persisting || props.valid) {
            debounced();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.persisting, props.valid]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    if (didPersist) {
        return (
            <div className={styles.wrapper}>
                <FinePrint className={styles.fadeOutItem}>Saved</FinePrint>
            </div>
        );
    }

    return null;
}
