import { CatalogApi } from '@backstage/catalog-client';
import { Entity } from '@backstage/catalog-model';
import { createApiRef } from '@backstage/core-plugin-api';
import qs, { ParsedQs } from 'qs';

declare type DiscoveryApi = {
    getBaseUrl(pluginId: string): Promise<string>;
};

declare type FetchApi = {
    fetch: typeof fetch;
};

export type GroupMember = {
    name: string;
    role: string;
    editMode: boolean;
};

export interface KmxGroupsApi {
    getGroupByName(groupName: string): Promise<Entity>;
    getGroups(groupType?: string , groupParent?: string): Promise<string[]>;
    getGroupObjects(groupType?: string , groupParent?: string): Promise<Entity[]>;
    getGroupMembers(groupName: string): Promise<GroupMember[]>;
    getEmployees(): Promise<string[]>;
    getAncestor(groupName: string): Promise<string>;
    getQueryStringValue(
        key: string | number,
        queryString: string,
    ): string | string[] | ParsedQs | ParsedQs[] | undefined;
}

export class KmxGroupsClient implements KmxGroupsApi {
    private readonly catalogApi: CatalogApi;

    constructor(options: {
        catalogApi: CatalogApi;
    }) {
        this.catalogApi = options.catalogApi;
    }

    async getGroupByName(groupName: string): Promise<Entity> {
        const filter: Record<string, string> = { kind: 'Group', 'metadata.name': groupName };

        const catalogResponse = await this.catalogApi.getEntities({ filter });
        return catalogResponse.items[0];
    }

    async getGroups(groupType?: string, groupParent?: string): Promise<string[]> {
        const filter: Record<string, string> = { kind: 'Group' };
        if (groupType) {
            filter['spec.type'] = groupType;
        }
        if (groupParent) {
            filter['spec.parent'] = groupParent;
        }
        const catalogResponse = await this.catalogApi.getEntities({ filter });
        return catalogResponse.items.map(entity => entity.metadata.name);
    }

    async getGroupObjects(groupType?: string, groupParent?: string): Promise<Entity[]> {
        const filter: Record<string, string> = { kind: 'Group' };
        if (groupType) {
            filter['spec.type'] = groupType;
        }
        if (groupParent) {
            filter['spec.parent'] = groupParent;
        }
        const catalogResponse = await this.catalogApi.getEntities({ filter });
        return catalogResponse.items;
    }

    async getGroupMembers(groupName: string): Promise<GroupMember[]> {
        const filter: Record<string, string> = { kind: 'Group' };
        if (groupName) {
            filter['metadata.name'] = groupName;
        }

        const catalogResponse = await this.catalogApi.getEntities({ filter });
        const group = catalogResponse.items[0];
        let membersWithRoles: GroupMember[] = [];
        if (group) {
            let memberRoles: GroupMember[];
            if (group.metadata.annotations?.userRoles) {
                memberRoles = JSON.parse(group.metadata.annotations?.userRoles) as GroupMember[];
            }

            const memberNames = group.spec?.members as string[] ?? [];
            membersWithRoles = memberNames.map(memberName => {
                const memberRole = memberRoles
                    ? memberRoles.find(role => {
                          return role.name === memberName;
                      })?.role ?? 'Team Member'
                    : 'Team Member';
                return {
                    name: memberName,
                    role: memberRole,
                    editMode: false,
                };
            });
        }

        return membersWithRoles;
    }

    async getEmployees(): Promise<string[]> {
        const filter: Record<string, string> = { kind: 'User' };
        const catalogResponse = await this.catalogApi.getEntities({ filter });
        return catalogResponse.items.map(entity => entity.metadata.name);
    }

    async getAncestor(groupName: string): Promise<string> {
        let parent: string = '';
        const filter: Record<string, string> = {
            kind: 'Group',
            'metadata.name': groupName,
        };
        const catalogResponse = await this.catalogApi.getEntities({ filter });
        if (catalogResponse.items && catalogResponse.items.length > 0) {
            const group = catalogResponse.items[0];
            parent = group.spec?.parent as string;
        } else {
            // eslint-disable-next-line no-console
            console.warn(`Unable to locate parent for \"${groupName}\" - are you sure this is correct?`);
        }
        return parent;
    }

    getQueryStringValue = (
        key: string | number,
        queryString = window.location.search,
    ): string | string[] | ParsedQs | ParsedQs[] | undefined => {
        const values = qs.parse(queryString, { ignoreQueryPrefix: true });
        return values[key];
    };
}

export const kmxGroupsApiRef = createApiRef<KmxGroupsApi>({
    id: 'kmxgroupsapi',
});
