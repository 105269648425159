import React from 'react';
import { Column } from './Column';
import type { ColumnProps } from './Column';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

const useStyles = makeStyles<OnrampTheme>(theme => ({ default: { paddingBottom: theme.spacing(2) } }));

export const WorkflowColumn = (props: ColumnProps) => {
    const { className, ...columnProps } = props;
    const styles = useStyles();

    return <Column className={classNames(styles.default, className)} {...columnProps} />;
};
