import {
    ConfigApi,
    createApiRef,
    IdentityApi,
} from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';
import { ResponseError } from '@backstage/errors';
import type { MonitoredEntityResponse, SystemStatusResponse } from '../models';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { OnrampApi } from '../../onrampCommon';

declare type DiscoveryApi = {
    getBaseUrl(pluginId: string): Promise<string>;
};

declare type FetchApi = {
    fetch: typeof fetch;
};

export interface ServiceHealthApi {
    getOwnedEntityNames(): Promise<string[]>;
    getSystems(): Promise<MonitoredEntityResponse[]>;
    getSystemStatuses(): Promise<SystemStatusResponse[]>;
    getSystemStatus(name: string): Promise<SystemStatusResponse>;
}

export class ServiceHealthClient implements ServiceHealthApi {
    private readonly onrampApi: OnrampApi;
    private readonly catalogApi: CatalogApi;
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly fetchApi: FetchApi;

    constructor(options: {
        onrampApi: OnrampApi;
        catalogApi: CatalogApi;
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
        configApi: ConfigApi;
        fetchApi?: FetchApi;
    }) {
        this.onrampApi = options.onrampApi;
        this.catalogApi = options.catalogApi;
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.fetchApi = options.fetchApi || { fetch: crossFetch };
    }

    async getOwnedEntityNames(): Promise<string[]> {
        const ownerGroups = await this.onrampApi.getGroupsForUser();

        const owners = ownerGroups.map(ownerGroup =>
            stringifyEntityRef({
                kind: ownerGroup.kind,
                namespace: ownerGroup.metadata.namespace,
                name: ownerGroup.metadata.name,
            }),
        );

        const ownedEntitiesList = await this.catalogApi.getEntities({
            filter: [
                {
                    'relations.ownedBy': owners,
                },
            ],
            fields: ['metadata.name'],
        });

        return ownedEntitiesList.items.map(item => item.metadata.name);
    }

    async getSystems(): Promise<MonitoredEntityResponse[]> {
        const response = await this.request('GET', '/systems');
        return response.body;
    }

    async getSystemStatuses(): Promise<SystemStatusResponse[]> {
        const response = await this.request('GET', '/system-statuses');
        return response.body;
    }
    async getSystemStatus(name: string): Promise<SystemStatusResponse> {
        const response = await this.request('GET', `/system-statuses/${name}`);
        return response.body;
    }

    private async request(
        method: string,
        path: string,
        body?: any,
        additionalHeaders?: Record<string, string>,
    ): Promise<{ body: any; response: Response }> {
        const baseUrl = `${await this.discoveryApi.getBaseUrl(
            'service-health',
        )}`;

        const token = (await this.identityApi.getCredentials()).token;
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            ...(additionalHeaders ?? {}),
        };

        const response = await this.fetchApi.fetch(`${baseUrl}${path}`, {
            method,
            headers,
            body: body === undefined ? undefined : JSON.stringify(body),
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        try {
            return {
                body: await response.json(),
                response,
            };
        } catch {
            return { body: undefined, response };
        }
    }
}

export const serviceHealthApiRef = createApiRef<ServiceHealthApi>({
    id: 'servicehealth',
});

