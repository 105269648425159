import React from 'react';
import { ExternalLink } from './ExternalLink';

export function DevExSurveyLink(props: { text: string }) {
    return (
        <ExternalLink href="https://forms.office.com/r/Nn05M0XMxC">
            {props.text}
        </ExternalLink>
    );
}
