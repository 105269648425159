import React from 'react';
import { Typography } from '@mui/material';

export interface FinePrintProps {
    className?: string;
    children: React.ReactNode;
}

export function FinePrint(props: FinePrintProps) {
    return (
        <Typography variant="caption" className={props.className}>
            {props.children}
        </Typography>
    );
}
