import React from 'react';
import { Grid } from '@mui/material';
import {
    RELATION_API_CONSUMED_BY,
    RELATION_API_PROVIDED_BY,
    RELATION_CONSUMES_API,
    RELATION_DEPENDENCY_OF,
    RELATION_DEPENDS_ON,
    RELATION_HAS_PART,
    RELATION_PART_OF,
    RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import {
    EntityDependencyOfComponentsCard,
    EntityDependsOnComponentsCard,
    EntityDependsOnResourcesCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '../../components/EntityCatalogGraphCard';
import { Direction } from '@backstage/plugin-catalog-graph';
import { RELATION_OWNED_BY, RELATION_OWNER_OF, RELATION_PARENT_OF, RELATION_CHILD_OF } from '@backstage/catalog-model';
import type { TabContent } from './types';

export const SiteOrServiceDependenciesTab: TabContent = {
    path: '/dependencies',
    title: 'Dependencies',
    element: (
        <Grid container spacing={3} alignItems="stretch">
            <Grid item md={8}>
                <EntityDependsOnComponentsCard variant="gridItem" />
            </Grid>
            <Grid item md={8}>
                <EntityDependsOnResourcesCard variant="gridItem" />
            </Grid>
            <Grid item md={6}>
                <EntityCatalogGraphCard
                    variant="gridItem"
                    height={400}
                    kinds={['Component', 'API']}
                    maxDepth={Number.POSITIVE_INFINITY}
                    relations={[
                        RELATION_CONSUMES_API,
                        RELATION_API_CONSUMED_BY,
                        RELATION_OWNED_BY,
                        RELATION_OWNER_OF,
                        RELATION_PROVIDES_API,
                        RELATION_API_PROVIDED_BY,
                    ]}
                />
            </Grid>
        </Grid>
    ),
};

export const LibraryDependenciesTab: TabContent = {
    path: '/dependencies',
    title: 'Dependencies',
    element: (
        <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
                <EntityDependsOnComponentsCard variant="gridItem" />
            </Grid>
            <Grid item md={6}>
                <EntityDependencyOfComponentsCard variant="gridItem" />
            </Grid>
        </Grid>
    ),
};

export const GroupDependenciesTab: TabContent = {
    path: '/organization',
    title: 'Organization',
    element: (
        <Grid container spacing={3}>
            <Grid item xs={10} md={6}>
                <EntityCatalogGraphCard
                    variant="gridItem"
                    height={700}
                    direction={Direction.TOP_BOTTOM}
                    kinds={['Group']}
                    maxDepth={1}
                    relations={[RELATION_PARENT_OF, RELATION_CHILD_OF]}
                />
            </Grid>
        </Grid>
    ),
};

export const SystemDependenciesTab: TabContent = {
    path: '/diagram',
    title: 'Diagram',
    element: (
        <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.TOP_BOTTOM}
            title="System Diagram"
            height={700}
            relations={[
                RELATION_PART_OF,
                RELATION_HAS_PART,
                RELATION_API_CONSUMED_BY,
                RELATION_API_PROVIDED_BY,
                RELATION_CONSUMES_API,
                RELATION_PROVIDES_API,
                RELATION_DEPENDENCY_OF,
                RELATION_DEPENDS_ON,
            ]}
            unidirectional={false}
        />
    ),
};
