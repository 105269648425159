import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { kmxProxyApiRef, onrampApiRef } from '../onrampCommon';
import { apxApiRef, ApxClient, apxOrchestrationApiRef, ApxOrchestrationClient } from './apis';
import { ApxApiClient } from './apis/ApxApiClient';
import {
    rootRouteRef,
    myAppsRouteRef,
    adminRouteRef,
    adminViewEntitiesByIdRouteRef,
    adminViewEntitiesRouteRef,
    adminViewInstanceByIdRouteRef,
    adminViewInstancesRouteRef,
    adminViewAppRequestsRouteRef,
    adminViewAppRequestByIdRouteRef,
    adminOrchestrationStatusByIdRouteRef,
    cloudExNeedHelpRouteRef,
} from './routes';
import { ApimClient, apimApiRef } from './apis/apimApi';

export const applicationProvisioningPlugin = createPlugin({
    id: 'backstage-apx-plugin',
    apis: [
        createApiFactory({
            api: apxApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
                onrampApi: onrampApiRef,
            },
            factory: ({ kmxProxyApi, configApi, onrampApi }) =>
                new ApxClient({
                    apxApiClient: new ApxApiClient({
                        kmxProxyApi,
                        configApi,
                        onrampApi,
                    }),
                }),
        }),
        createApiFactory({
            api: apxOrchestrationApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
                onrampApi: onrampApiRef,
            },
            factory: ({ kmxProxyApi, configApi, onrampApi }) =>
                new ApxOrchestrationClient({
                    apxApiClient: new ApxApiClient({
                        kmxProxyApi,
                        configApi,
                        onrampApi,
                    }),
                }),
        }),
        createApiFactory({
            api: apimApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                identityApi: identityApiRef,
            },
            factory: ({ discoveryApi, identityApi }) =>
                new ApimClient({
                    discoveryApi,
                    identityApi,
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
        myApps: myAppsRouteRef,
        admin: adminRouteRef,
        adminViewEntitiesById: adminViewEntitiesByIdRouteRef,
        adminViewEntities: adminViewEntitiesRouteRef,
        adminViewInstanceById: adminViewInstanceByIdRouteRef,
        adminViewInstances: adminViewInstancesRouteRef,
        adminViewAppRequests: adminViewAppRequestsRouteRef,
        adminViewAppRequestById: adminViewAppRequestByIdRouteRef,
        adminOrchestrationStatusById: adminOrchestrationStatusByIdRouteRef,
        cloudExNeedHelp: cloudExNeedHelpRouteRef,
    },
    featureFlags: [],
});

export const ApxRootPage = applicationProvisioningPlugin.provide(
    createRoutableExtension({
        name: 'ApxRootPage',
        component: () => import('./pages/ApxRootPage').then(m => m.ApxRootPage),
        mountPoint: rootRouteRef,
    }),
);
