import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { Chip } from '@mui/material';

const getName = (entity: Entity): string => {
    return entity.metadata?.title || humanizeEntityRef(entity);
};

const entitySort = (item1: CatalogTableRow, item2: CatalogTableRow) => {
    return getName(item1.entity).toLowerCase().localeCompare(getName(item2.entity).toLowerCase());
};

const tagPillCallback = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    window.location.href = `${event.currentTarget.baseURI}&filters%5Btags%5D=${event.currentTarget.innerText}`;
};

const defaultNameSort: 'asc' | 'desc' | undefined = 'asc';

export const catalogColumns = [
    {
        ...CatalogTable.columns.createNameColumn(),
        width: '25%',
        sorting: true,
        defaultSort: defaultNameSort,
        customSort: entitySort,
    },
    CatalogTable.columns.createOwnerColumn(),
    CatalogTable.columns.createSpecTypeColumn(),
    CatalogTable.columns.createSpecLifecycleColumn(),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    {
        ...CatalogTable.columns.createTagsColumn(),
        render: ({ entity }: { entity: Entity }) => (
            <>
                {entity.metadata.tags &&
                    entity.metadata.tags.map(t => (
                        <Chip
                            key={t}
                            label={t}
                            size="small"
                            variant="outlined"
                            style={{ marginBottom: '0px' }}
                            onClick={e => tagPillCallback(e)}
                        />
                    ))}
            </>
        ),
    },
];
