import React from 'react';
import { ExternalLink } from './ExternalLink';

export function DevExTeamLink(props: { text: string }) {
    return (
        <ExternalLink href="https://teams.microsoft.com/l/channel/19:7632ea3ea74142f2bee21f5de4ea2bd5%40thread.tacv2/CarMax%20Onramp%20%28Backstage%29?groupId=6ee9a21f-ee97-44c2-b3db-b3db14b3bfce&tenantId=ed38c4bc-a204-4511-8009-34c0612c882a">
            {props.text}
        </ExternalLink>
    );
}
