import {
    rootRouteRef
} from './routes';
import {
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

export const responsibilitiesPlugin = createPlugin({
    id: 'devex-responsibilities',
    apis: [],
    routes: {
        root: rootRouteRef,
    },
});

export const ResponsibilitiesRootPage = responsibilitiesPlugin.provide(
    createRoutableExtension({
        name: 'ResponsibilitiesRootPage',
        component: () =>
            import('./pages/ResponsibilitiesRootPage').then(m => m.ResponsibilitiesRootPage),
        mountPoint: rootRouteRef,
    }),
);
