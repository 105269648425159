import {
    rootRouteRef
} from './routes';
import {
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

export const apiKeyGeneratorPlugin = createPlugin({
    id: 'apiKeyGenerator',
    apis: [],
    routes: {
        root: rootRouteRef,
    },
});

export const ApiKeyGeneratorHomePage = apiKeyGeneratorPlugin.provide(
    createRoutableExtension({
        name: 'ApiKeyGeneratorHomePage',
        component: () =>
            import('./pages/ApiKeyGeneratorHomePage').then(m => m.ApiKeyGeneratorHomePage),
        mountPoint: rootRouteRef,
    }),
);

