import React from 'react';
import { ExternalLink } from './ExternalLink';

export function ApxAdxSupportLink(props: { text: string }) {
    return (
        <ExternalLink href="https://teams.microsoft.com/l/channel/19%3aebf0a3156c5e4752a2f27c18c5cc6b61%40thread.skype/APX%2520-%2520ADX%2520Support?groupId=c0d54b5c-2982-4cf3-8ec3-fbee3c800775&tenantId=ed38c4bc-a204-4511-8009-34c0612c882a">
            {props.text}
        </ExternalLink>
    );
}
