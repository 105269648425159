import { createRouteRef, createExternalRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
    id: 'backstage-appinsights-plugin',
});

export const scaffolderRouteRef = createExternalRouteRef({
    id: 'backstage-devopsmetrics-plugin-scaffolder',
});

