import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, CATALOG_FILTER_EXISTS } from '@backstage/plugin-catalog-react';
import { SearchFilter, useSearch } from '@backstage/plugin-search-react';
import { Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    filter: {
        '& + &': {
            marginTop: theme.spacing(2.5),
        },
    },
    filters: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));

export const OnrampSearchFilters = () => {
    const classes = useStyles();
    const { types } = useSearch();
    const catalogApi = useApi(catalogApiRef);

    // TODO: Expand this as more filtering is added for different result types
    if (!types.includes('techdocs')) {
        return null;
    }

    return (
        <Paper className={classes.filters}>
            {types.includes('techdocs') && (
                <SearchFilter.Select
                    className={classes.filter}
                    label="Entity"
                    name="name"
                    values={async () => {
                        // Return a list of entities which are documented.
                        const { items } = await catalogApi.getEntities({
                            fields: ['metadata.name'],
                            filter: {
                                'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
                            },
                        });

                        const names = items.map(entity => entity.metadata.name);
                        names.sort();
                        return names;
                    }}
                />
            )}
            {/* <SearchFilter.Select
        className={classes.filter}
        label="Kind"
        name="kind"
        values={['Component', 'Template']}
    /> */}
            {/* <SearchFilter.Checkbox
        className={classes.filter}
        label="Lifecycle"
        name="lifecycle"
        values={['experimental', 'production']}
    /> */}
        </Paper>
    );
};
