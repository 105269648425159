import { serviceHealthApiRef, ServiceHealthClient } from './apis';
import { rootRouteRef, detailsRouteRef, entityDetailsRouteRef } from './routes';
import {
    configApiRef,
    createApiFactory,
    createComponentExtension,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { onrampApiRef } from '../onrampCommon';

export const serviceHealthPlugin = createPlugin({
    id: 'backstage-servicehealth-plugin',
    apis: [
        createApiFactory({
            api: serviceHealthApiRef,
            deps: {
                onrampApi: onrampApiRef,
                catalogApi: catalogApiRef,
                discoveryApi: discoveryApiRef,
                identityApi: identityApiRef,
                configApi: configApiRef,
            },
            factory: ({ onrampApi, catalogApi, discoveryApi, identityApi, configApi }) =>
                new ServiceHealthClient({
                    onrampApi,
                    catalogApi,
                    discoveryApi,
                    identityApi,
                    configApi,
                }),
        }),
    ],
    externalRoutes: {
        entity: entityDetailsRouteRef,
    },
    routes: {
        root: rootRouteRef,
        details: detailsRouteRef,
    },
});

export const ServiceHealthRootPage = serviceHealthPlugin.provide(
    createRoutableExtension({
        name: 'ServiceHealthRootPage',
        component: () => import('./pages/ServiceHealthRootPage').then(m => m.ServiceHealthRootPage),
        mountPoint: rootRouteRef,
    }),
);

export const EntityServiceHealthContent = serviceHealthPlugin.provide(
    createComponentExtension({
        name: 'EntityServiceHealthContent',
        component: {
            lazy: () => import('./pages/EntityServiceHealthContent').then(m => m.EntityServiceHealthContent),
        },
    }),
);

