import React, { useState, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { IconButton, TableRow, TableCell } from '@mui/material';
import { kmxTeamRoles } from '../../constants';
import { GroupMember } from '../../apis/api';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';

type EditEmployeeRowProps = {
    rowIndex: number;
    member: GroupMember;
    memberList: GroupMember[];
    setMemberList: React.Dispatch<React.SetStateAction<GroupMember[]>>;
};

export const EditEmployeeRow = ({ rowIndex, member, memberList, setMemberList }: EditEmployeeRowProps) => {
    const [existingEmployeeNewRole, setExistingEmployeeNewRole] = useState<string>('');

    const onSelectExistingEmployeeRole = useCallback(
        (event: SelectChangeEvent<string>) => {
            setExistingEmployeeNewRole(event.target.value as string);
        },
        [setExistingEmployeeNewRole],
    );

    const onSaveEditEmployeeButtonClick = useCallback(
        (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
            const list = [...memberList];
            list[index].role = existingEmployeeNewRole;
            list[index].editMode = false;
            setMemberList(list);
        },
        [existingEmployeeNewRole, memberList, setMemberList],
    );

    const onEditEmployeeButtonClick = useCallback(
        (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number, edit: boolean) => {
            const list = [...memberList];
            for (let i = 0; i < list.length; i++) {
                if (i === index) {
                    list[i].editMode = edit;
                    setExistingEmployeeNewRole(list[i].role);
                } else {
                    list[i].editMode = false;
                }
            }

            setMemberList(list);
        },
        [memberList, setExistingEmployeeNewRole, setMemberList],
    );

    const onRemoveEmployeeButtonClick = useCallback(
        (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
            const list = [...memberList];
            list.splice(index, 1);
            setMemberList(list);
        },
        [memberList, setMemberList],
    );

    return (
        <TableRow key={member.name}>
            <TableCell component="th" scope="row">
                {member.name}
            </TableCell>
            <TableCell align="right">
                {member.editMode ? (
                    <FormControl variant="outlined" fullWidth>
                        <Select
                            onChange={onSelectExistingEmployeeRole}
                            label="Team Role"
                            labelId="label-for-editrole-select"
                            value={existingEmployeeNewRole}
                        >
                            {(kmxTeamRoles ?? []).map((role, index) => {
                                return (
                                    <MenuItem value={role} key={index}>
                                        {role}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        <InputLabel id="label-for-editrole-select">Team Role</InputLabel>
                    </FormControl>
                ) : (
                    member.role
                )}
            </TableCell>
            <TableCell align="right">
                {member.editMode ? (
                    <>
                        <IconButton
                            edge="end"
                            aria-label="save"
                            onClick={e => onSaveEditEmployeeButtonClick(e, rowIndex)}
                        >
                            <SaveOutlined />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="cancel"
                            onClick={e => onEditEmployeeButtonClick(e, rowIndex, false)}
                        >
                            <CancelOutlined />
                        </IconButton>
                    </>
                ) : (
                    <>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={e => onRemoveEmployeeButtonClick(e, rowIndex)}
                        >
                            <DeleteOutline />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={e => onEditEmployeeButtonClick(e, rowIndex, true)}
                        >
                            <EditOutlined />
                        </IconButton>
                    </>
                )}
            </TableCell>
        </TableRow>
    );
};
