import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Display } from '../typography';
import { Modal } from './Modal';
import { makeStyles } from '@mui/styles';

type ConfirmDialogProps = {
    children: (showModal: () => void) => React.ReactNode;
    onCancel?: () => void;
    onConfirm?: () => void;
    title: string;
    details?: React.ReactNode;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
    className?: string;
};

const useStyles = makeStyles(_theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
}));

export function ConfirmDialog(props: ConfirmDialogProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const styles = useStyles();

    const handleCancellation = () => {
        setModalVisible(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onCancel && props.onCancel();
    };

    const handleConfirmation = () => {
        setModalVisible(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        props.onConfirm && props.onConfirm();
    };

    const showModal = () => {
        setModalVisible(true);
    };

    return (
        <React.Fragment>
            {modalVisible && (
                <Modal onClose={handleCancellation} className={props.className}>
                    <Display level={2}>{props.title}</Display>
                    <div>{props.details}</div>
                    <div className={styles.buttonContainer}>
                        <Button onClick={handleCancellation}>{props.cancelButtonLabel || 'Cancel'}</Button>
                        <Button onClick={handleConfirmation}>{props.confirmButtonLabel || 'OK'}</Button>
                    </div>
                </Modal>
            )}
            {props.children(showModal)}
        </React.Fragment>
    );
}
