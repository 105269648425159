import { DashboardIcon, InfoCard, InfoCardVariants, Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { ConditionallyVisible } from '../../../plugins/applicationProvisioning/components/common';

/** @public */
export const GroupsLinksCard = (props: { variant?: InfoCardVariants }) => {
    const { variant } = props;
    const { entity } = useEntity();
    const groupName = entity.kind === 'Group' ? entity.metadata.name : null;

    return (
        <InfoCard title="Links" variant={variant}>
            <Grid container spacing={3}>
                <Grid item md={10} xl={11}>
                    <List>
                        <ConditionallyVisible when={groupName != null}>
                            <ListItem>
                                <ListItemIcon>
                                    <Tooltip title="Metric">
                                        <DashboardIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText>
                                    <Link to={`/devops-metrics/groups/${groupName}`}>DevOps Metrics</Link>
                                </ListItemText>
                            </ListItem>
                        </ConditionallyVisible>
                    </List>
                </Grid>
            </Grid>
        </InfoCard>
    );
};
