import { CircularProgress as MaterialUiCircularProgress } from '@mui/material';

import React, { useMemo } from 'react';

type CircularProgressProps = {
    size?: 'large' | 'medium' | 'small' | 'x-small';
};

export const CircularProgress = (props: CircularProgressProps) => {
    const size = useMemo(() => {
        switch (props.size) {
            case 'large':
                return '128px';
            case 'medium':
                return '64px';
            case 'small':
                return '32px';
            case 'x-small':
                return '16px';
            default:
                return '32px';
        }
    }, [props.size]);

    return <MaterialUiCircularProgress size={size} />;
};
