import { IconButton, Popover, Typography } from '@mui/material';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { useCallback } from 'react';
import React from 'react';

export interface CopyButtonProps {
    getCopyText: () => string;
}

export function CopyButton({ getCopyText }: CopyButtonProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = useCallback(
        async (event: React.MouseEvent<HTMLElement>) => {
            await navigator.clipboard.writeText(getCopyText());
            setAnchorEl(event.target as any);
        },
        [getCopyText],
    );

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton aria-label="copy" color="primary" onClick={handleClick}>
                <CopyIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>Copied.</Typography>
            </Popover>
        </>
    );
}

