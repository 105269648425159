import { apigeeApiRef, ApigeeClient } from './api';
import { rootRouteRef, createApplicationRouteRef, applicationRouteRef } from './routes';
import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { kmxProxyApiRef } from '../onrampCommon';

export const apigeePlugin = createPlugin({
    id: 'apigee',
    apis: [
        createApiFactory({
            api: apigeeApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                identityApi: identityApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, identityApi, configApi }) =>
                new ApigeeClient({
                    kmxProxyApi,
                    identityApi,
                    configApi,
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
        create: createApplicationRouteRef,
        application: applicationRouteRef,
    },
});

export const ApigeeRootPage = apigeePlugin.provide(
    createRoutableExtension({
        name: 'ApigeeRootPage',
        component: () => import('./pages/ApigeeRootPage').then(m => m.ApigeeRootPage),
        mountPoint: rootRouteRef,
    }),
);

