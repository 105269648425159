import React from 'react';
import { DevExTeamLink } from '../external-links';

export function NewTemplateRequest() {
    return (
        <p>
            Don't see what you're looking for? <br /> Request new templates <DevExTeamLink text="here" />.
        </p>
    );
}

