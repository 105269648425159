import { ConfigApi, createApiRef } from "@backstage/core-plugin-api";
import { KmxProxyApi } from "../onrampCommon";
import { MessagingProviderModel, PeekDLQResponse, MessagingProviderQueueModel } from './types'

declare type FetchApi = {
    fetch: typeof fetch
}

export interface QueueManagementApi {
    getMessagingProviders(): Promise<MessagingProviderModel[]>;
    getMessagingQueues(providerId: string, filters: string, withMessagesOnly: boolean): Promise<MessagingProviderQueueModel[]>;
    getQueue(providerId: string, queueId: string): Promise<MessagingProviderQueueModel>;
    purgeDLQ(providerId: string, queueId: string): Promise<void>;
    peekDLQ(providerId: string, queueId: string): Promise<PeekDLQResponse>;
    replayDLQ(providerId: string, queueId: string, replayCount: number): Promise<void>;
}

export class QueueManagementClient implements QueueManagementApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly serviceApiBaseUrl: string;
    private readonly retryTimeout: number;
    private readonly retryLimit: number;

    constructor(options: {
        kmxProxyApi: KmxProxyApi;
        configApi: ConfigApi;
    }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.serviceApiBaseUrl = options.configApi.getString(
            'serviceApi.baseUrl',
        );
        this.retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
        this.retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');
    }

    async getMessagingProviders(): Promise<MessagingProviderModel[]> {
        const path = '/api/messaging-providers';
        const getProvidersUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = { url: getProvidersUrl, method: 'GET', body: null};
        const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);

        let result: MessagingProviderModel[] = JSON.parse(await response.text());
        return result;
    };

    async getMessagingQueues(
        providerId: string,
        filters: string,
        withMessagesOnly: boolean): Promise<MessagingProviderQueueModel[]> {
            const path = `/api/queues?id=${providerId}&filters=${filters}&withmessagesonly=${withMessagesOnly}`;
            const getQueuesUrl = `${this.serviceApiBaseUrl}${path}`;

            const request = {url: getQueuesUrl, method: 'GET', body: null};
            const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);

            let result: MessagingProviderQueueModel[] = JSON.parse(await response.text());
            return result;
    };

    async getQueue(providerId: string, queueId: string): Promise<MessagingProviderQueueModel> {
        const path = `/api/queueid?id=${providerId}&queueid=${queueId}`;
        const getQueueUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = {url: getQueueUrl, method: 'GET', body: null};
        const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);

        let result: MessagingProviderQueueModel = JSON.parse(await response.text());
        return result;
    };

    async purgeDLQ(providerId: string, queueId: string): Promise<void> {
        const path = `/api/purge-dlq?id=${providerId}&queueid=${queueId}`;
        const purgeQueueUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = {url: purgeQueueUrl, method: 'POST', body: null};
        await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
        return;
    };

    async peekDLQ(providerId: string, queueId: string): Promise<PeekDLQResponse> {
        const path = `/api/peek-dlq?id=${providerId}&queueid=${queueId}`;
        const getQueueUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = {url: getQueueUrl, method: 'GET', body: null};
        const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);

        let responseText = await response.text();
        console.log(responseText);
        let result: PeekDLQResponse = JSON.parse(responseText);
        return result;
    }

    async replayDLQ(providerId: string, queueId: string, replayCount: number): Promise<void> {
        const path = `/api/replay-dlq?id=${providerId}&queueid=${queueId}&replayCount=${replayCount}`;
        const replayQueueUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = {url: replayQueueUrl, method: 'POST', body: null};
        await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request, this.retryLimit, this.retryTimeout);
        return;
    }
}

export const queueManagementApiRef = createApiRef<QueueManagementApi>({
    id: 'messaging-api',
});
