import React from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CostItem } from '../classes';
import { useOnrampTheme } from '../../../theme';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            display: false,
        },
        tooltip: {
            callbacks: {
                label: (item: any) => `$${item.raw}`,
            },
        },
    },
    scales: {
        y: {
            ticks: {
                callback: (value: unknown) => `$${value}`,
            },
        },
    },
};

type CostHistoryGraphProps = {
    values: CostItem[];
};

export const CostHistoryGraph = ({ values }: CostHistoryGraphProps) => {
    const theme = useOnrampTheme();
    const data = values
        .reverse()
        .map(val => ({ label: val.nameOfMonth, value: val.cost }));

    return (
        <div>
            <Bar
                options={options}
                data={{
                    labels: data.map(x => x.label),
                    datasets: [
                        {
                            label: '',
                            data: data.map(x => x.value),
                            backgroundColor: theme.palette.charts.positive,
                        },
                    ],
                }}
            />
        </div>
    );
};

