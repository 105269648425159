import type { RootState } from '../../store';
import type {
    InProgressRequest,
    InProgressApplicationRequest,
    InProgressPackageRequest,
    InProgressApigeeRequest,
    InProgressSalesforceDxRequest,
    InProgressConsoleAppRequest,
    AppArchetypeDetails,
} from '.';
import {
    BaseAppArchetype,
    InProgressApplicationTransferRequest,
    InProgressCloudAccountRequest,
    InProgressAzureSecurityGroupsRequest,
    InProgressAzureTaggingRequest,
    InProgressIamRequest,
    InProgressServicePrincipalRequest,
    NewAppStep,
    InProgressEnterpriseGatewayApiRequest,
} from './types';
import { isCiOnlyMode } from './util';
import { ResourceNeedType } from '../../../types/template-request';

export const selectCurrentRequest = (state: RootState): InProgressRequest =>
    state.InProgressProvisioningRequests.current;
export const selectCurrentApplicationRequest = (state: RootState): Partial<InProgressApplicationRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.AppProvisioning:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentNugetRequest = (state: RootState): Partial<InProgressPackageRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.Package:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentApigeeRequest = (state: RootState): Partial<InProgressApigeeRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.Apigee:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentSalesforceDxRequest = (state: RootState): Partial<InProgressSalesforceDxRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.SalesforceDxPackage:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentConsoleAppRequest = (state: RootState): Partial<InProgressConsoleAppRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.ConsoleApp:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentApplicationTransferRequest = (
    state: RootState,
): Partial<InProgressApplicationTransferRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.ApplicationTransfer:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};

export const selectCurrentCloudAccountRequest = (state: RootState): Partial<InProgressCloudAccountRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.CloudAccount:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};

export const selectCurrentAzureSecurityGroupsRequest = (
    state: RootState,
): Partial<InProgressAzureSecurityGroupsRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.AzureSecurityGroups:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentAzureTaggingRequest = (state: RootState): Partial<InProgressAzureTaggingRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.AzureTagging:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};
export const selectCurrentIamRequest = (state: RootState): Partial<InProgressIamRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.IamRequest:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};

export const selectCurrentServicePrincipalRequest = (state: RootState): Partial<InProgressServicePrincipalRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.ServicePrincipalRequest:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};

export const selectCurrentEnterpriseGatewayApiRequest = (
    state: RootState,
): Partial<InProgressEnterpriseGatewayApiRequest> => {
    switch (state.InProgressProvisioningRequests.current.request.kind) {
        case BaseAppArchetype.EnterpriseGatewayApi:
            return state.InProgressProvisioningRequests.current.request;
        default:
            return {};
    }
};

export const selectInProgressRequests = (state: RootState) => state.InProgressProvisioningRequests.all;

export const selectInProgressRequest = (state: RootState, id: string) =>
    Object.values(state.InProgressProvisioningRequests.all).find(req => req.id === id);

export const selectCurrentAppArchetypeDetails = (state: RootState): AppArchetypeDetails =>
    state.InProgressProvisioningRequests.current.appArchetypeDetails;

export const selectCurrentStep = (state: RootState) => state.InProgressProvisioningRequests.current.currentStep;
export const selectMaxStepIndex = (state: RootState) => state.InProgressProvisioningRequests.current.maxStepIndex;
export const selectCurrentSteps = (state: RootState) => state.InProgressProvisioningRequests.current.steps;

export const selectCurrentStepDetails = (state: RootState) =>
    state.InProgressProvisioningRequests.current.steps.find(
        step => step.id === state.InProgressProvisioningRequests.current.currentStep,
    );

export const selectCurrentStepIndex = (state: RootState) =>
    state.InProgressProvisioningRequests.current.steps.findIndex(
        step => step.id === state.InProgressProvisioningRequests.current.currentStep,
    );

export const selectIsCiOnlyMode = (state: RootState) => isCiOnlyMode(state.InProgressProvisioningRequests.current);
export const selectGitHubCodeGenEnabled = (state: RootState) => {
    const steps = selectCurrentSteps(state);
    return steps.find(s => s.id === NewAppStep.GitHubCodeGen || s.id === NewAppStep.GitHubTeam) !== undefined;
};
export const selectAzureSubscriptionEnabled = (state: RootState) => {
    const steps = selectCurrentSteps(state);
    return steps.find(s => s.id === NewAppStep.AzureSubscription) !== undefined;
};
export const selectSingleRegion = (state: RootState) => {
    return (state.InProgressProvisioningRequests.current.props ?? {})['singleRegion'] === true;
};
export const selectSpoGroupsNeedType = (state: RootState): ResourceNeedType | undefined => {
    const resourceNeedType = (state.InProgressProvisioningRequests.current.props ?? {})['spoGroupsNeedType'];
    return resourceNeedType === undefined ? undefined : ResourceNeedType[resourceNeedType as ResourceNeedType];
};
