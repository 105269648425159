import React from 'react';
import { toPairs } from 'ramda';
import { pascalCase } from 'change-case';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(_theme => ({
    propertyNameContainer: { textAlign: 'left' },
}));

type ObjectDisplayProps = { mode?: 'raw' | 'readable'; value: { [key: string]: any } };

export function ObjectDisplay(props: ObjectDisplayProps) {
    const styles = useStyles();

    if (props.mode === 'raw') {
        return <pre>{JSON.stringify(props.value, undefined, 3)}</pre>;
    }

    const renderValue = (value: any): any => {
        if (Array.isArray(value)) {
            return (
                <table>
                    <tbody>
                        <tr>
                            {value.map((v, i) => (
                                <td key={i}>{renderValue(v)}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            );
        }

        switch (typeof value) {
            case 'boolean':
            case 'string':
            case 'number':
                return value.toString();
            case 'object':
                return <ObjectDisplay value={value} mode={props.mode} />;
            default:
                return `Not supported: ${typeof value}`;
        }
    };

    return (
        <table>
            <tbody>
                {toPairs(props.value).map(([key, value], i) => (
                    <tr key={i}>
                        <th className={styles.propertyNameContainer}>{pascalCase(key, { delimiter: ' ' })}</th>
                        <td>{renderValue(value)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
