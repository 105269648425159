import {
    createApiRef,
    ConfigApi,
    IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { readApigeePluginConfig } from './config';

import { ApigeeDeveloper, ApigeeDeveloperApp, ApigeeApiProduct } from './types';
import { KmxProxyApi } from '../onrampCommon';

export interface ApigeeApi {
    getDeveloper(): Promise<ApigeeDeveloper | undefined>;

    createDeveloper(args: {
        firstName: string;
        lastName: string;
    }): Promise<ApigeeDeveloper>;

    getDeveloperApp(appName: string): Promise<ApigeeDeveloperApp>;

    getDeveloperApps(): Promise<{ name: string }[]>;

    createDeveloperApp(
        applicationName: string,
        apiProductName: string,
    ): Promise<boolean>;

    getApiProducts(): Promise<ApigeeApiProduct[]>;
}

export class ApigeeClient implements ApigeeApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly identityApi: IdentityApi;
    private readonly configApi: ConfigApi;

    private orgName = 'carmax';

    constructor(options: {
        kmxProxyApi: KmxProxyApi;
        identityApi: IdentityApi;
        configApi: ConfigApi;
    }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.identityApi = options.identityApi;
        this.configApi = options.configApi;
    }

    // https://apidocs.apigee.com/docs/developers/1/routes/organizations/%7Borg_name%7D/developers/get
    async getDeveloper(): Promise<ApigeeDeveloper | undefined> {
        const { email } = await this.identityApi.getProfileInfo();

        try {
            return await this.performDeveloperApiRequest(
                'GET',
                `/organizations/${this.orgName}/developers/${email}`,
            );
        } catch (error: any) {
            if (error.response?.status === 404) {
                return undefined;
            }

            throw error;
        }
    }

    // https://apidocs.apigee.com/docs/developers/1/routes/organizations/%7Borg_name%7D/developers/post
    async createDeveloper({
        firstName,
        lastName,
    }: {
        firstName: string;
        lastName: string;
    }): Promise<ApigeeDeveloper> {
        let { email } = await this.identityApi.getProfileInfo();

        email = email ?? '';
        const userName = email
            .substring(0, email.indexOf('@'))
            .replace(/_/g, ' ')
            .split(' ')
            .map(x => `${x[0].toUpperCase()}${x.substring(1)}`)
            .join(' ');

        return await this.performDeveloperApiRequest(
            'POST',
            `/organizations/${this.orgName}/developers/`,
            { email, firstName, lastName, userName },
        );
    }

    // https://apidocs.apigee.com/docs/developer-apps/1/routes/organizations/%7Borg_name%7D/developers/%7Bdeveloper_email%7D/apps/%7Bapp_name%7D/get
    async getDeveloperApp(appName: string): Promise<ApigeeDeveloperApp> {
        const { email } = await this.identityApi.getProfileInfo();

        return await this.performDeveloperApiRequest(
            'GET',
            `/organizations/${this.orgName}/developers/${email}/apps/${appName}`,
        );
    }

    // https://apidocs.apigee.com/docs/developers/1/routes/organizations/%7Borg_name%7D/developers/get
    async getDeveloperApps(): Promise<{ name: string }[]> {
        const developer = await this.getDeveloper();

        return (developer?.apps ?? []).map(name => ({ name }));
    }

    // https://apidocs.apigee.com/docs/developer-apps/1/routes/organizations/%7Borg_name%7D/developers/%7Bdeveloper_email%7D/apps/post
    async createDeveloperApp(
        applicationName: string,
        apiProductName: string,
    ): Promise<boolean> {
        const { email } = await this.identityApi.getProfileInfo();

        try {
            await this.performDeveloperApiRequest(
                'POST',
                `/organizations/${this.orgName}/developers/${email}/apps`,
                { apiProducts: [apiProductName], name: applicationName },
            );

            return true;
        } catch {
            return false;
        }
    }

    // https://apidocs.apigee.com/docs/api-products/1/routes/organizations/%7Borg_name%7D/apiproducts/get
    async getApiProducts(): Promise<ApigeeApiProduct[]> {
        const productNames: string[] = await this.performDeveloperApiRequest(
            'GET',
            `/organizations/${this.orgName}/apiproducts`,
        );

        return productNames.map(name => ({ name }));
    }

    private async performDeveloperApiRequest(
        method: 'GET' | 'POST',
        path: string,
        body?: any,
    ): Promise<any> {
        const developerApiBaseUrl = readApigeePluginConfig(
            this.configApi,
        ).developerApiUrl;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'apigeeproxy',
            {
                url: `${developerApiBaseUrl}${path}`,
                method,
                body,
            }
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return await response.json();
    }
}

export const apigeeApiRef = createApiRef<ApigeeApi>({
    id: 'apigee',
});

