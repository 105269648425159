import React from 'react';

interface ConditionallyVisibleProps {
    when?: boolean;
    children: React.ReactNode;
}

export function ConditionallyVisible(props: ConditionallyVisibleProps) {
    return <>{props.when ? props.children : <></>}</>;
}

