import React from 'react';
import { useOnrampTheme } from '../../../theme';

type ApigeeDeveloperPortalIconProps = {
    monochrome?: boolean;
    width?: number;
    height?: number;
};

export const ApigeeDeveloperPortalIcon = ({
    monochrome,
    width,
    height,
}: ApigeeDeveloperPortalIconProps) => {
    const theme = useOnrampTheme();
    const primaryColor = monochrome
        ? theme.palette.icons.apigee.monochrome
        : theme.palette.icons.apigee.primary;
    const secondaryColor = monochrome
        ? theme.palette.icons.apigee.monochrome
        : theme.palette.icons.apigee.secondary;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 81 58"
            fill="#fff"
            fillRule="evenodd"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            width={width ?? 20}
            height={height ?? 20}
        >
            <g stroke="none">
                <path
                    d="M40 5.886a22.36 22.36 0 0 1 16 6.4l4-4a28.28 28.28 0 0 0-40 0h0l4 4a22.36 22.36 0 0 1 16-6.4zm0 44.8a22.36 22.36 0 0 1-16-6.4l-4 4a28.28 28.28 0 0 0 40 0h0l-4-4a22.36 22.36 0 0 1-16 6.4z"
                    fill={secondaryColor}
                    fillRule="nonzero"
                />
                <path
                    d="M40 40.566a12.24 12.24 0 0 1-12-9.36H12.44v9.52L0 28.286l12.44-12.4v9.52H28a12.24 12.24 0 0 1 12-9.36h0a12.24 12.24 0 0 1 12 9.36h15.64v-9.56L80 28.286l-12.44 12.48v-9.56H52a12.24 12.24 0 0 1-12 9.36zm0-18.72a6.44 6.44 0 0 0-4.554 10.994 6.44 6.44 0 0 0 10.994-4.554h0a6.44 6.44 0 0 0-6.44-6.44z"
                    fill={primaryColor}
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};

