import React, { useState, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { TextField, IconButton, TableRow, TableCell } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/base';

import { kmxTeamRoles } from '../../constants';
import { GroupMember } from '../../apis/api';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';

type NewEmployeeRowProps = {
    employeeList: string[];
    onComplete: (newEmployee?: GroupMember) => void;
};

export const NewEmployeeRow = ({ employeeList, onComplete }: NewEmployeeRowProps) => {
    const [newEmployeeName, setNewEmployeeName] = useState<string>('');
    const [newEmployeeRole, setNewEmployeeRole] = useState<string>('Team Member');

    const onSelectNewEmployeeName = useCallback(
        (
            _event: React.ChangeEvent<{}>,
            value: string | null,
            _reason: AutocompleteChangeReason,
            _details?: AutocompleteChangeDetails<string> | undefined,
        ) => {
            setNewEmployeeName(value as string);
        },
        [setNewEmployeeName],
    );

    const onSelectNewEmployeeRole = useCallback(
        (event: SelectChangeEvent<string>) => {
            setNewEmployeeRole(event.target.value as string);
        },
        [setNewEmployeeRole],
    );

    const onSaveEmployeeButtonClick = useCallback(
        (_e: any) => {
            onComplete({
                name: newEmployeeName ?? '',
                role: newEmployeeRole ?? '',
                editMode: false,
            });
        },
        [newEmployeeName, newEmployeeRole, onComplete],
    );

    return (
        <TableRow key="newmember">
            <TableCell component="th" scope="row">
                <FormControl variant="outlined" fullWidth>
                    <Autocomplete
                        options={employeeList}
                        onChange={onSelectNewEmployeeName}
                        renderInput={params => <TextField {...params} variant="outlined" label="Employees" />}
                    />
                </FormControl>
            </TableCell>
            <TableCell align="right">
                <FormControl variant="outlined" fullWidth>
                    <Select
                        onChange={onSelectNewEmployeeRole}
                        value={newEmployeeRole}
                        label="Team Role"
                        labelId="label-for-role-select"
                    >
                        {(kmxTeamRoles ?? []).map((role, index) => {
                            return (
                                <MenuItem value={role} key={index}>
                                    {role}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <InputLabel id="label-for-role-select">Team Role</InputLabel>
                </FormControl>
            </TableCell>
            <TableCell align="right">
                <IconButton edge="end" aria-label="save" onClick={onSaveEmployeeButtonClick}>
                    <SaveOutlined />
                </IconButton>
                <IconButton edge="end" aria-label="cancel" onClick={() => onComplete()}>
                    <CancelOutlined />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};
