import React from 'react';
import { Grid } from '@mui/material';
import { EntityApiDefinitionCard, EntityConsumedApisCard, EntityProvidedApisCard } from '@backstage/plugin-api-docs';
import {
    EntityAzureDevOpsPipelineStatusContent,
    isPluginApplicableToEntity as isAzureDevOpsPluginApplicableToEntity,
} from '../../../../plugins/azureDevOps';
import {
    EntityApplicationInsightsContent,
    isPluginApplicableToEntity as isApplicationInsightsPluginApplicableToEntity,
} from '../../../../plugins/appInsights';
import {
    EntityDevOpsMetricsContent,
    isPluginApplicableToEntity as isDevOpsMetricsPluginApplicableToEntity,
} from '../../../../plugins/devOpsMetrics';
import { EntityTechdocsCustomContent } from '../../components/EntityTechdocsCustomContent';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import type { TabContent } from './types';
import { EntityAdrContent, isAdrAvailable } from '@backstage/plugin-adr';
import { isPluginApplicableToEntity as isPagerDutyAvailable, EntityPagerDutyCard } from '@backstage/plugin-pagerduty';

export const MetricsTab: TabContent = {
    path: '/metrics',
    title: 'Metrics',
    if: isApplicationInsightsPluginApplicableToEntity,
    element: <EntityApplicationInsightsContent />,
};

export const PipelineStatusTab: TabContent = {
    path: '/pipelines',
    title: 'Pipeline Status',
    if: isAzureDevOpsPluginApplicableToEntity,
    element: <EntityAzureDevOpsPipelineStatusContent />,
};

export const DocsTab: TabContent = {
    path: '/docs',
    title: 'Docs',
    if: isTechDocsAvailable,
    element: <EntityTechdocsCustomContent />,
};

export const DevOpsMetricsTab: TabContent = {
    path: '/devops-metrics',
    title: 'DevOps Metrics',
    if: isDevOpsMetricsPluginApplicableToEntity,
    element: <EntityDevOpsMetricsContent />,
};

export const ApiTab: TabContent = {
    path: '/api',
    title: 'API',
    element: (
        <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
                <EntityProvidedApisCard />
            </Grid>
            <Grid item md={6}>
                <EntityConsumedApisCard />
            </Grid>
        </Grid>
    ),
};

export const ApiDefinitionTab: TabContent = {
    path: '/definition',
    title: 'Definition',
    element: (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <EntityApiDefinitionCard />
            </Grid>
        </Grid>
    ),
};

const adrFileFilter = (path: string): boolean => {
    let adrFilesToFilter = ['tempate-log4brains.md', '0000-adr-template.md', 'template.md', 'readme.md', 'index.md'];

    if (adrFilesToFilter.includes(path.toLowerCase())) {
        return false;
    }

    // Match all files following the pattern NNNN-title-with-dashes.md including those under decided-adrs/
    return /.+\.md$/.test(path);
};

export const AdrTab: TabContent = {
    path: '/adrs',
    title: 'ADRs',
    if: isAdrAvailable,
    element: (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <EntityAdrContent filePathFilterFn={adrFileFilter} />
            </Grid>
        </Grid>
    ),
};

export const PagerDutyTab: TabContent = {
    path: '/pagerduty',
    title: 'PagerDuty',
    if: isPagerDutyAvailable,
    element: (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <EntityPagerDutyCard />
            </Grid>
        </Grid>
    ),
};
