import { RadarEntry } from '@backstage/plugin-tech-radar';
import { EntryData } from './types';

export function getRadarItems(data: EntryData[], quadrant: string): RadarEntry[] {
  return data.map(entry => ({
        timeline: [
            {
                moved: 0,
                ringId: entry.ringId,
                date: entry.date,
            },
        ],
        key: entry.key,
        id: entry.key,
        title: entry.title,
        quadrant: quadrant,
        links: entry.links,
        description: entry.description ?? entry.title,
    }));
}
