import { createApiRef, IdentityApi } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { Entity, parseEntityRef } from '@backstage/catalog-model';

export interface OnrampApi {
    getGroupsForUser(): Promise<Entity[]>;
    getGroupWithChildren(groupId: string): Promise<Entity[]>;
    getCurrentUser(): Promise<Entity>;
}

export class OnrampClient implements OnrampApi {
    private readonly catalogApi: CatalogApi;
    private readonly identityApi: IdentityApi;

    constructor(options: { catalogApi: CatalogApi; identityApi: IdentityApi }) {
        this.catalogApi = options.catalogApi;
        this.identityApi = options.identityApi;
    }

    async getGroupsForUser(): Promise<Entity[]> {
        const identity = await this.identityApi.getBackstageIdentity();

        return (
            await this.catalogApi.getEntities({
                filter: [
                    {
                        kind: 'group',
                        'relations.hasMember': identity.userEntityRef,
                        'spec.type': 'team',
                    },
                ],
                fields: ['metadata', 'kind'],
            })
        ).items;
    }

    async getGroupWithChildren(groupId: string): Promise<Entity[]> {

        const [currentGroup, childGroups] = await Promise.all([
            this.catalogApi.getEntities({
                filter: [
                    {
                        kind: 'group',
                        "metadata.name": groupId,
                    },
                ],
                fields: ['metadata', 'kind'],
            }),
            this.catalogApi.getEntities({
                filter: [
                    {
                        kind: 'group',
                        'relations.childOf': `group:default/${groupId}`,
                    },
                ],
                fields: ['metadata', 'kind'],
            })]);

        return [ ...currentGroup.items, ...childGroups.items ];
    }
    async getCurrentUser(): Promise<Entity> {
        const identity = await this.identityApi.getBackstageIdentity();

        const entityRef = parseEntityRef(identity.userEntityRef);

        return (await this.catalogApi.getEntityByRef(entityRef))!;
    }
}

export const onrampApiRef = createApiRef<OnrampApi>({
    id: 'onramp',
});

