export const AZURE_SUBSCRIPTIONID_ANNOTATION = 'azure.com/subscriptionId';
export const AZURE_RESOURCEGROUPS_ANNOTATION = 'azure.com/resourceGroups';
export const AZURE_APPLICATIONNAME_ANNOTATION = 'azure.com/applicationName';
export const AZURE_COST_MOREINFOLINK = 'https://github.com/CarMax-Internal/capabilities-backstage-azure-cost-plugin';

export const costIssuesAnnotations = [
    { name: "MultiLocationNonProdCosmosAccounts", friendlyName: "Multi-Location Cosmos Accounts in Non-Prod Environments", helpText: "In most cases, Dev and QA environments are fine with single location Cosmos accounts."},
    { name: "UnusedAppServicePlans", friendlyName: "Unused App Service Plans", helpText: "If the App Service is no longer needed please delete it."},
    { name: "UnusedDisks", friendlyName: "Unused Disks", helpText: "If the Disk is no longer needed please delete it."},
    { name: "UnusedPublicIpAddresses", friendlyName: "Unused Public Ip Addresses", helpText: "If the IP Address is no longer needed please delete it."},
    { name: "NonProdMultiInstanceAppServicePlans", friendlyName: "Multi-Instance App Service Plans in Non-Prod Environments", helpText: "In most cases, Dev and QA environments are fine with single instance App Service Plans."},
    { name: "NonProdPremiumSqlServerDatabases", friendlyName: "Premium SQL Server Database in Non-Prod Environments", helpText: "In most cases, Dev and QA environments are fine with Basic and Standard SQL Databases."},
    { name: "NonProdPremiumSignalRServices", friendlyName: "Premium SignalR Services in Non-Prod Environments", helpText: "In most cases, Dev and QA environments are fine with Basic and Standard SignalR Services."},
    { name: "NonProdPremiumServiceBuses", friendlyName: "Premium Service Bus in Non-Prod Environments", helpText: "In most cases, Dev and QA environments are fine with Basic and Standard Service Buses."},
    { name: "NonProdVmsWithoutSchedules", friendlyName: "Always-On VMs in Non-Prod Environments", helpText: "Consider creating a schedule to shutdown Dev and QA VMs during non-working hours."},
    { name: "NonProdOverprovisionedRedis", friendlyName: "Multi-Instance Redis Instances in Non-Prod Environments", helpText: "Consider reducing the number of Dev and QA Redis instances."},
    { name: "RightSizeVm", friendlyName: "Over-provisioned VMs", helpText: "There may be a cheaper SKU for the VM."},
    { name: "CostAnomalyDetected", friendlyName: "Cost Anomalies", helpText: "Identifies anomalies in monthly Azure costs for the past year."},
];
