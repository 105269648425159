import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
    id: 'apx:root-page',
});

export const myAppsRouteRef = createSubRouteRef({
    id: 'apx:my-apps-page',
    parent: rootRouteRef,
    path: '/my-apps',
});

export const adminRouteRef = createSubRouteRef({
    id: 'apx:admin-page',
    parent: rootRouteRef,
    path: '/admin',
});

export const adminViewEntitiesByIdRouteRef = createSubRouteRef({
    id: 'apx:admin-view-entities-by-id-page',
    parent: rootRouteRef,
    path: '/admin/view-entities-by-id/:id',
});

export const adminViewEntitiesRouteRef = createSubRouteRef({
    id: 'apx:admin-view-entities-page',
    parent: rootRouteRef,
    path: '/admin/view-entities',
});

export const adminViewInstanceByIdRouteRef = createSubRouteRef({
    id: 'apx:admin-view-instance-by-id-page',
    parent: rootRouteRef,
    path: '/admin/view-instance/:instanceId',
});

export const adminViewInstancesRouteRef = createSubRouteRef({
    id: 'apx:admin-view-instances-page',
    parent: rootRouteRef,
    path: '/admin/view-instances',
});

export const adminViewAppRequestsRouteRef = createSubRouteRef({
    id: 'apx:admin-view-app-requests-page',
    parent: rootRouteRef,
    path: '/admin/view-app-requests',
});

export const adminViewAppRequestByIdRouteRef = createSubRouteRef({
    id: 'apx:admin-view-app-request-by-id-page',
    parent: rootRouteRef,
    path: '/admin/view-app-request/:id',
});

export const adminOrchestrationStatusByIdRouteRef = createSubRouteRef({
    id: 'apx:admin-orchestration-status-page',
    parent: rootRouteRef,
    path: '/admin/orchestration-status/:id',
});

export const cloudExNeedHelpRouteRef = createSubRouteRef({
    id: 'apx:cloud-need-help',
    parent: rootRouteRef,
    path: '/cloud-need-help',
});

export const privateCloudNeedHelpRouteRef = createSubRouteRef({
    id: 'apx:private-cloud-need-help',
    parent: rootRouteRef,
    path: '/private-cloud-need-help',
});

export const deliveryAutomationNeedHelpRouteRef = createSubRouteRef({
    id: 'apx:delivery-automation-need-help',
    parent: rootRouteRef,
    path: '/delivery-automation-need-help',
});

export const approvalsRouteRef = createSubRouteRef({
    id: 'apx:approvals-home',
    parent: rootRouteRef,
    path: '/approvals',
});

export const approveCreateSubscriptionRouteRef = createSubRouteRef({
    id: 'apx:approvals-azure-subscription',
    parent: rootRouteRef,
    path: '/approvals/azure-subscription',
});

export const approveCreateResourceGroupsRouteRef = createSubRouteRef({
    id: 'apx:approvals-azure-resource-groups',
    parent: rootRouteRef,
    path: '/approvals/azure-resource-groups',
});

export const approveCreateIAMRequestRouteRef = createSubRouteRef({
    id: 'apx:approvals-iam-request',
    parent: rootRouteRef,
    path: '/approvals/iam-request',
});

export const approveCreateAADGroupsRouteRef = createSubRouteRef({
    id: 'apx:approvals-create-aad-groups',
    parent: rootRouteRef,
    path: '/approvals/create-aad-groups',
});

export const approveCreateADGroupsRouteRef = createSubRouteRef({
    id: 'apx:approvals-create-ad-groups',
    parent: rootRouteRef,
    path: '/approvals/create-ad-groups',
});

export const approveDeleteAADGroupRouteRef = createSubRouteRef({
    id: 'apx:approvals-delete-aad-group',
    parent: rootRouteRef,
    path: '/approvals/delete-aad-group',
});

export const approveCreateSPORouteRef = createSubRouteRef({
    id: 'apx:approvals-create-spo',
    parent: rootRouteRef,
    path: '/approvals/create-spo',
});

export const approveAssignSPORouteRef = createSubRouteRef({
    id: 'apx:approvals-assign-spo',
    parent: rootRouteRef,
    path: '/approvals/assign-spo',
});

export const approveDeleteSPORouteRef = createSubRouteRef({
    id: 'apx:approvals-delete-spo',
    parent: rootRouteRef,
    path: '/approvals/delete-spo',
});

export const approveUpdateAzureTagsRouteRef = createSubRouteRef({
    id: 'apx:approvals-azure-tagging',
    parent: rootRouteRef,
    path: '/approvals/azure-tagging',
});