export const kmxTeamRoles = [
    'Team Member',
    'Analyst',
    'Delivery',
    'Developer',
    'Lead Developer',
    'Product Designer',
    'Product Manager',
    'Quality Engineer',
    'Solution Architect',
    'Technology Manager',
    'Visual Designer',
];

export const groupApiSuccessMessage = "Changes have been saved, and will be visible in Onramp in a couple minutes.";
export const groupApiFailureMessage = "There was an error. Please contact the DevEx team.";