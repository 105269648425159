import { CatalogIcon, DocsIcon } from '@backstage/core-components';
import AlbumIcon from '@mui/icons-material/Album';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';
import { TechDocsSearchResultListItem } from '@backstage/plugin-techdocs';
import { StackOverflowSearchResultListItem as StackOverflowQuestionSearchResultListItem } from '@backstage/plugin-stack-overflow';
import React from 'react';
import { StackOverflowArticleSearchResultListItem } from './components/StackOverflowArticleSearchResultListItem';
import { StackOverflowIcon } from './components/icons/StackOverflowIcon';
import { SearchDocument } from '@backstage/plugin-search-common';
import { AdrSearchResultListItem } from '@backstage/plugin-adr';
import { AdrDocument } from '@backstage/plugin-adr-common';

export const searchResultTypes: {
    value: string;
    name: string;
    icon: React.ReactElement;
    render: (document: SearchDocument) => React.ReactElement;
}[] = [
    {
        value: 'software-catalog',
        name: 'Software Catalog',
        icon: <CatalogIcon />,

        render: document => <CatalogSearchResultListItem key={document.location} result={document} />,
    },
    {
        value: 'techdocs',
        name: 'Documentation',
        icon: <DocsIcon />,
        render: document => <TechDocsSearchResultListItem key={document.location} result={document} />,
    },
    {
        value: 'stack-overflow-question',
        name: 'Stack Overflow Questions',
        icon: <StackOverflowIcon height={22} width={22} monochrome style={{ paddingLeft: '4px' }} />,
        render: document => <StackOverflowQuestionSearchResultListItem key={document.location} result={document} />,
    },
    {
        value: 'stack-overflow-article',
        name: 'Stack Overflow Articles',
        icon: <StackOverflowIcon height={22} width={22} monochrome style={{ paddingLeft: '4px' }} />,
        render: document => <StackOverflowArticleSearchResultListItem key={document.location} result={document} />,
    },
    {
        value: 'adr',
        name: 'ADRs',
        icon: <AlbumIcon />,
        render: document => <AdrSearchResultListItem key={document.location} result={document as AdrDocument} />,
    },
];
