import { AppInsightsClient } from './api';
import {
    configApiRef,
    createApiFactory,
    createPlugin,
    analyticsApiRef,
    identityApiRef,
} from '@backstage/core-plugin-api';

export const onrampAnalyticsPlugin = createPlugin({
    id: 'onramp-analytics-plugin',
    apis: [
        createApiFactory({
            api: analyticsApiRef,
            deps: { configApi: configApiRef, identityApi: identityApiRef },
            factory: ({ configApi, identityApi }) => new AppInsightsClient({ configApi, identityApi }),
        }),
    ],
});

