import { createApiRef } from '@backstage/core-plugin-api';

export type EntityAndPage = {
    pageName: string;
    pageType: string;
    entity: string;
};

export interface FullStoryApi {
    getCurrentSession(): string | undefined;

    trackEvent(eventName: string, eventProperties: Record<string, any>): void;

    setVars(pageData: EntityAndPage): void;

    identifyUser(userId: string, name: string, email: string): void;
}

export class FullStoryClient implements FullStoryApi {
    getCurrentSession(): string | undefined {
        try {
            // @ts-ignore
            return window.FS.getCurrentSession();
        } catch {
            return undefined;
        }
    }

    trackEvent(eventName: string, eventProperties: Record<string, any>): void {
        try {
            // @ts-ignore
            window.FS.event(eventName, eventProperties);
        } catch {
            // NOOP
        }
    }

    setVars(pageData: EntityAndPage): void {
        try {
            // @ts-ignore
            window.FS.setVars("page", {"pageName": pageData.pageName, "pageType": pageData.pageType, "componentName": pageData.componentName });
        } catch {
            // NOOP
        }
    }

    identifyUser(userId: string, name: string, email: string): void {
        try {
            // @ts-ignore
            window.FS.identify(userId, {
                displayName: name,
                email: email,
            });
        } catch {
            // NOOP
        }
    }
}

export const fullStoryApiRef = createApiRef<FullStoryApi>({
    id: 'fullstory-proxy',
});

