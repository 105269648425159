import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import type {
    ServiceResponse,
    ChangeFailureResponse,
    ChangeLeadTimeResponse,
    DeploymentFrequencyResponse,
    MeanTimeToRestoreResponse,
    CreateServiceRequest,
    CreateWebHookRequest,
    PatchDocument,
    IncidentResponse,
    MetricResponse,
    MetricResponseValue,
    TeamResponse,
    DeploymentResponse,
} from '../models';
import moment from 'moment';

import { MetricSeries } from '../types';
import { KmxProxyApi, OnrampApi } from '../../onrampCommon';

type MetricResponseValueNames<T> = T extends MetricResponse<infer P>
    ? { [k in keyof P]: P[k] extends number ? k : never }[keyof P]
    : never;

export interface DevOpsMetricsApi {
    getServicesForUser(): Promise<ServiceResponse[]>;
    getServicesForGroup(groupId: string): Promise<ServiceResponse[]>;
    getAllServices(): Promise<ServiceResponse[]>;
    getAllTeams(): Promise<TeamResponse[]>;

    getServiceChangeFailureMetric(serviceId: string): Promise<MetricSeries>;
    getServiceAverageLeadTimeMetric(serviceId: string): Promise<MetricSeries>;
    getServiceDeploymentFrequencyMetric(serviceId: string): Promise<MetricSeries>;
    getServiceMeanTimeToRestoreMetric(serviceId: string): Promise<MetricSeries>;

    getTeamChangeFailureMetric(teamId: string): Promise<MetricSeries>;
    getTeamAverageLeadTimeMetric(teamId: string): Promise<MetricSeries>;
    getTeamDeploymentFrequencyMetric(teamId: string): Promise<MetricSeries>;
    getTeamMeanTimeToRestoreMetric(teamId: string): Promise<MetricSeries>;

    getService(serviceId: string): Promise<ServiceResponse>;
    createService(request: CreateServiceRequest): Promise<string>;
    updateService(serviceId: string, body: PatchDocument<ServiceResponse>): Promise<unknown>;
    deleteService(serviceId: string): Promise<void>;

    getServiceDeployments(serviceId: string): Promise<DeploymentResponse[]>;
    updateServiceDeployment(
        serviceId: string,
        deploymentId: string,
        body: PatchDocument<DeploymentResponse>,
    ): Promise<unknown>;

    createServiceWebhooks(serviceId: string, repositoryNames: string[]): Promise<unknown>;
    getServiceWebhookSecret(serviceId: string): Promise<string>;

    getIncident(incidentId: string): Promise<IncidentResponse>;
    updateIncident(incidentId: string, body: PatchDocument<IncidentResponse>): Promise<unknown>;
    deleteIncident(incidentId: string): Promise<void>;
    getIncidents(serviceId: string): Promise<IncidentResponse[]>;
    createIncident(body: Partial<IncidentResponse>): Promise<void>;
}

const DEFAULT_METRIC_PERIOD_DAYS = 90;

export class DevOpsMetricsClient implements DevOpsMetricsApi {
    private readonly onrampApi: OnrampApi;
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly devopsMetricsBaseUrl: string;

    constructor(options: { onrampApi: OnrampApi; kmxProxyApi: KmxProxyApi; configApi: ConfigApi }) {
        this.onrampApi = options.onrampApi;
        this.kmxProxyApi = options.kmxProxyApi;
        this.devopsMetricsBaseUrl = options.configApi.getString('devopsMetricsApi.baseUrl');
    }

    async getServicesForUser(): Promise<ServiceResponse[]> {
        const groupIds = (await this.onrampApi.getGroupsForUser()).map(g => g.metadata.name);

        const allServices = await this.getAllServices();

        return allServices.filter(s => groupIds.indexOf((s.team?.teamId ?? '').toLowerCase()) > -1);
    }

    async getServicesForGroup(groupId: string): Promise<ServiceResponse[]> {
        const groupIds = [groupId];

        const allServices = await this.getAllServices();

        return allServices.filter(s => groupIds.indexOf((s.team?.teamId ?? '').toLowerCase()) > -1);
    }

    async getAllServices(): Promise<ServiceResponse[]> {
        const response = await this.request('GET', '/api/v1/services');
        return response.body;
    }

    async getAllTeams(): Promise<TeamResponse[]> {
        const response = await this.request('GET', '/api/v1/teams');
        return response.body;
    }

    getMetricSeries<T extends MetricResponse<MetricResponseValue>>(
        response: T,
        valueName: MetricResponseValueNames<T>,
    ): MetricSeries {
        const values = response.items.map(item => ({
            timestamp: new Date(item.entryDate).valueOf(),
            value: item[valueName],
        }));

        return {
            values,
            category: response.category,
            maturityLevel: response.maturityLevel,
        };
    }

    async getServiceChangeFailureMetric(serviceId: string): Promise<MetricSeries> {
        const response: ChangeFailureResponse = (
            await this.request(
                'GET',
                `/api/v1/metrics/change-failure/services/${serviceId}${this.getDefaultQueryString()}`,
            )
        ).body;

        return this.getMetricSeries(response, 'changeFailureRatio');
    }

    async getServiceAverageLeadTimeMetric(serviceId: string): Promise<MetricSeries> {
        const response: ChangeLeadTimeResponse = (
            await this.request('GET', `/api/v1/metrics/lead-time/services/${serviceId}${this.getDefaultQueryString()}`)
        ).body;

        return this.getMetricSeries(response, 'averageLeadTimeDays');
    }

    async getServiceDeploymentFrequencyMetric(serviceId: string): Promise<MetricSeries> {
        const response: DeploymentFrequencyResponse = (
            await this.request(
                'GET',
                `/api/v1/metrics/deployment-frequency/services/${serviceId}${this.getDefaultQueryString()}`,
            )
        ).body;

        return this.getMetricSeries(response, 'deploymentCount');
    }

    async getServiceMeanTimeToRestoreMetric(serviceId: string): Promise<MetricSeries> {
        const response: MeanTimeToRestoreResponse = (
            await this.request(
                'GET',
                `/api/v1/metrics/mean-time-to-restore/services/${serviceId}${this.getDefaultQueryString()}`,
            )
        ).body;

        return this.getMetricSeries(response, 'meanTimeToRestoreHours');
    }

    async getTeamChangeFailureMetric(teamId: string): Promise<MetricSeries> {
        const response: ChangeLeadTimeResponse = (
            await this.request('GET', `/api/v1/metrics/change-failure/teams/${teamId}${this.getDefaultQueryString()}`)
        ).body;

        return this.getMetricSeries(response, 'averageLeadTimeDays');
    }

    async getTeamAverageLeadTimeMetric(teamId: string): Promise<MetricSeries> {
        const response: ChangeLeadTimeResponse = (
            await this.request('GET', `/api/v1/metrics/lead-time/teams/${teamId}${this.getDefaultQueryString()}`)
        ).body;

        return this.getMetricSeries(response, 'averageLeadTimeDays');
    }

    async getTeamDeploymentFrequencyMetric(teamId: string): Promise<MetricSeries> {
        const response: DeploymentFrequencyResponse = (
            await this.request(
                'GET',
                `/api/v1/metrics/deployment-frequency/teams/${teamId}${this.getDefaultQueryString()}`,
            )
        ).body;

        return this.getMetricSeries(response, 'deploymentCount');
    }

    async getTeamMeanTimeToRestoreMetric(teamId: string): Promise<MetricSeries> {
        const response: MeanTimeToRestoreResponse = (
            await this.request(
                'GET',
                `/api/v1/metrics/mean-time-to-restore/teams/${teamId}${this.getDefaultQueryString()}`,
            )
        ).body;

        return this.getMetricSeries(response, 'meanTimeToRestoreHours');
    }

    async getService(serviceId: string): Promise<ServiceResponse> {
        const response = await this.request('GET', `/api/v1/services/${serviceId}`);
        return response.body;
    }

    async createService(request: CreateServiceRequest): Promise<string> {
        const response = await this.request('POST', '/api/v1/services', request);
        return response.body.id;
    }

    async updateService(serviceId: string, body: PatchDocument<ServiceResponse>): Promise<unknown> {
        const response = await this.request('GET', `/api/v1/services/${serviceId}`);

        return await this.request('PATCH', `/api/v1/services/${serviceId}`, body, {
            'If-Match': response.response.headers.get('etag') ?? '',
        });
    }

    async deleteService(serviceId: string): Promise<void> {
        await this.request('DELETE', `/api/v1/services/${serviceId}`);
    }

    async getServiceDeployments(serviceId: string): Promise<DeploymentResponse[]> {
        const response = await this.request('GET', `/api/v1/services/${serviceId}/deployments`);
        return response.body;
    }

    async updateServiceDeployment(
        serviceId: string,
        deploymentId: string,
        body: PatchDocument<DeploymentResponse>,
    ): Promise<unknown> {
        return await this.request('PATCH', `/api/v1/services/${serviceId}/deployments/${deploymentId}`, body);
    }

    async createServiceWebhooks(serviceId: string, repositoryNames: string[]): Promise<unknown> {
        const requests: CreateWebHookRequest[] = repositoryNames.map(repositoryName => ({
            repositoryName,
            serviceId,
        }));

        return await Promise.all(
            requests.map(async request => await this.request('POST', '/api/v1/hooks/github', request)),
        );
    }

    async getServiceWebhookSecret(serviceId: string): Promise<string> {
        const response = await this.request('GET', `/api/v1/hooks/token/${serviceId}`);
        return response.body;
    }

    async getIncident(incidentId: string): Promise<IncidentResponse> {
        const response = await this.request('GET', `/api/v1/incidents/${incidentId}`);
        return response.body;
    }

    async updateIncident(incidentId: string, body: PatchDocument<IncidentResponse>): Promise<unknown> {
        const response = await this.request('GET', `/api/v1/incidents/${incidentId}`);

        return await this.request('PATCH', `/api/v1/incidents/${incidentId}`, body, {
            'If-Match': response.response.headers.get('etag') ?? '',
        });
    }

    async deleteIncident(incidentId: string): Promise<void> {
        await this.request('DELETE', `/api/v1/incidents/${incidentId}`);
    }

    async getIncidents(serviceId: string): Promise<IncidentResponse[]> {
        const response = await this.request('GET', `/api/v1/incidents?serviceId=${serviceId}`);
        return response.body;
    }

    async createIncident(body: Partial<IncidentResponse>): Promise<void> {
        await this.request('POST', '/api/v1/incidents', body);
    }

    private getDefaultQueryString(): string {
        const startDateTimeUtc = moment().add(-DEFAULT_METRIC_PERIOD_DAYS, 'days').toDate();

        return `?startDateTimeUtc=${startDateTimeUtc.toISOString()}`;
    }

    private async request(
        method: string,
        path: string,
        body?: any,
        additionalHeaders?: Record<string, string>,
    ): Promise<{ body: any; response: Response }> {
        const metricsUrl = `${this.devopsMetricsBaseUrl}${path}`;

        const response = await this.kmxProxyApi.performProxiedRequest('kmxproxy', {
            url: metricsUrl,
            method,
            headers: additionalHeaders,
            body,
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        try {
            let responseBody = await response.text();
            try {
                responseBody = JSON.parse(responseBody);
            } catch (e: any) {
                // NOOP
            }

            return {
                body: responseBody,
                response,
            };
        } catch {
            return { body: undefined, response };
        }
    }
}

export const devOpsMetricsApiRef = createApiRef<DevOpsMetricsApi>({
    id: 'devopsmetrics',
});

