import { UserListItem } from '../components/lookups';
import type { ValidationInfo } from '../common/validationHelpers';
import { useGenericLookup, LookupProps } from './useLookup';

export default function useUserLookup<T, V>(
    value: UserListItem | undefined,
    fieldName: (keyof T & string) | (keyof V & string),
    setValue: (v: UserListItem) => void,
    currentState: T,
    validationInfo?: ValidationInfo<V>,
): LookupProps<UserListItem> {
    return useGenericLookup<T, UserListItem, V>(value, fieldName, setValue, currentState, validationInfo);
}

