import { fullStoryApiRef, FullStoryClient } from './apis';
import { createApiFactory, createPlugin } from '@backstage/core-plugin-api';

export const fullStoryPlugin = createPlugin({
    id: 'backstage-fullstory-plugin',
    apis: [
        createApiFactory({
            api: fullStoryApiRef,
            deps: {},
            factory: () => new FullStoryClient(),
        }),
    ],
    routes: {},
    featureFlags: [],
});
