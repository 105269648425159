import { createApiFactory, createPlugin } from "@backstage/core-plugin-api";
import { shortcutsApiRef } from "@backstage/plugin-shortcuts";
import { userProfileDataApiRef } from "../onrampCommon/apis/UserProfileDataApi";
import { ShortcutsClient } from "./apis/ShortcutsClient";

export const PLUGIN_SHORTCUTS_KEY = 'onramp-shortcuts';

// @backstage/shortcuts contains shortcuts plugin, this is to customize api registration to use ShortcutClient
export const onrampShortcutsPlugin = createPlugin({
    id: PLUGIN_SHORTCUTS_KEY,
    apis: [
        createApiFactory({
            api: shortcutsApiRef,
            deps: { userProfileDataApi: userProfileDataApiRef },
            factory: ({userProfileDataApi}) => new ShortcutsClient(userProfileDataApi),
        }),
    ],
  });
