import React from 'react';
import { useOnrampTheme } from '../../../../theme';

type StackOverflowIconProps = {
    monochrome?: boolean;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
};

export const StackOverflowIcon = ({ monochrome, width, height, style }: StackOverflowIconProps) => {
    const theme = useOnrampTheme();
    const primaryColor = monochrome
        ? theme.palette.icons.stackOverflow.monochrome
        : theme.palette.icons.stackOverflow.primary;
    const secondaryColor = monochrome
        ? theme.palette.icons.stackOverflow.monochrome
        : theme.palette.icons.stackOverflow.secondary;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width={width ?? 20}
            height={height ?? 20}
            style={style}
        >
            <path d="M28.16 32H2.475V20.58H5.32v8.575h19.956V20.58h2.884z" fill={secondaryColor} />
            <path
                d="M8.477 19.8l13.993 2.923.585-2.806-13.993-2.923zm1.832-6.704l12.94 6.04 1.208-2.572-12.94-6.08zm3.586-6.353l10.99 9.12 1.832-2.183-10.99-9.12zM20.99 0l-2.3 1.715 8.536 11.46 2.3-1.715zM8.166 26.27H22.43v-2.845H8.166v2.845z"
                fill={primaryColor}
            />
        </svg>
    );
};

