import React, { ComponentProps } from 'react';
import { Checkbox as MaterialCheckbox, FormGroup, FormControlLabel } from '@mui/material';
import { Body, FinePrint } from '../typography';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

type CheckboxProps = ComponentProps<typeof MaterialCheckbox> & { label: string; helperText?: string };

const useStyles = makeStyles<OnrampTheme>(theme => ({
    container: { alignItems: 'start' },
    label: { paddingTop: theme.spacing(1) },
}));

export const Checkbox = (props: CheckboxProps) => {
    const { label, helperText, ...rest } = props;

    const styles = useStyles();

    return (
        <FormGroup>
            <FormControlLabel
                className={styles.container}
                control={<MaterialCheckbox color="primary" {...rest} />}
                labelPlacement="end"
                label={
                    <div className={styles.label}>
                        <Body>{label}</Body>
                        <FinePrint>{helperText}</FinePrint>
                    </div>
                }
            />
        </FormGroup>
    );
};
