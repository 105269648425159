import React from "react";
import { useApi } from "@backstage/core-plugin-api";
import { ComponentProps } from "react";
import { Route, useLocation } from "react-router";
import { EntityAndPage, fullStoryApiRef } from './apis'

const parseUrlToEntityAndPage = (url: string): EntityAndPage => {

    const pathSegments = url.split('/').filter(x => x !== '');
    
    const pageSegment = pathSegments.at(0) as string;
    const entitySegment = pathSegments.at(3) as string;
    const pageName = pageSegment.charAt(0).toUpperCase() + pageSegment.substring(1);
    let pageType = '';

    if(pageName !== "Docs") {
        pageType = pathSegments.length > 4 ? pathSegments.at(4) as string : "overview"
    }
    else {
        pageType = pageName.toLowerCase();
    }

    return {pageName: pageName, pageType: pageType, entity: entitySegment};
}

type RouteWithTrackingProps = ComponentProps<typeof Route>;

export const RouteWithTracking = ({ ...props }: RouteWithTrackingProps) => {

    const location = useLocation();
    const parsedUrl = parseUrlToEntityAndPage(location.pathname);

    const fsClient = useApi(fullStoryApiRef);
    fsClient.setVars(parsedUrl);

    return <Route {...props} />;
};



