import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useState } from 'react';
import { useAsync } from 'react-use';
import { MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION } from '../apis/ApxApiClient';

const useUserPrincipalEntityName = (rawUserId: string) => {
    const [userName, setUserName] = useState(rawUserId);
    const catalogApi = useApi(catalogApiRef);

    useAsync(async () => {
        const entities = await catalogApi.getEntities({
            fields: ['metadata.name'],
            filter: {
                [`metadata.annotations.${MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION}`]: rawUserId,
            },
        });

        if (entities.items.length === 1) {
            setUserName(entities.items[0].metadata.name);
        } else {
            setUserName(rawUserId);
        }
    }, [rawUserId]);

    return userName;
};

export default useUserPrincipalEntityName;

