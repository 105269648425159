import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import SaveOutlined from '@mui/icons-material/SaveOutlined';

type AzureCostManageIssueStateSelectProps = {
    issueObject: any;
    saveIssueStatus: (id: string, status: string) => void;
};

export const AzureCostManageIssueStateSelect = ({
    issueObject,
    saveIssueStatus,
}: AzureCostManageIssueStateSelectProps) => {
    const [selectValue, setSelectValue] = useState<string>('');

    useEffect(() => {
        if (issueObject !== undefined) {
            setSelectValue((issueObject.status as string) ?? 'IN_PROGRESS');
        }
    }, [issueObject]);

    const saveIssue = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        saveIssueStatus(issueObject.id, selectValue);
    };

    return (
        <Grid container spacing={0}>
            <Grid item xs={10}>
                <FormControl variant="outlined" fullWidth size="small">
                    <Select
                        onChange={e => setSelectValue(e.target.value as string)}
                        label="Issue Status"
                        labelId="label-for-select"
                        defaultValue="IN_PROGRESS"
                        value={selectValue}
                    >
                        <MenuItem value="IN_PROGRESS" key={1}>
                            In Progress
                        </MenuItem>
                        <MenuItem value="ACKNOWLEDGED" key={2}>
                            Acknowledged
                        </MenuItem>
                        <MenuItem value="CLOSED_AS_DESIGNED" key={3}>
                            Closed as Designed
                        </MenuItem>
                        <MenuItem value="RESOLVED" key={4}>
                            Resolved
                        </MenuItem>
                    </Select>
                    <InputLabel id="label-for-select">Status</InputLabel>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <Button onClick={e => saveIssue(e)}>
                    <SaveOutlined />
                </Button>
            </Grid>
        </Grid>
    );
};
