import {
    rootRouteRef
} from './routes';
import {
    createPlugin,
    createRoutableExtension,
    configApiRef,
    createApiFactory
} from '@backstage/core-plugin-api';

import { kmxProxyApiRef } from '../onrampCommon';
import { queueManagementApiRef, QueueManagementClient } from '../queueManagement/api'

export const MessageQueuesManagementPlugin = createPlugin({
    id: 'messageQueues',
    apis: [
        createApiFactory({
            api: queueManagementApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new QueueManagementClient({
                    kmxProxyApi,
                    configApi
                })
        })
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const MessageQueuesHomePage = MessageQueuesManagementPlugin.provide(
    createRoutableExtension({
        name: 'MessageQueuesHomePage',
        component: () =>
            import('./pages/QueueManagementHomePage').then(m => m.MessageQueuesHomePage),
        mountPoint: rootRouteRef,
    }),
);

