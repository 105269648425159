import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import type { GroupModel, EndpointModel, PublisherModel } from '../models';
import { KmxProxyApi } from '../../onrampCommon';

export interface EnterpriseMessagingApi {
    getGroups(): Promise<GroupModel[]>;
    getEndpoints(): Promise<EndpointModel[]>;
    getPublishers(): Promise<PublisherModel[]>;
}

export class EnterpriseMessagingClient implements EnterpriseMessagingApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly enterpriseMessagingBaseUrl: string;

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.enterpriseMessagingBaseUrl = options.configApi.getString('enterpriseMessagingApi.baseUrl');
    }

    async getGroups(): Promise<GroupModel[]> {
        const response = await this.request('GET', '/groups');
        return response.body;
    }

    async getEndpoints(): Promise<EndpointModel[]> {
        const response = await this.request('GET', '/endpoints');
        return response.body;
    }

    async getPublishers(): Promise<PublisherModel[]> {
        const response = await this.request('GET', '/publishers');
        return response.body;
    }

    private async request(
        method: string,
        path: string,
        body?: any,
        additionalHeaders?: Record<string, string>,
    ): Promise<{ body: any; response: Response }> {
        const metricsUrl = `${this.enterpriseMessagingBaseUrl}${path}`;

        const response = await this.kmxProxyApi.performProxiedRequest('kmxproxy', {
            url: metricsUrl,
            method,
            headers: additionalHeaders,
            body,
        });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        try {
            let responseBody = await response.text();
            try {
                responseBody = JSON.parse(responseBody);
            } catch (e: any) {
                // NOOP
            }

            return {
                body: responseBody,
                response,
            };
        } catch {
            return { body: undefined, response };
        }
    }
}

export const enterpriseMessagingApiRef = createApiRef<EnterpriseMessagingApi>({
    id: 'enterprisemessaging',
});

