import React from 'react';
import { useOnrampTheme } from '../../../theme';

type ApimIconProps = {
    monochrome?: boolean;
    width?: number;
    height?: number;
};

export function ApimIcon({ monochrome, width, height }: ApimIconProps) {
    const theme = useOnrampTheme();
    const primaryColor = monochrome ? theme.palette.icons.apim.monochrome : theme.palette.icons.apim.primary;
    const secondaryColor = monochrome ? theme.palette.icons.apim.monochrome : theme.palette.icons.apim.secondary;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 101 81"
            fill="#fff"
            fill-rule="evenodd"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            width={width ?? 20}
            height={height ?? 20}
        >
            <g stroke="none">
                <path
                    d="M40.837 54.676H23.108h0c-8.765 0-15.936-6.907-15.936-15.539s6.773-15.54 15.936-15.54c1.594 0 3.386.384 5.379.575l3.386.959.996-3.261c3.187-9.976 13.148-16.115 24.502-16.115 13.745 0 24.303 10.168 24.303 23.405 0 2.11-.398 4.221-.996 6.331l-1.394 4.796 5.378-.575h.996c3.984 0 7.171 3.261 7.171 6.907s-2.789 6.331-6.175 6.906H68.924c-.597-4.796-3.187-9.017-6.574-12.278-4.382-4.221-10.358-6.715-16.534-6.715-4.382 0-8.765 1.151-12.151 3.261l3.785 5.755c2.789-1.535 5.578-2.11 8.765-2.11 4.383 0 8.765 1.535 11.554 4.796 3.187 3.07 4.98 6.907 4.98 11.127s-1.593 8.441-4.98 11.127c-3.187 3.261-7.171 4.796-11.554 4.796-3.187 0-6.175-.959-8.765-2.686l-3.785 5.755C37.45 78.465 41.833 80 45.817 80c6.175 0 12.151-2.11 16.534-6.523 3.785-3.645 5.976-8.441 6.574-13.429h18.127.398c7.171-.575 12.55-6.523 12.55-13.813 0-5.755-5.578-11.127-12.151-12.278.399-1.151.399-1.727.399-3.645C88.247 13.429 75.896 0 56.175 0h0C43.626 0 32.47 6.907 27.888 18.034c-1.594 0-2.789-.575-4.581-.575C10.359 17.266 0 26.859 0 39.137c0 12.086 10.359 21.679 22.709 21.679h19.522l-1.394-6.139z"
                    fill={primaryColor}
                />
                <path
                    d="M34.263 57.17c0-5.952 4.975-10.743 11.155-10.743s11.155 4.791 11.155 10.743-4.975 10.743-11.155 10.743-11.155-4.792-11.155-10.743z"
                    fill={secondaryColor}
                />
            </g>
        </svg>
    );
}
