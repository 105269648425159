import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory,
    configApiRef,
    createApiFactory,
    ApiRef,
    BackstageIdentityApi,
    createApiRef,
    discoveryApiRef,
    oauthRequestApiRef,
    ProfileInfoApi,
    SessionApi,
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';
import { plugins } from './plugins';
import { flatten } from 'lodash';
import { onrampAnalyticsPlugin } from './plugins/onrampAnalytics';
import { ProductDeveloperTechRadarApi } from './components/techRadars/productDeveloper/TechRadar';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';

export const carmaxAuthApiRef: ApiRef<ProfileInfoApi & BackstageIdentityApi & SessionApi> = createApiRef({
    id: 'carmax.auth',
});

const baseApis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
    createApiFactory({
        api: carmaxAuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
        },
        factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
            OAuth2.create({
                discoveryApi,
                oauthRequestApi,
                environment: configApi.getOptionalString('auth.environment'),
            }),
    }),
    createApiFactory(techRadarApiRef, new ProductDeveloperTechRadarApi()),
    // This causes problems if it's part of the "plugins" array, so we have to register the APIs here
    ...onrampAnalyticsPlugin.getApis(),
];

const pluginApis: AnyApiFactory[] = flatten(plugins.map(plugin => Array.from(plugin.getApis())));

export const apis: AnyApiFactory[] = [...baseApis, ...pluginApis];
