import { Entity } from '@backstage/catalog-model';
import { AZURE_PIPELINES_ANNOTATION } from './constants';

export const isPluginApplicableToEntity = (entity: Entity) => {
    const annotations = entity.metadata?.annotations as Record<string, string>;
    const pipelines = Object.keys(annotations).filter(k =>
        k.startsWith(AZURE_PIPELINES_ANNOTATION),
    );
    return pipelines.length === 0 || pipelines === undefined ? false : true;
};

