import React from 'react';
import { InfoCard, Content } from '@backstage/core-components';
import { Grid, Link } from '@mui/material';

export const AzureCostDetails = () => {
    return (
        <InfoCard title="Azure Cost" variant="gridItem">
            <Content>
                <Grid container spacing={3} direction="column">
                    <Grid item>
                        <h4>
                            The cost data widget is moving soon to the new <Link href="/cost">cost page.</Link>
                        </h4>
                    </Grid>
                </Grid>
            </Content>
        </InfoCard>
    );
};
