import React from 'react';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { CircularProgress } from '../CircularProgress';
import { useStyles } from './WorkflowButton.styles';
import { useOnrampTheme } from '../../../../../theme';

interface WorkflowButtonProps {
    disabled?: boolean;
    loading?: boolean;
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
    className?: string;
}

function WorkflowButton({ active, onClick, children, disabled, loading, className }: WorkflowButtonProps) {
    const onrampTheme = useOnrampTheme();
    const styles = useStyles(onrampTheme);

    return (
        <Button
            onClick={onClick}
            variant={active ? 'contained' : 'outlined'}
            className={classNames(styles.default, active && !disabled ? styles.active : null, className)}
            disabled={disabled}
            endIcon={
                loading ? (
                    <div className={styles.buttonIconWrapper}>
                        <CircularProgress size="x-small" />
                    </div>
                ) : undefined
            }
        >
            {children}
        </Button>
    );
}

export default WorkflowButton;
