import React, { useState, useMemo, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, TextField } from '@mui/material';
import { useDebounce } from 'react-use';

type GroupPropertyTextFieldProps = {
    value: string;
    onChange: (value: string) => void;
    pattern?: RegExp;
    id: string;
    label: string;
    helperText?: string;
    debounce?: number;
    error?: boolean;
    disabled?: boolean;
};

export const GroupPropertyTextField = ({
    value,
    onChange,
    pattern,
    id,
    label,
    helperText,
    debounce,
    error,
    disabled,
}: GroupPropertyTextFieldProps) => {
    const [internalValue, setInternalValue] = useState(value);
    const isValid = useMemo(() => (pattern ? pattern.test(internalValue) : true), [internalValue, pattern]);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useDebounce(
        () => {
            onChange(internalValue);
        },
        debounce ?? 0,
        [internalValue],
    );

    return (
        <FormControl fullWidth>
            <TextField
                variant="outlined"
                label={label}
                id={id}
                value={internalValue}
                onChange={({ currentTarget }) => {
                    setInternalValue(currentTarget.value);
                }}
                error={error || (pattern ? internalValue.length !== 0 && !isValid : false)}
                inputProps={{ pattern }}
                disabled={disabled}
            />
            {helperText && <FormHelperText id={`${id}-helper-text`}>{helperText}</FormHelperText>}
        </FormControl>
    );
};
