import React from 'react';
import { InfoCard, Link } from '@backstage/core-components';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { costIssuesAnnotations } from '../constants';
import { CostIssue, CostIssueAnnotation, GroupedIssues } from '../types';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import HelpIcon from '@mui/icons-material/Help';
import { startCase } from 'lodash';
import { AzureCostManageIssueStateSelect } from './AzureCostManageIssueStateSelect';
import { makeStyles } from '@mui/styles';
import { ControlWithHelp } from '../../onrampCommon';
import { AzureCostMoreInfo } from '../help';

type AzureCostIssuesAccordionProps = {
    identifiedIssues: GroupedIssues[];
    manageIssue: (id: string, status: string) => void;
};

const useStyles = makeStyles({
    center: {
        display: 'inline-flex',
        flexDirection: 'row',
        justifyContent: 'right',
        margin: "10px 0px 0px 0px",
        "& h4": {
            fontSize: "18px",
            padding: "0px",
            margin: "0px -10px 0px 0px",
            textAlign: "right",
            lineHeight: "40px",
        },
        "& svg": {
            color: "#053361"
        }
    }
});

export const AzureCostIssuesAccordion = ({ identifiedIssues, manageIssue }: AzureCostIssuesAccordionProps) => {
    const classes = useStyles();

    const issueAnnotation = (issueName: string) => {
        return costIssuesAnnotations.find(x => x.name === issueName) as CostIssueAnnotation;
    };

    const rowColor = (previousMonth: string, month: string) => {
        return +previousMonth > +month ? '#4caf50' : '#f44336';
    };

    const getDetails = (additionalDetails: any) => {
        const messages: string[] = [];

        Object.keys(additionalDetails).forEach(deets => {
            const title = startCase(deets);
            messages.push(`${title}: ${additionalDetails[deets]}`);
        });

        return (
            <React.Fragment>
                {messages.map((msg, index) => (
                    <Typography key={`msg${index}`}>{msg}</Typography>
                ))}
            </React.Fragment>
        );
    };

    const headerContent = (
        <Grid className={classes.center}>
            <h4>More Info</h4>
            <ControlWithHelp helpContent={<AzureCostMoreInfo />}/>
        </Grid>
    );

    return (
        <InfoCard title="Azure Cost Suggestions" action={headerContent} >
            <Grid item xs={12}>
                {(identifiedIssues ?? []).map((iss, index) => {
                    return (
                        <Accordion id={iss.issueType} key={`s${index.toString()}`}>
                            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                                <Typography>
                                    {`${issueAnnotation(iss.issueType).friendlyName} - ${iss.resources.length} found`}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography>{issueAnnotation(iss.issueType).helpText}</Typography>
                                    </Grid>
                                    {iss.issueType !== 'CostAnomalyDetected' ? (
                                        <Grid item xs={12}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Owner</TableCell>
                                                        <TableCell>Resource Group</TableCell>
                                                        <TableCell>Resource Item</TableCell>
                                                        <TableCell align="center">More Information</TableCell>
                                                        <TableCell width="20%">Manage Issue</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {((iss.resources as CostIssue[]) ?? []).map((res, ind) => {
                                                        return (
                                                            <TableRow key={ind.toString()}>
                                                                <TableCell>{res.owner}</TableCell>
                                                                <TableCell>{res.resourceGroup}</TableCell>
                                                                <TableCell>
                                                                    <Link to={res.resourceUrl}>{res.name}</Link>
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Tooltip title={getDetails(res.additionalDetails)}>
                                                                        <IconButton>
                                                                            <HelpIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <AzureCostManageIssueStateSelect
                                                                        issueObject={res}
                                                                        saveIssueStatus={manageIssue}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Owner</TableCell>
                                                        <TableCell>Previous Monthly Cost</TableCell>
                                                        <TableCell>Monthly Cost</TableCell>
                                                        <TableCell>Measurement Date</TableCell>
                                                        <TableCell width="20%">Manage Issue</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {((iss.resources as CostIssue[]) ?? []).map((res, ind) => {
                                                        return (
                                                            <TableRow key={ind.toString()}>
                                                                <TableCell>{res.owner}</TableCell>
                                                                <TableCell>
                                                                    ${res.additionalDetails.previousMonthlyCost}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        color: rowColor(
                                                                            res.additionalDetails.previousMonthlyCost,
                                                                            res.additionalDetails.monthlyCost,
                                                                        ),
                                                                    }}
                                                                >
                                                                    ${res.additionalDetails.monthlyCost}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {res.additionalDetails.measurementDate}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <AzureCostManageIssueStateSelect
                                                                        issueObject={res}
                                                                        saveIssueStatus={manageIssue}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </InfoCard>
    );
};
