import { createApiRef, IdentityApi, ConfigApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

const AUTHENTICATION_ENVIRONMENT_CONFIGURATION_KEY = 'auth.environment';
const AUTHENTICATION_ENVIRONMENT_OFFLINE = 'offline';

declare type FetchApi = {
    fetch: typeof fetch;
};

declare type DiscoveryApi = {
    getBaseUrl(pluginId: string): Promise<string>;
};

export class UserProfileDataClient implements UserProfileDataApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly fetchApi: FetchApi;
    private readonly configApi: ConfigApi;
    private readonly isOffline: boolean;

    constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi; configApi: ConfigApi; fetchApi?: FetchApi }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.configApi = options.configApi;
        this.fetchApi = options.fetchApi || { fetch: crossFetch };

        const authEnvironment = this.configApi.getOptionalString(AUTHENTICATION_ENVIRONMENT_CONFIGURATION_KEY);

        this.isOffline = authEnvironment === AUTHENTICATION_ENVIRONMENT_OFFLINE;
    }

    async get<T>(bucket: string, key: string): Promise<UserProfileRecord<T>> {

        if (this.isOffline) {
            // values for "bucket" and "key" are made up
            return Promise.resolve({
                bucket: 'guest',
                key: 'guest',
                // NOTE: defaulting "value" to an empty Array. If an empty object
                //       expression is used, then code that expects an Array will
                //       completely fail with an error. However, Arrays are objects,
                //       so code that expects an object should still work.
                value: (undefined as unknown) as T,
            } as UserProfileRecord<T>);
        }

        const url = `${await this.getBaseUrl()}/${bucket}/${key}`;
        const authHeaders = await this.getAuthHeader();

        return await this.fetchApi
            .fetch(url, {
                method: 'get',
                headers: authHeaders,
            })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP status code: ${response.status}`)
                }
                return await response.json();
            });
    }

    async set<T>(bucket: string, key: string, value: T): Promise<UserProfileRecord<T>> {
        const url = `${await this.getBaseUrl()}/${bucket}/${key}`;
        const authHeaders = await this.getAuthHeader();

        return await this.fetchApi
            .fetch(url, {
                method: 'post',
                headers: { ...authHeaders, Accept: 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(value),
            })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP status code: ${response.status}`)
                }

                return await response.json();
            });
    }

    async getBaseUrl(): Promise<string> {
        return await this.discoveryApi.getBaseUrl('user-profile-data');
    }

    async getAuthHeader(): Promise<Record<string, string>> {
        const token = (await this.identityApi.getCredentials()).token;
        return {
            Authorization: `Bearer ${token}`,
        };
    }
}

export interface UserProfileDataApi {
    get<T>(bucket: string, key: string): Promise<UserProfileRecord<T>>;
    set<T>(bucket: string, key: string, value: T): Promise<UserProfileRecord<T>>;
}

export const userProfileDataApiRef = createApiRef<UserProfileDataApi>({
    id: 'onramp-profile',
});

export interface UserProfileRecord<T> {
    bucket: string;
    key: string;
    value: T
}
