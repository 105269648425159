import React from 'react';
import { Grid } from '@mui/material';

export type ColumnProps = {
    span: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    children?: React.ReactNode;
    className?: string;
};

export const Column = ({ children, span, className }: ColumnProps) => {
    return (
        <Grid item xs={span} className={className}>
            {children}
        </Grid>
    );
};
