import React from 'react';
import { ExternalLink } from './ExternalLink';

export function CloudExNeedsHelpFormLink(props: { text: string }) {
    return (
        <ExternalLink href="https://onramp.carmax.com/provision/cloud-need-help">
            {props.text}
        </ExternalLink>
    );
}
