import { onrampUsageApiRef, OnrampUsageClient } from './api';
import { rootRouteRef } from './routes';
import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';
import { kmxProxyApiRef } from '../onrampCommon';

export const onrampUsagePlugin = createPlugin({
    id: 'onramp-usage-plugin',
    apis: [
        createApiFactory({
            api: onrampUsageApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new OnrampUsageClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const OnrampUsagePage = onrampUsagePlugin.provide(
    createRoutableExtension({
        name: 'OnrampUsagePage',
        component: () =>
            import('./pages/OnrampUsagePage').then(m => m.OnrampUsagePage),
        mountPoint: rootRouteRef,
    }),
);

