/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    svg: {
        width: 'auto',
        height: 40,
    },
    path: {
        fill: '#fff',
    },
});

const KmxLogoIcon = () => {
    const classes = useStyles();

    return (
        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.01 238.01">
            <rect className={classes.path} x="96.87" y="154.55" width="51.55" height="15.58" />
            <rect className={classes.path} x="163.04" y="154.55" width="51.55" height="15.58" />
            <path
                className={classes.path}
                d="M63.64,111.19c-.36,6.94-2.64,9-6,9-6.57,0-7.12-7.49-7.12-17.54,0-10.6.64-17.54,7.4-17.54,3.2,0,5.94,1.46,6.3,9H88.13C88,74.65,74,67.88,57.34,67.88c-22.29,0-34.53,13.89-33.89,34.44,0,20.93,10.68,35,33.8,35,16.35,0,30.88-7.94,30.88-26.12Z"
            />
            <path
                className={classes.path}
                d="M96.21,135H122V95.3c0-5.08,2.24-7.1,4.68-7.1s4.47,2.41,4.47,6.84v40h25.76V95.3c0-5.08,2.24-7.1,4.69-7.1S166,90.61,166,95v40H191.8V92.64c0-16.87-7.77-23.21-18.52-23.21-6.07,0-12.46,2.41-16.83,11.54-3.29-7.86-9.25-11.54-17.45-11.54-6.06,0-12.46,2.41-16.82,11.54H122l-.64-9.64H96.21V135Z"
            />
        </svg>
    );
};

export default KmxLogoIcon;
