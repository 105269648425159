import { createRouteRef } from '@backstage/core-plugin-api';

export const observePageRouteRef = createRouteRef({
    id: 'onramp-observe-landing',
});

export const createPageRouteRef = createRouteRef({
    id: 'onramp-create-landing',
});

export const designPageRouteRef = createRouteRef({
    id: 'onramp-design-landing',
});
