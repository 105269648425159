import React from 'react';
import { Radio as MaterialRadio, FormGroup, FormControlLabel } from '@mui/material';
import { Body } from '../typography';

type RadioButtonProps = {
    checked: boolean;
    label: string;
    name: string;
    disabled?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
};

export const RadioButton = ({ checked, label, name, disabled, onChange, value }: RadioButtonProps) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <MaterialRadio
                        checked={checked}
                        name={name}
                        disabled={disabled}
                        onChange={onChange}
                        color="primary"
                        value={value}
                    />
                }
                labelPlacement="end"
                label={<Body>{label}</Body>}
            />
        </FormGroup>
    );
};
