import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
    id: 'apigee:root-page',
});

export const createApplicationRouteRef = createSubRouteRef({
    id: 'apigee:create-application-page',
    parent: rootRouteRef,
    path: '/create',
});

export const applicationRouteRef = createSubRouteRef({
    id: 'apigee:application-page',
    parent: rootRouteRef,
    path: '/:name',
});

