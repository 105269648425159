import React from 'react';
import { Grid, Typography, FormControl } from '@mui/material';
import { InfoCard } from '@backstage/core-components';
import { GroupPropertyTextField } from './GroupPropertyTextField';
import { GroupTreeHierarchy } from './GroupTreeHierarchy';
import { GroupInfo } from '../../pages/KmxGroupsPage';
import { Entity } from '@backstage/catalog-model';

type GroupMetadataProps = {
    isAddingNewGroup: boolean;
    groupEntities: Entity[];
    group: GroupInfo;
    groupHierarchy: string[];
    updateGroup: (updates: Partial<GroupInfo>) => void;
};

export const ValidEmailPattern: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const ValidGroupNamePattern: RegExp = /^[a-z_\-]+$/;

export const GroupMetadata = ({
    isAddingNewGroup,
    groupEntities,
    group,
    groupHierarchy,
    updateGroup,
}: GroupMetadataProps) => {
    const onNodeSelect = (node: string) => {
        if (isAddingNewGroup) {
            updateGroup({ parent: node });
        } else {
            updateGroup({ name: node, description: '', email: '' });
        }
    };

    return (
        <InfoCard title="Information">
            <Grid container>
                <Grid item xs={12}>
                    {isAddingNewGroup ? (
                        <Typography variant="h6">Select Parent for New Group</Typography>
                    ) : (
                        <Typography variant="h6">Select Group to Edit</Typography>
                    )}
                </Grid>
                <GroupTreeHierarchy
                    groups={groupEntities}
                    hierarchy={groupHierarchy}
                    preselectedGroup={group.name}
                    setSelectedGroup={onNodeSelect}
                />
                <Grid item xs={12}>
                    <Typography variant="h6">Details</Typography>
                </Grid>
                {isAddingNewGroup && (
                    <Grid item xs={12}>
                        <GroupPropertyTextField
                            label="Selected Parent"
                            value={group.parent}
                            id="group-parent-field"
                            onChange={(_value: string) => void {}}
                            disabled
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <GroupPropertyTextField
                        debounce={500}
                        value={group.name}
                        onChange={name => updateGroup({ name })}
                        pattern={ValidGroupNamePattern}
                        label="Group Name"
                        id="group-name-field"
                        helperText="Lowercase letters and underscores only!"
                        disabled={!isAddingNewGroup}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <GroupPropertyTextField
                            label="Group Description"
                            value={group.description}
                            id="group-description-field"
                            onChange={description => updateGroup({ description })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <GroupPropertyTextField
                            label="Group Email"
                            value={group.email}
                            id="group-email-field"
                            pattern={ValidEmailPattern}
                            onChange={email => updateGroup({ email })}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </InfoCard>
    );
};
