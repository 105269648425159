import React from 'react';
import { Dialog as MaterialDialog } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

type ModalProps = {
    className?: string;
    children: React.ReactNode;
    onClose?: () => void;
};

const useStyles = makeStyles<OnrampTheme>(theme => ({
    default: {
        padding: theme.spacing(3),
        minWidth: '20vw',
    },
}));

export const Modal = ({ className, children, onClose }: ModalProps) => {
    const styles = useStyles();

    return (
        <MaterialDialog open onClose={onClose}>
            <div className={classNames(styles.default, className)}>{children}</div>
        </MaterialDialog>
    );
};
