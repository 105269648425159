import React from 'react';
import { Grid } from '@mui/material';
import {
    EntityConsumingComponentsCard,
    EntityHasApisCard,
    EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
    EntityAboutCard,
    EntityHasComponentsCard,
    EntityHasResourcesCard,
    EntityHasSubcomponentsCard,
    EntityHasSystemsCard,
    EntityLinksCard,
    EntitySwitch,
    EntityOrphanWarning,
    EntityProcessingErrorsPanel,
    hasCatalogProcessingErrors,
    isOrphan,
    hasRelationWarnings,
    EntityRelationWarning,
} from '@backstage/plugin-catalog';
import { EntityUserProfileCard, EntityGroupProfileCard } from '@backstage/plugin-org';

import { EntityMembersListCard } from '../../components/EntityMembersListCard';
import { OwnershipCard } from '../../components/OwnershipCard';

import { AzureCostCard } from '../../../../plugins/azureCost';
import {
    EntityServiceHealthContent,
    isPluginApplicableToEntity as isServiceHealthPluginApplicableToEntity,
} from '../../../../plugins/serviceHealth';
import type { TabContent } from './types';
import { GroupsLinksCard } from '../../components/GroupLinksCard';

const entityWarningContent = (
    <>
        <EntitySwitch>
            <EntitySwitch.Case if={isOrphan}>
                <Grid item xs={12}>
                    <EntityOrphanWarning />
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasRelationWarnings}>
                <Grid item xs={12}>
                    <EntityRelationWarning />
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
            <EntitySwitch.Case if={hasCatalogProcessingErrors}>
                <Grid item xs={12}>
                    <EntityProcessingErrorsPanel />
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </>
);

const makeOverviewTab = (element: JSX.Element): TabContent => ({
    path: '/',
    title: 'Overview',
    element: (
        <Grid container spacing={3} alignItems="stretch">
            {entityWarningContent}
            {element}
        </Grid>
    ),
});

export const DefaultOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={4} xs={12}>
            <EntityLinksCard />
        </Grid>
        <Grid item md={10} xs={12}>
            <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
    </>,
);

export const SiteOrServiceOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={4} xs={12}>
            <EntityLinksCard />
        </Grid>
        <Grid item md={10} xs={12}>
            <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={4}>
            <AzureCostCard />
        </Grid>
        <EntitySwitch>
            <EntitySwitch.Case if={isServiceHealthPluginApplicableToEntity}>
                <Grid item md={4}>
                    <EntityServiceHealthContent />
                </Grid>
            </EntitySwitch.Case>
        </EntitySwitch>
    </>,
);

export const ApiOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item md={6}>
            <EntityAboutCard />
        </Grid>
        <Grid item md={4} xs={12}>
            <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
            <Grid item md={6}>
                <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
                <EntityConsumingComponentsCard />
            </Grid>
        </Grid>
    </>,
);

export const UserOverviewTab: TabContent = makeOverviewTab(
    <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
            <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
            <OwnershipCard variant="gridItem" entityFilterKind={['Component', 'API', 'System', 'Domain']} />
        </Grid>
    </Grid>,
);

export const GroupOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item xs={12} md={5}>
            <Grid item>
                <EntityGroupProfileCard variant="gridItem" />
            </Grid>

            <Grid item>
                <GroupsLinksCard variant="gridItem" />
            </Grid>
        </Grid>

        <Grid item xs={12} md={5}>
            <OwnershipCard variant="gridItem" entityFilterKind={['Component', 'API', 'System', 'Domain']} />
        </Grid>

        <Grid item xs={12}>
            <EntityMembersListCard />
        </Grid>
    </>,
);

export const SystemOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
            <EntityLinksCard />
        </Grid>
        <Grid item md={6}>
            <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
            <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
            <EntityHasResourcesCard variant="gridItem" />
        </Grid>
    </>,
);

export const DomainOverviewTab: TabContent = makeOverviewTab(
    <>
        <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
            <EntityLinksCard />
        </Grid>
        <Grid item md={6}>
            <EntityHasSystemsCard variant="gridItem" />
        </Grid>
    </>,
);
