import React, { useState } from 'react';
import { EmbeddedDocsRouter, EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { ComponentProps } from 'react';
import { useEntity } from '@backstage/plugin-catalog-react';
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { useAsync } from 'react-use';
import { EmptyState, Link } from '@backstage/core-components';

type EntityTechdocsCustomContentProps = ComponentProps<typeof EntityTechdocsContent>;

type TechdocsStatus = 'loading' | 'notfound' | 'ok';

const TECHDOCS_ANNOTATION = 'backstage.io/techdocs-ref';

type TechDocsEmptyStateProps = { name: string };
const TechDocsEmptyState = ({ name }: TechDocsEmptyStateProps) => (
    <EmptyState
        title="Missing TechDocs"
        description={
            <>
                There is no TechDocs content available for the <strong>{name}</strong> component.
                <br />
                <br />
                If you do not expect this tab to be shown, please remove the{' '}
                <strong>
                    <code>{TECHDOCS_ANNOTATION}</code>
                </strong>{' '}
                annotation from your component definition file.
                <br />
                <br />
                Otherwise, please ensure your TechDocs configuration is correct:
                <ul>
                    <li>
                        Is the value of the{' '}
                        <strong>
                            <code>{TECHDOCS_ANNOTATION}</code>
                        </strong>{' '}
                        annotation correct?
                    </li>
                    <li>
                        Does the{' '}
                        <strong>
                            <code>mkdocs.yml</code>
                        </strong>{' '}
                        file exist in your repository, and is it correctly formatted?
                    </li>
                    <li>
                        Are you triggering TechDocs ingestion appropriately when your repository is updated, using
                        either the{' '}
                        <Link to="https://github.com/CarMax-Internal/online-platform-azure-pipelines/blob/master/src/templates/publish-techdocs.yml">
                            <code>publish-techdocs.yml</code> Azure DevOps Pipeline step
                        </Link>{' '}
                        or the{' '}
                        <Link to="https://onramp.carmax.com/docs/default/component/devex-onramp/plugins/techdocs/#github-webhook">
                            GitHub webhook
                        </Link>
                        ?
                    </li>
                </ul>
                If you have checked all of the above and are still experiencing issues, please contact the DevEx team.
            </>
        }
        missing="field"
    />
);

export const EntityTechdocsCustomContent = (props: EntityTechdocsCustomContentProps) => {
    const entity = useEntity();
    const [status, setStatus] = useState<TechdocsStatus>('loading');

    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);

    useAsync(async () => {
        const entityRef = getCompoundEntityRef(entity.entity);

        const baseUrl = await discoveryApi.getBaseUrl('techdocs');
        const url = `${baseUrl}/metadata/techdocs/${entityRef.namespace}/${entityRef.kind}/${entityRef.name}`;

        const response = await fetchApi.fetch(url);

        if (response.status === 404) {
            setStatus('notfound');
        } else {
            setStatus('ok');
        }
    });

    return status === 'notfound' ? (
        <TechDocsEmptyState name={entity.entity.metadata.name} />
    ) : (
        <EmbeddedDocsRouter {...props} />
    );
};

