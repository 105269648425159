export type ValidationInfo<V> = { rules: V; attributeAliases?: Partial<{ [key in keyof V & string]: string }> };

export function getValidationStatus(validation: any, fieldName: string, touchedFields: any): 'valid' | 'invalid' {
    if (validation && validation[fieldName] && touchedFields[fieldName]) {
        return 'invalid';
    }
    return 'valid';
}

export function getValidationMessage(validation: any, fieldName: string, touchedFields: any) {
    if (validation && validation[fieldName] && touchedFields[fieldName]) {
        return Array.isArray(validation[fieldName]) ? validation[fieldName].join('. ') : validation[fieldName];
    }
    return undefined;
}

export function isValid(validation: any): boolean {
    if (validation && Object.keys(validation).length > 0) return false;
    return true;
}

export function isInvalid(validation: any): boolean {
    return !isValid(validation);
}

export const getLowerCaseAlphaNumericOnlyFormatValidator = () => ({
    pattern: '^[a-z][a-z0-9]*$',
    message:
        'can only contain lower case alphabets and numeric characters, and must start with an alphabetic character.',
});

export const getAlphaNumericFormatValidator = () => ({
    pattern: '^[a-zA-Z_][a-zA-Z0-9_-]*$',
    message:
        'can only contain alphanumeric characters, underscores, or dashes, and must start with an alphabetic character.',
});

export const getNumericFormatValidator = () => ({
    pattern: '^[0-9]*$',
    message: 'can only contain numeric characters.',
});

//Fulltime CL Accounts must be a length of 6, cannot start with the number 9 and must be numeric
export const getCLAccountFullTimeValidator = () => ({
    pattern: '^[0-8][0-9]{5}$',
    message: 'must be at least a length of 6 (7 in the case of employees who are contractors), can only contain numeric characters, and cannot start with the number 9 (unless the employee is a contractor)',
});

//Contractor CL Accounts must be a length of 7, start with the number 9 and must be numeric
export const getCLAccountContractorValidator = () => ({
    pattern: '^[9][0-9]{6}$',
    message: 'must be at least a length of 6 (7 in the case of employees who are contractors), can only contain numeric characters, and cannot start with the number 9 (unless the employee is a contractor)',
});

export const getProjectNameValidator = (example: string) => ({
    pattern: /([a-z_A-Z]\w+(?:\.?[a-z_A-Z]\w+)*)/,
    message: `must be a valid project name, e.g. ${example}`,
});

const addPatternValidationWhenApplies = (pattern: RegExp, value: string, message: string) => {
    if (pattern.test(value)) {
        return {
            pattern,
            message,
        };
    }

    return undefined;
};

export const getAzureSubscriptionNameValidator = (value: string) => {
    return (
        addPatternValidationWhenApplies(
            /subscription/i,
            value,
            'must not contain "Subscription" (will be appended by Cloud Experience team)',
        ) ??
        addPatternValidationWhenApplies(/\s\s/i, value, 'must not contain consecutive spaces') ??
        addPatternValidationWhenApplies(/\B[a-z][A-Z]\B/g, value, 'must have space before beginning of a new word') ??
        addPatternValidationWhenApplies(
            /^[A-Z][a-zA-Z\d\s]*[a-zA-Z\d]$/,
            value,
            'must start with an upper case character, end with an alphanumeric character, and only contain alphanumeric characters or space',
        )
    );
};

export const getHostNameFormatValidator = () => ({
    pattern:
        /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/,
    message: 'must be a valid host name, e.g., webstore-service.carmax.com',
});

export const getShortNameValidator = () => {
    return {
        presence: {
            allowEmpty: false,
        },
        length: {
            maximum: 8,
            message: (value: string) => `is too long (maximum is 8 characters, currently ${value.length})`,
        },
        format: getLowerCaseAlphaNumericOnlyFormatValidator(),
    };
};

