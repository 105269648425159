import { rootRouteRef } from './routes';
import {
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

export const onrampNewsletterPlugin = createPlugin({
    id: 'onramp-newsletter-plugin',
    apis: [],
    routes: {
        root: rootRouteRef,
    },
});

export const NewsletterPage = onrampNewsletterPlugin.provide(
    createRoutableExtension({
        name: 'NewsletterPage',
        component: () =>
            import('./pages/NewsletterPage').then(
                m => m.NewsletterPage,
            ),
        mountPoint: rootRouteRef,
    }),
);
