import { Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { KmxProxyApi, OnrampApi } from '../../onrampCommon';

export const MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION = 'graph.microsoft.com/user-principal-name';

export type HttpMethod = 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';

type ApxApiRequestInit = {
    headers?: Record<string, string>;
    method?: HttpMethod;
    body?: any;
};

let currentUser: Entity | undefined = undefined;

export let apxFetch: ApxApiClient['fetch'] = (
    _path: string,
    _init?: ApxApiRequestInit | undefined,
): Promise<Response> => {
    return Promise.reject('apxFetch has not been initialized');
};

export class ApxApiClient {
    static initialize(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi; onrampApi: OnrampApi }) {
        apxFetch = async (path: string, init?: ApxApiRequestInit) => {
            const apxBaseUrl = options.configApi.getString('applicationProvisioning.baseUrl');
            const retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
            const retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');

            const apxUrl = `${apxBaseUrl}${path}`;

            if (currentUser === undefined) {
                currentUser = await options.onrampApi.getCurrentUser();
            }

            const headers: Record<string, string> = {
                ['from']: (currentUser?.metadata?.annotations ?? {})[MICROSOFT_GRAPH_USER_PRINCIPAL_NAME_ANNOTATION],
                ...(init?.headers ?? {}),
            };

            return await options.kmxProxyApi.performProxiedRequest('apxproxy', {
                url: apxUrl,
                method: init?.method ?? 'GET',
                headers,
                body: init?.body,
            },
            retryLimit,
            retryTimeout);
        };
    }

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi; onrampApi: OnrampApi }) {
        ApxApiClient.initialize(options);
    }

    async fetch(path: string, init?: ApxApiRequestInit): Promise<Response> {
        return await apxFetch(path, init);
    }
}

