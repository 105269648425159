import { ConfigApi, createApiRef } from "@backstage/core-plugin-api";
import { KmxProxyApi } from "../onrampCommon";

declare type FetchApi = {
    fetch: typeof fetch;
};

export interface CompressionApi {
    postCompressedText(text: string): Promise<string>;
    postDecompressedText(text: string): Promise<string>;
}

export class CompressionClient implements CompressionApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly serviceApiBaseUrl: string;

    constructor(options: {
        kmxProxyApi: KmxProxyApi;
        configApi: ConfigApi;
    }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.serviceApiBaseUrl = options.configApi.getString(
            'serviceApi.baseUrl',
        );
    }

    async postCompressedText(text: string): Promise<string> {

        const path = '/api/compress/GZipBase64'
        const compressUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = { url: compressUrl, method: 'POST', body: { text }}
        const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request);

        return await response.text();
    };

    async postDecompressedText(text: string): Promise<string> {

        const path = '/api/decompress/GZipBase64'
        const decompressUrl = `${this.serviceApiBaseUrl}${path}`;

        const request = { url: decompressUrl, method: 'POST', body: { text }}
        const response = await this.kmxProxyApi.performProxiedRequest('onrampfunctionsproxy', request);

        return await response.text();
    };


}

export const compressionApiRef = createApiRef<CompressionApi>({
    id: 'compression-api',
});

