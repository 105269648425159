import React from 'react';
import { LinkText } from './LinkText';

interface LinkButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    className?: string;
}

export function LinkButton({ onClick, children, className }: LinkButtonProps) {
    return (
        <LinkText href="#" onClick={onClick} className={className}>
            {children}
        </LinkText>
    );
}

