import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    svg: {
        height: '24',
        width: '24',
    },
    path: {
        fill: '#ABAFB3',
    },
});

export const Backpack = () => {
    const classes = useStyles();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24"
            viewBox="0 0 24 24"
            width="24"
        >
            <g>
                <rect fill="none" height="24" width="24" y="0" />
                <g>
                    <g>
                        <path
                            className={classes.path}
                            d="M20,8v12c0,1.1-0.9,2-2,2H6c-1.1,0-2-0.9-2-2V8c0-1.86,1.28-3.41,3-3.86V2h3v2h4V2h3v2.14C18.72,4.59,20,6.14,20,8z M6,12v2h10v2h2v-4H6z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
