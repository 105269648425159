import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Content, PageWithHeader } from '@backstage/core-components';
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { AzureCostFilters, AzureCostBarGraph, AzureCostIssuesAccordion } from '../components';
import { azureCostApiRef } from '../api';
import { CostDisplayData, CostGroup, GroupedCostlyResources, GroupedIssues, QueryData } from '../types';
import { useOnrampTheme } from '../../../theme';
import { AzureCostlyResourcesAccordion } from '../components/AzureCostlyResourcesAccordion';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    content: { maxWidth: '1280px' },
}));

export const CostPage = () => {
    const [groups, setGroups] = useState<CostGroup[]>([]);
    const [displayData, setDisplayData] = useState<CostDisplayData[]>([]);
    const [issues, setIssues] = useState<GroupedIssues[]>([]);
    const [costlyResources, setCostlyResources] = useState<GroupedCostlyResources[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const azureCostApi = useApi(azureCostApiRef);
    const theme = useOnrampTheme();
    const index = theme.zIndex.drawer + 1;

    const styles = useStyles();

    // just do this at load
    useEffect(() => {
        const getCostGroupData = async () => {
            const costPrograms = await azureCostApi.getCostPrograms();
            setGroups(costPrograms);
        };
        // eslint-disable-next-line no-console
        getCostGroupData().catch(console.error);
    }, [azureCostApi]);

    const manageIssue = useCallback(
        async (id: string, status: string) => {
            await azureCostApi.updateIssueStatus(id, status);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [azureCostApi],
    );

    const updateQueryString = useCallback(
        async (data: QueryData) => {
            let costdata: CostDisplayData[];
            let apiIssues: GroupedIssues[] = [];
            let costResources: GroupedCostlyResources[] = [];

            setLoading(true);

            if (data.detailLevel === 'program') {
                const programOwners = groups
                    .filter((x: any) => data.programs.indexOf(x.id) !== -1)
                    .flatMap(x => x.members);
                costdata = await azureCostApi.getCostByProgram(data.startDate, data.endDate, data.programs);
                apiIssues = await azureCostApi.getCostIssuesByOwner(programOwners);
                costResources = await azureCostApi.getCostlyResourcesByOwner(programOwners);
            } else if (data.detailLevel === 'owner') {
                costdata = await azureCostApi.getCostByOwner(data.startDate, data.endDate, data.owners);
                apiIssues = await azureCostApi.getCostIssuesByOwner(data.owners);
                costResources = await azureCostApi.getCostlyResourcesByOwner(data.owners);
            } else {
                costdata = await azureCostApi.getCostByResource(
                    data.startDate,
                    data.endDate,
                    data.owners,
                    data.resources,
                );
                apiIssues = await azureCostApi.getCostIssuesByOwner(data.owners);
                costResources = await azureCostApi.getCostlyResourcesByOwner(data.owners);
            }

            setDisplayData(costdata);
            setIssues(apiIssues);
            setCostlyResources(costResources);
            setLoading(false);
        },
        [azureCostApi, groups],
    );

    return (
        <PageWithHeader title="Azure Costs" themeId="tool">
            <Content className={styles.content}>
                <Grid>
                    <>
                        <Backdrop open={loading} style={{ zIndex: index }}>
                            <CircularProgress />
                        </Backdrop>
                    </>
                    <Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <AzureCostFilters groups={groups} queryData={updateQueryString} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <AzureCostBarGraph title="Cost Detail" data={displayData} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <AzureCostIssuesAccordion identifiedIssues={issues} manageIssue={manageIssue} />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <AzureCostlyResourcesAccordion costlyResources={costlyResources} />
                        </Grid>
                    </Grid>
                </Grid>
            </Content>
        </PageWithHeader>
    );
};
