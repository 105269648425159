import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { Entity } from '@backstage/catalog-model';
import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined';
import _ from 'lodash';

type GroupHierarchyProps = {
    groups: Entity[];
    hierarchy: string[];
    preselectedGroup: string;
    setSelectedGroup: (node: string) => void;
};

type GroupTreeItem = {
    groupName: string;
    groupParent: string;
    children?: GroupTreeItem[];
};

export const GroupTreeHierarchy = ({ groups, hierarchy, preselectedGroup, setSelectedGroup }: GroupHierarchyProps) => {
    const [tree, setTree] = useState<GroupTreeItem>({ groupName: '', groupParent: '', children: [] });
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [selected, setSelected] = React.useState<string>();

    const buildGroupTree = (groupList: Entity[]) => {
        function getChildren(parent: string, groupTreeItemList: GroupTreeItem[]): GroupTreeItem[] {
            const groupItemChildren = groupTreeItemList.filter(x => x.groupParent === parent);
            return groupItemChildren.map((localItem: GroupTreeItem) => ({
                groupName: localItem.groupName,
                groupParent: localItem.groupParent,
                children: getChildren(localItem.groupName, groupTreeItemList),
            }));
        }

        let root;
        if (groupList.length > 0) {
            const localGroupList: GroupTreeItem[] = groupList.map(
                (groupEnt: Entity) =>
                    ({
                        groupName: groupEnt.metadata.name,
                        groupParent: groupEnt.spec?.parent ?? null,
                        children: [],
                    } as GroupTreeItem),
            );
            root = localGroupList.filter((groupItem: GroupTreeItem) => groupItem.groupName === 'carmax')[0];
            root.children = getChildren(root.groupName, localGroupList);
        } else {
            root = { groupName: '', groupParent: '', children: [] };
        }
        return root;
    };

    useEffect(() => {
        const localHierarchy = hierarchy.length > 0 ? hierarchy : ['carmax'];
        const defaultGroup = preselectedGroup !== '' ? preselectedGroup : 'carmax';

        setExpanded(localHierarchy);
        setSelected(defaultGroup);
        const treeData = buildGroupTree(groups);
        if (treeData) {
            setTree(treeData);
        }
        // leaving out preselectedGroup since that should only fire once at page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, hierarchy]);

    const renderTree = (nodes: GroupTreeItem) => (
        <TreeItem key={nodes.groupName} itemId={nodes.groupName} label={nodes.groupName}>
            {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
        </TreeItem>
    );

    const handleToggle = useCallback((_event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    }, []);

    const handleSelect = useCallback(
        (_event: React.ChangeEvent<{}>, itemIds: string | null) => {
            if(itemIds != null)
            {
                setSelected(itemIds as string);
                setSelectedGroup(itemIds as string);
            }
        },
        [setSelectedGroup],
    );

    return (
        <Grid item xs={12}>
            <SimpleTreeView
                multiSelect={false}
                aria-label="controlled"
                slots={{ expandIcon: ChevronRightOutlined, collapseIcon: ExpandMoreOutlined}}
                expandedItems={expanded}
                selectedItems={selected}
                onExpandedItemsChange={handleToggle}
                onSelectedItemsChange={handleSelect}
                style={{ height: '450px', overflowY: 'auto' }}
            >
                {renderTree(tree)}
            </SimpleTreeView>
        </Grid>
    );
};
