import { RadarEntry } from '@backstage/plugin-tech-radar';
import { EntryData } from './types';
import { getRadarItems } from './util';

const radarEntries: EntryData[] = [
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'stackenterprise',
        title: 'Stack Overflow Enterprise',
        links: [
            {
                title: 'Stack Overflow Enterprise',
                url: 'https://carmax.stackenterprise.co/',
            },
        ],
        description: `CarMax's private Q&A forum powered by Stack Overflow.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'yarn',
        title: 'Yarn',
        links: [
            {
                title: 'Yarn',
                url: 'https://yarnpkg.com/',
            },
        ],
        description: `A JavaScript package manager.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'visualstudio',
        title: 'Visual Studio',
        links: [
            {
                title: 'Visual Studio',
                url: 'https://visualstudio.microsoft.com/',
            },
        ],
        description: 'Full-featured IDE for Windows development.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'visualstudiocode',
        title: 'Visual Studio Code',
        links: [
            {
                title: 'Visual Studio Code',
                url: 'https://code.visualstudio.com/',
            },
        ],
        description: 'A Cross-platform, light-weight source code editor with a a rich ecosystem of extensions.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'artifactory',
        title: 'Artifactory',
        links: [
            {
                title: 'Artifactory',
                url: 'https://jfrog.com/artifactory/',
            },
            {
                title: 'CarMax Repository',
                url: 'https://carmax.jfrog.io/ui/login/',
            },
        ],
        description: `JFrog's artifact repository for container images, libraries, and more.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'npm',
        title: 'npm',
        links: [
            {
                title: 'npm',
                url: 'https://www.npmjs.com/',
            },
        ],
        description: 'Package manager for Node.js.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'pnpm',
        title: 'pnpm',
        links: [
            {
                title: 'pnpm',
                url: 'https://pnpm.io/',
            },
        ],
        description: 'Another package manager for Node.js.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'lit',
        title: 'Lit',
        links: [
            {
                title: 'Lit',
                url: 'https://lit.dev/',
            },
        ],
        description:
            'Lit is a simple library for building fast, lightweight web components that work in any framework.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'webpack',
        title: 'webpack',
        links: [
            {
                title: 'webpack',
                url: 'https://webpack.js.org/',
            },
        ],
        description: 'webpack is a static module bundler for modern JavaScript applications.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'postman',
        title: 'Postman',
        links: [
            {
                title: 'Postman',
                url: 'https://www.postman.com/',
            },
        ],
        description: 'Postman is an API platform for building and using APIs.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'adobetarget',
        title: 'Adobe Target',
        links: [
            {
                title: 'Adobe Target',
                url: 'https://business.adobe.com/products/target/adobe-target.html',
            },
        ],
        description: 'Adobe Target enables user-testing (like A/B tests) and personalization on applications.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'fullstory',
        title: 'FullStory',
        links: [
            {
                title: 'FullStory',
                url: 'https://www.fullstory.com/',
            },
        ],
        description: 'A platform for capturing user experience insights.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-03-20'),
        key: 'leankit',
        title: 'LeanKit/AgilePlace',
        links: [
            {
                title: 'LeanKit',
                url: 'https://www.planview.com/products-solutions/products/agileplace/',
            },
            {
                title: 'CarMax boards',
                url: 'https://carmax.leankit.com/',
            },
        ],
        description: 'Online Kanban boards to visually track and manage work flows.  Teams are also assessing Azure Boards as an alternative.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'wsl2',
        title: 'Windows Subsystem for Linux',
        links: [
            {
                title: 'Windows Subsystem for Linux',
                url: 'https://learn.microsoft.com/en-us/windows/wsl/about',
            },
        ],
        description: `The Windows Subsystem for Linux lets developers run a Linux environment
            directly on Windows without the overhead of a traditional virtual machine or dualboot setup.`,
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'vite',
        title: 'Vite',
        links: [
            {
                title: 'Vite',
                url: 'https://vitejs.dev/',
            },
        ],
        description: `Vite is a tool for scaffolding and bundling projects, 
            popular due to near-instant code compilation and rapid hot module replacement.`,
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'podman',
        title: 'Podman',
        links: [
            {
                title: 'Podman',
                url: 'https://podman.io/',
            },
        ],
        description: `Podman is a daemonless container engine for developing, managing, and running OCI Containers.
            Many times it can be used in lieu of Docker.`,
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'stoplight',
        title: 'Stoplight Studio',
        links: [
            {
                title: 'Stoplight Studio',
                url: 'https://stoplight.io/studio',
            },
        ],
        description: 'An editor for API design, modeling, and technical writing.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'dockerdesktop',
        title: 'Docker Desktop',
        links: [
            {
                title: 'Docker Desktop',
                url: 'https://www.docker.com/products/docker-desktop/',
            },
        ],
        description: `Prefer Podman if possible.  If not, requests for a license may be made through ServiceNow.`,
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'teamcity',
        title: 'TeamCity',
        links: [
            {
                title: 'TeamCity',
                url: 'https://www.jetbrains.com/teamcity/',
            },
        ],
        description: `On-premises CI/CD server from JetBrains.  Prefer Azure DevOps Pipelines.`,
    },
];

export const tools = (): RadarEntry[] => getRadarItems(radarEntries, 'tools');
