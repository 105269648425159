import { Content, Header, Page } from '@backstage/core-components';
import { analyticsApiRef, useApi } from '@backstage/core-plugin-api';
import { SearchType } from '@backstage/plugin-search';
import {
    DefaultResultListItem,
    SearchBar,
    SearchResult,
    SearchResultPager,
    useSearch,
} from '@backstage/plugin-search-react';
import { Grid, List, Paper, Theme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { searchResultTypes } from '../searchResultTypes';
import { OnrampSearchFilters } from '../components/OnrampSearchFilters';
import { SearchDocument } from '@backstage/plugin-search-common';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    bar: {
        padding: theme.spacing(1, 2),
    },
}));

export const OnrampSearchPage = () => {
    const classes = useStyles();
    const { term } = useSearch();
    const analyticsApi = useApi(analyticsApiRef);

    useEffect(() => {
        if (term.length > 2) {
            analyticsApi.captureEvent({
                action: 'kmxsearch',
                subject: term,
                context: {
                    pluginId: 'kmxsearch',
                    routeRef: '/kmxsearch',
                    extension: 'App',
                },
            });
        }
    }, [term, analyticsApi]);

    const contentRenderers = useMemo(() => {
        const renderers: Record<string, (document: SearchDocument) => React.ReactNode> = {};
        for (const searchResultType of searchResultTypes) {
            renderers[searchResultType.value] = searchResultType.render;
        }
        return renderers;
    }, []);

    return (
        <Page themeId="home">
            <Header title="Search" />
            <Content>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <Paper className={classes.bar}>
                            <SearchBar debounceTime={100} />
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <SearchType.Accordion name="Result Type" defaultValue="" types={searchResultTypes} />
                        <OnrampSearchFilters />
                    </Grid>
                    <Grid item xs>
                        <SearchResult>
                            {({ results }) => (
                                <List>
                                    {results.map(({ type, document }) => {
                                        UnknownAuthorCheck(document);
                                        UnicodeCheck(document);
                                        if (contentRenderers[type]) {
                                            return contentRenderers[type](document);
                                        }
                                        return <DefaultResultListItem key={document.location} result={document} />;
                                    })}
                                </List>
                            )}
                        </SearchResult>
                        <SearchResultPager />
                    </Grid>
                </Grid>
            </Content>
        </Page>
    );
};

function UnknownAuthorCheck(document: SearchDocument) {
    const authorRegex = new RegExp('^user\\d+$');
    if (authorRegex.test(document.text)) {
        document.text = 'Unknown';
    }
}

function UnicodeCheck(document: SearchDocument) {
    const htmlUnicodeRegex = new RegExp('&#([0-9a-fA-F]{1,4});');
    if (htmlUnicodeRegex.test(document.text)) {
        document.text = convertUnicode(document.text.replace('&#', '\\u').replace(';', ''));
    }
}

function convertUnicode(input: string) {
    return input.replace(/\\+u([0-9a-fA-F]{1,4})/g, (_a, b) => String.fromCharCode(parseInt(b)));
}
