import { useEffect } from 'react';
import { useOnrampTheme } from '../../theme';

export function useUpdateHorizonTheme() {
    const theme = useOnrampTheme();

    useEffect(() => {
        if (theme.palette?.mode) {
            document.getElementsByTagName('html')[0].setAttribute('data-theme', theme.palette.mode);
        }
    }, [theme.palette?.mode]);
}
