import { appInsightsApiRef, AppInsightsClient } from './api';
import { rootRouteRef, scaffolderRouteRef } from './routes';
import { configApiRef, createApiFactory, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { kmxProxyApiRef } from '../onrampCommon';

export const appInsightsPlugin = createPlugin({
    id: 'backstage-appinsights-plugin',
    apis: [
        createApiFactory({
            api: appInsightsApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new AppInsightsClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
    externalRoutes: {
        scaffolder: scaffolderRouteRef,
    },
    routes: {
        root: rootRouteRef,
    },
});

export const EntityApplicationInsightsContent = appInsightsPlugin.provide(
    createRoutableExtension({
        name: 'EntityApplicationInsightsContent',
        component: () =>
            import('./pages/EntityApplicationInsightsContent').then(m => m.EntityApplicationInsightsContent),
        mountPoint: rootRouteRef,
    }),
);

