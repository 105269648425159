import { RadarEntry } from '@backstage/plugin-tech-radar';
import { EntryData } from './types';
import { getRadarItems } from './util';

const radarEntries: EntryData[] = [
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'azuredevops',
        title: 'Azure DevOps',
        links: [
            {
                title: 'CarMax DevOps Organization',
                url: 'https://dev.azure.com/CarMax/',
            },
            {
                title: 'Azure DevOps',
                url: 'https://azure.microsoft.com/en-us/products/devops/',
            },
        ],
        description: `CarMax currently leverages Azure Pipelines for its CI/CD capabilities,
            but teams are starting to explore Azure Boards and the other offerings as well.`,
    },

    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'github',
        title: 'GitHub',
        links: [
            {
                title: 'GitHub',
                url: 'https://github.com/CarMax-Internal/',
            },
        ],
        description: `GitHub provides Git distributed version control plus bug tracking, software feature requests, 
            task management, continuous integration, and wikis.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'servicenow',
        title: 'servicenow',
        links: [
            {
                title: 'servicenow',
                url: 'https://www.servicenow.com/',
            },
            {
                title: 'CarMax Service Center',
                url: 'https://carmax.service-now.com/css',
            },
        ],
        description: `A PaaS provider of IT service management.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'oracleclouderp',
        title: 'Oracle Cloud ERP',
        links: [
            {
                title: 'Oracle Cloud ERP',
                url: 'https://www.oracle.com/erp/',
            },
        ],
        description: 'Enterprise Resource Planning solution from Oracle.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'venafi',
        title: 'Venafi Aperture',
        links: [
            {
                title: 'Venafi',
                url: 'https://venafi.com/',
            },
            {
                title: 'CarMax Venafi',
                url: 'https://certificate.carmax.org/',
            },
            {
                title: 'CarMax Aperture Documentation',
                url: 'https://psychic-adventure-49gqlj4.pages.github.io/#/self_service/gui',
            },
        ],
        description: 'Platform for provisioning and managing digital certificates.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'akamai',
        title: 'Akamai',
        links: [
            {
                title: 'Akamai',
                url: 'https://www.akamai.com/',
            },
            {
                title: 'CarMax Control Panel',
                url: 'https://control.akamai.com/',
            },
        ],
        description: 'Provides CDN services, WAF, DDoS protection, BOT detection, DNS management, and more.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'salesforcecrm',
        title: 'Salesforce CRM',
        links: [
            {
                title: 'Salesforce CRM',
                url: 'https://www.salesforce.com/crm/',
            },
            {
                title: 'CarMax SF B2C QA',
                url: 'https://carmax--qa.sandbox.lightning.force.com/',
            },
            {
                title: 'CarMax SF B2B QA',
                url: 'https://carmaxb2b--qa.sandbox.lightning.force.com/',
            },
        ],
        description: `CarMax's Customer Relationship Management platform.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'oraclelogistics',
        title: 'Oracle Logistics',
        links: [
            {
                title: 'Oracle Logistics',
                url: 'https://www.oracle.com/scm/logistics/',
            },
        ],
        description: 'Platform for managing the logistics of moving vehicles.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'onramp',
        title: 'Onramp',
        links: [
            {
                title: 'Onramp',
                url: 'https://onramp.carmax.com/',
            },
            {
                title: 'Backstage',
                url: 'https://backstage.io/',
            },
        ],
        description: 'A developer portal for discovering, building, and managing applications.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'pagerduty',
        title: 'PagerDuty',
        links: [
            {
                title: 'PagerDuty',
                url: 'https://www.pagerduty.com/',
            },
            {
                title: 'CarMax Landing Page',
                url: 'https://carmax.pagerduty.com/',
            },
        ],
        description: 'An incident response platform used by CarMax.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'splunk',
        title: 'Splunk',
        links: [
            {
                title: 'CarMax Splunk',
                url: 'https://search.splunk.carmax.org/en-US/app/launcher/home',
            },
            {
                title: 'Splunk Corporation',
                url: 'https://www.splunk.com/',
            },
        ],
        description: 'A platform for collecting, searching, monitoring, and analyzing data.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'enterprisemessaging',
        title: 'Enterprise Messaging',
        links: [
            {
                title: 'Enterprise Messaging',
                url: 'https://messaging.sites.carmax.com/',
            },
            {
                title: 'Event Grid',
                url: 'https://azure.microsoft.com/en-us/products/event-grid',
            },
        ],
        description:
            'A messaging platform for publishing and subscribing to events.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'msazure',
        title: 'Microsoft Azure',
        links: [
            {
                title: 'Microsoft Azure',
                url: 'https://azure.microsoft.com/en-us',
            },
        ],
        description: `Microsoft's cloud solution.  In general, prefer PaaS offerings over IaaS.`,
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'apigee',
        title: 'Apigee',
        links: [
            {
                title: 'Apigee',
                url: 'https://cloud.google.com/apigee',
            },
            {
                title: 'CarMax Apigee',
                url: 'https://onramp.carmax.com/apigee',
            },
        ],
        description:
            'An API management platform from Google.  It is typically used in CarMax when Azure applications need to communicate with on-premises applications.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'karbon',
        title: 'Nutanix Karbon',
        links: [
            {
                title: 'Nutanix Karbon',
                url: 'https://www.nutanix.com/blog/nutanix-karbon-enterprise-grade-kubernetes-solution',
            },
        ],
        description:
            'An on-premises enterprise Kubernetes management solution.  It may be used to host applications that require access to store systems.',
    },
    {
        ringId: 'assess',
        date: new Date('2023-02-28'),
        key: 'launchdarkly',
        title: 'LaunchDarkly',
        links: [
            {
                title: 'LaunchDarkly',
                url: 'https://launchdarkly.com/',
            },
        ],
        description: 'A platform for managing feature flags.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'aws',
        title: 'AWS',
        links: [
            {
                title: 'Amazon Web Services',
                url: 'https://aws.amazon.com/',
            },
        ],
        description: 'CarMax utilizes Azure for most applications instead of AWS.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'gcp',
        title: 'Google Cloud',
        links: [
            {
                title: 'Google Cloud',
                url: 'https://cloud.google.com/',
            },
        ],
        description: 'CarMax utilizes Azure for most applications instead of GCP.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'oraclecloud',
        title: 'Oracle Could (non-ERP)',
        links: [
            {
                title: 'Oracle Could',
                url: 'https://www.oracle.com/cloud/',
            },
        ],
        description:
            'CarMax utilizes Azure for most applications instead of Oracle Cloud, except for ERP and logistics.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'onprem',
        title: 'On-Prem Greenfield Development',
        links: [
            {
                title: 'On-Prem Greenfield Development',
                url: 'https://www.hpe.com/us/en/what-is/on-premises-vs-cloud.html',
            },
        ],
        description: 'CarMax utilizes Azure for most new applications instead of on-premises data centers.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'tibco',
        title: 'Tibco ESB',
        links: [
            {
                title: 'Tibco ESB',
                url: 'https://www.tibco.com/events-and-messaging',
            },
        ],
        description: 'CarMax utilizes Azure messaging solutions for most new applications instead of Tibco.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'sybase',
        title: 'Sybase',
        links: [
            {
                title: 'Sybase',
                url: 'https://www.sap.com/products/acquired-brands/what-is-sybase.html',
            },
        ],
        description: 'CarMax utilizes Azure database for most new applications instead of Sybase.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'biztalk',
        title: 'Biztalk',
        links: [
            {
                title: 'Biztalk',
                url: 'https://learn.microsoft.com/en-us/biztalk/',
            },
        ],
        description: 'Prefer other Azure solution for new applications instead of Biztalk.',
    },
];

export const platforms = (): RadarEntry[] => getRadarItems(radarEntries, 'platforms');
