import { BackstagePlugin } from '@backstage/core-plugin-api';
import { apigeePlugin } from './plugins/apigee';
import { apiKeyGeneratorPlugin } from './plugins/apikeyGenerator';
import { appInsightsPlugin } from './plugins/appInsights';
import { applicationProvisioningPlugin } from './plugins/applicationProvisioning';
import { compressionPlugin } from './plugins/compression';
import { enterpriseMessagingPlugin } from './plugins/enterpriseMessaging';
import { fullStoryPlugin } from './plugins/fullStory';
import { kmxGroupsPlugin } from './plugins/kmxGroups';
import { MessageQueuesManagementPlugin } from './plugins/queueManagement';
import { onrampPlugin } from './plugins/onrampCommon/plugin';
import { onrampNewsletterPlugin } from './plugins/newsletter/plugin';
import { onrampSearchPlugin } from './plugins/onrampSearch';
import { onrampShortcutsPlugin } from './plugins/shortcuts';
import { onrampUsagePlugin } from './plugins/onrampUsage';
import { serviceHealthPlugin } from './plugins/serviceHealth';
import { onrampOnboardingPlugin } from './plugins/onboarding';

export const plugins: BackstagePlugin[] = [
    apigeePlugin,
    apiKeyGeneratorPlugin,

    appInsightsPlugin,
    applicationProvisioningPlugin,

    compressionPlugin,
    enterpriseMessagingPlugin,
    fullStoryPlugin,
    kmxGroupsPlugin,
    MessageQueuesManagementPlugin,

    onrampNewsletterPlugin,
    onrampOnboardingPlugin,
    onrampPlugin,
    onrampSearchPlugin,
    onrampShortcutsPlugin,
    onrampUsagePlugin,

    serviceHealthPlugin,
];
