export class CostItem {
    startDate: Date;
    endDate: Date;
    cost: number;
    nameOfMonth: string;

    constructor(startDate: string, endDate: string, cost: number) {
        this.startDate = new Date(startDate);
        this.endDate = new Date(endDate);
        this.cost = cost;

        this.nameOfMonth = this.startDate.toLocaleString('default', {
            month: 'short',
        });
    }
}
