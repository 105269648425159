import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { Entity } from '@backstage/catalog-model';
import { DEVOPS_METRICS_SERVICEID_ANNOTATION } from '../constants';

const getServiceIdAnnotationName = (environment?: string) => {
    let annotationName = DEVOPS_METRICS_SERVICEID_ANNOTATION;

    if (environment && environment !== 'prod') {
        annotationName = `${DEVOPS_METRICS_SERVICEID_ANNOTATION}Qa`;
    }

    return annotationName;
};

const getServiceId = (entity?: Entity, environment?: string) => {
    const annotationName = getServiceIdAnnotationName(environment);
    const annotations = entity?.metadata?.annotations ?? {};
    return annotations[annotationName];
};

export const isPluginApplicableToEntity = (entity: Entity) => {
    return getServiceId(entity) !== undefined;
};

export interface DevOpsMetricsPluginApi {
    getServiceIdAnnotationName(): string;
    getServiceId(entity?: Entity): string;
    isApplicableToEntity(entity: Entity): boolean;
}

export class DevOpsMetricsPlugin implements DevOpsMetricsPluginApi {
    private readonly environment: string;

    constructor(options: { configApi: ConfigApi }) {
        this.environment = options.configApi.getString('onramp.environment');
    }

    getServiceIdAnnotationName = () =>
        getServiceIdAnnotationName(this.environment);

    getServiceId = (entity?: Entity) => getServiceId(entity, this.environment);

    isApplicableToEntity = (entity: Entity) =>
        this.getServiceId(entity) !== undefined;
}

export const devOpsMetricsPluginApiRef = createApiRef<DevOpsMetricsPluginApi>({
    id: 'devopsmetricsplugin',
});

