import { useApi } from '@backstage/core-plugin-api';
import { debounce, isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useAsync } from 'react-use';
import { apxApiRef } from '../apis';
import { ResourcePreviews } from '../types/lookups';

type ResourceType = 'Service Principal' | 'Azure Subscription';

const setPreviewedResourceName = (
    result: ResourcePreviews,
    type: ResourceType,
    defaultValue: string,
    setName: Dispatch<SetStateAction<string>>,
) => {
    const previewedName = result?.data?.find(d => d.type === type)?.name;
    if (previewedName) {
        setName(previewedName);
    } else {
        setName(defaultValue);
    }
};

export const useSpoNamePreview = (
    rawProjectName: string,
    rawApplicationName: string,
    rawEnvironment: string,
    waitTimeMs: number = 200,
) => {
    // This is our "best guess" as to what the SPO name will be.
    // We'll use it as a fallback only if the "Preview" API call fails.
    const getDefaultSpoName = (p: string, a: string, e: string) =>
        `${p.toLowerCase()}-${a.toLowerCase()}-${e.toLowerCase()}-azuredevops.com`;

    const [spoName, setSpoName] = useState(getDefaultSpoName(rawProjectName, rawApplicationName, rawEnvironment) ?? '');

    const apxApi = useApi(apxApiRef);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLookup = useCallback(
        debounce(async (rawProjectNameInner: string, rawApplicationNameInner: string, rawEnvironmentInner: string) => {
            try {
                const result = await apxApi.getSpoResourcePreviews(
                    rawProjectNameInner,
                    rawApplicationNameInner,
                    rawEnvironmentInner,
                );

                setPreviewedResourceName(
                    result,
                    'Service Principal',
                    getDefaultSpoName(rawProjectNameInner, rawApplicationNameInner, rawEnvironmentInner),
                    setSpoName,
                );
            } catch (e: unknown) {
                console.error(`Error calling getSpoResourcePreviews: ${(e as Error).message}`);
            }
        }, waitTimeMs),
        [],
    );

    useAsync(async () => {
        debouncedLookup(rawProjectName, rawApplicationName, rawEnvironment);
    }, [rawProjectName, rawApplicationName, rawEnvironment]);

    return spoName;
};

export const useSubscriptionNamePreview = (rawSubscriptionName: string, waitTimeMs: number = 200) => {
    const [subscriptionName, setSubscriptionName] = useState(rawSubscriptionName ?? '');

    const apxApi = useApi(apxApiRef);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedLookup = useCallback(
        debounce(async (rawSubscriptionNameInner: string) => {
            if (isEmpty(rawSubscriptionNameInner)) {
                return;
            }
            try {
                const result = await apxApi.getSubscriptionResourcePreviews(rawSubscriptionNameInner);

                setPreviewedResourceName(
                    result,
                    'Azure Subscription',
                    `${rawSubscriptionNameInner} Subscription`,
                    setSubscriptionName,
                );
            } catch (e: unknown) {
                console.error(`Error calling getSubscriptionResourcePreviews: ${(e as Error).message}`);
            }
        }, waitTimeMs),
        [],
    );

    useAsync(async () => {
        debouncedLookup(rawSubscriptionName);
    }, [rawSubscriptionName]);

    return subscriptionName;
};
