import { rootRouteRef } from './routes';
import {
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

export const onrampOnboardingPlugin = createPlugin({
    id: 'onramp-onboarding-plugin',
    apis: [],
    routes: {
        root: rootRouteRef,
    },
});

export const OnboardingPage = onrampOnboardingPlugin.provide(
    createRoutableExtension({
        name: 'OnboardingPage',
        component: () =>
            import('./pages/OnboardingPage').then(
                m => m.OnboardingPage,
            ),
        mountPoint: rootRouteRef,
    }),
);
