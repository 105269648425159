import React from 'react';
import { useOnrampTheme } from '../../../theme';

type ApigeeIconProps = {
    monochrome?: boolean;
    width?: number;
    height?: number;
};

export const ApigeeIcon = ({ monochrome, width, height }: ApigeeIconProps) => {
    const theme = useOnrampTheme();
    const primaryColor = monochrome
        ? theme.palette.icons.apigee.monochrome
        : theme.palette.icons.apigee.primary;
    const secondaryColor = monochrome
        ? theme.palette.icons.apigee.monochrome
        : theme.palette.icons.apigee.secondary;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 81 82"
            fill="#fff"
            fillRule="evenodd"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            width={width ?? 20}
            height={height ?? 20}
        >
            <g stroke="none">
                <path
                    d="M13.042 40l5.233-5.233-7.096-7.096c-3.281-3.341-4.244-8.32-2.446-12.644s6.009-7.15 10.692-7.178c3.08-.001 6.033 1.226 8.206 3.409l7.136 7.096L40 13.042l-7.096-7.096A19.07 19.07 0 0 0 14.296.608 19.07 19.07 0 0 0 .608 14.296a19.07 19.07 0 0 0 5.338 18.608zm53.916 0l-5.233 5.233 7.096 7.136a11.66 11.66 0 0 1-16.492 16.452l-7.096-7.096L40 66.958l7.096 7.096a19.07 19.07 0 1 0 26.958-26.958z"
                    fill={primaryColor}
                />
                <path
                    d="M27.671 68.821c-4.554 4.554-11.938 4.554-16.492 0s-4.554-11.938 0-16.492l7.096-7.096 16.492 16.492zm24.698-57.642c3.327-3.321 8.328-4.31 12.668-2.504s7.164 6.049 7.154 10.75c-.023 3.087-1.246 6.044-3.409 8.246l-7.096 7.096-16.452-16.492zM40 52.646A12.69 12.69 0 0 1 27.354 40h0A12.73 12.73 0 0 1 40 27.354h0A12.73 12.73 0 0 1 52.646 40h0A12.69 12.69 0 0 1 40 52.646zM66.958 40l7.096-7.096A19.07 19.07 0 0 0 65.704.608a19.07 19.07 0 0 0-18.608 5.338L40 13.042 13.042 40l-7.096 7.096A19.07 19.07 0 0 0 .608 65.704a19.07 19.07 0 0 0 32.296 8.35L40 66.958z"
                    fill={secondaryColor}
                />
            </g>
        </svg>
    );
};

