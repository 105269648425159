import { useState } from 'react';
import type { ValidationInfo } from '../common/validationHelpers';
import { getValidationMessage } from '../common/validationHelpers';
import { prettify, validate } from 'validate.js';
import { path } from 'ramda';
import { SelectChangeEvent } from '@mui/material';

type SelectEventHandler<T> = (event: SelectChangeEvent<T>) => void;

type BlurEvent = React.FocusEvent<HTMLInputElement>;

type BlurEventHandler = (event: BlurEvent) => void;

interface SelectProps<T> {
    value: T | undefined;
    onChange: SelectEventHandler<T>;
    onBlur: BlurEventHandler;
}

export default function useSelect<T extends string | number, U, V>(
    value: T | undefined,
    fieldName: (keyof U & string) | (keyof V & string),
    setValue: (v: T, label?: string) => void,
    currentState: U,
    validationInfo?: ValidationInfo<V>,
    helperText?: string,
): SelectProps<T> {
    const [validation, setValidation] = useState({});
    const [touched, setTouched] = useState(false);

    const getValidation = (valueInner: string) => {
        return validate({ ...currentState, [fieldName]: valueInner }, validationInfo?.rules, {
            prettify: (attr: keyof V & string) =>
                (validationInfo?.attributeAliases && validationInfo?.attributeAliases[attr]) || prettify(attr),
        });
    };

    const handleChange = (e: SelectChangeEvent<T>) => {
        const label = (path(['target', 'selectedOptions', 0, 'label'], e) as any)?.toString();
        setValue(e.target.value as T, label);
    };

    const handleBlur = (e: BlurEvent) => {
        setTouched(true);
        setValidation(getValidation(e.target.value));
    };

    const props = {
        value,
        helperText: getValidationMessage(validation, fieldName, { [fieldName]: touched }) || helperText,
        onChange: handleChange,
        onBlur: handleBlur,
    };

    return props;
}

