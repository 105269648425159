import React from 'react';
import { DevExTeamLink } from '../external-links';

export function HelpRequest() {
    return (
        <p className="onboarding-help-request">
            Need more help? <br /> Contact the DevEx team <DevExTeamLink text="here" />.
        </p>
    );
}

