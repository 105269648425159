import React from 'react';
import { Grid } from '@mui/material';

type RowProps = {
    children: React.ReactNode;
    className?: string;
};

export const Row = ({ children, className }: RowProps) => {
    return (
        <Grid container className={className}>
            {children}
        </Grid>
    );
};
