import React from 'react';
import { ExternalLink } from '../applicationProvisioning/components/external-links/ExternalLink';
import { Display } from '../applicationProvisioning/components/typography';

export const AzureCostGettingStarted = () => {
    return (
        <div>
            &nbsp;
            <Display level={3}>Getting Started</Display>
            <p>
                The Onramp Azure Cost page pulls data directly from Azure. Owners and Programs are based on tagging, so it's important to ensure your tagging is correct.
            </p>
            <p>
                The top graph defaults to a Program level, showing a month-to-month view of all Programs' data for this month and the previous 12 months.
                <ul>
                    <li>Adjust the Start Date and End Date as needed to see your desired time range.</li>
                    <li>The Detail Level field determines whether data in the graph is shown rolled up to the Program level or rolled up to the Owner level. Choose your desired value.</li>
                    <li>The Programs value allows you to filter to show just the desired Programs.</li>
                    <li>If the Detail Level is Owner, an Owners field will display, allowing you to filter to show just the desired Owners.</li>
                </ul>
                Example Uses:
                <ul>
                    <li>If you want to see the data for Steve Allocco’s program, choose “steve_j_allocco@carmax.com” in the Programs dropdown. If Detail Level is Program, you will see the monthly totals for the Program. If Detail Level is set to Owner, you will see the monthly totals broken down by Owner. Ensure that the Owners field is set to “Select All” to see all owners in that Program.</li>
                    <li>If you want to see the data for just the things owned by Eddy Simmons, set Detail Level to “Owner”. If you know Eddy’s Program, choose it in the Program field to shorten the list of Owners to just the ones within that Program. Choose “eddy_simmons@carmax.com” in the Owners dropdown.</li>
                </ul>
            </p>
            <p>
                Scroll down to the Azure Cost Suggestions section.
                <ul>
                    <li>This is a great place to start looking for cost savings. Dig into the suggestions. Use the provided link if more guidance is needed.</li>
                    <li>Use the Status field for each suggestion. If you determine that you should not implement the suggestion, change the Status to “Closed as Designed” to remove it from your list.</li>
                </ul>
            </p>
            <p>
                Scroll down farther to the Azure Most Expensive Resources section.
                <ul>
                    <li>Look particularly at your most expensive resources and see if you can identify savings. Use the provided link if more guidance is needed.</li>
                </ul>
            </p>
            <p>
                Note: Individual teams can setup content specific to just their team and application(s) in Onramp. If you haven't already done this, see{' '}
                <ExternalLink href="https://onramp.carmax.com/docs/default/component/devex-onramp/quickstart/">
                    Quickstart: Getting Started with Onramp.
                </ExternalLink>{' '} This content can include a variety of plugins, and links for each can be found in{' '}
                <ExternalLink href="https://onramp.carmax.com/docs/default/component/devex-onramp/plugins/">
                    Onramp Plugins.
                </ExternalLink>{' '}
            </p>
        </div>
    );
};

export const AzureCostMoreInfo = () => {
    return (
        <div>
            &nbsp;
            <Display level={3}>Links to other resources and advice for managing your Azure costs:</Display>
            <p>
                <ul>
                    <li>Onramp contains an {' '}<ExternalLink href="https://onramp.carmax.com/docs/default/component/cloudex-documentation/guides/cost-management-quickstart/"> Azure Cost Management Quickstart</ExternalLink>{' '} to “help you get access to and quickly start filtering cost management data provided by Azure.” It includes links for more detail and additional use cases.</li>
                    <li>Onramp contains more detailed information on{' '}<ExternalLink href="https://onramp.carmax.com/docs/default/component/cloudex-documentation/guides/azure-cost-savings">Azure Cost Savings.</ExternalLink>{' '}</li>
                    <li>Onramp’s Cloud Documentation includes information on {' '}<ExternalLink href="https://onramp.carmax.com/docs/default/component/cloudex-documentation/standards/tagging-standards/">Tagging Standards.</ExternalLink>{' '}</li>
                    <li>Advice on {' '}<ExternalLink href="https://carmax-my.sharepoint.com/:w:/p/262624/EaSzmzlK7DFJsQ3jUlBN1XsBU6ydKLJWtc9KavbXmvl4-g?e=RQ9eXG">Cost Dashboards in Azure</ExternalLink>{' '}</li>
                    <li>Microsoft’s {' '}<ExternalLink href="https://portal.azure.com/#view/Microsoft_Azure_Expert/AdvisorMenuBlade/~/Cost/category/Cost">Azure Advisor cost recommendations.</ExternalLink>{' '}</li>
                    <li>Microsoft’s {' '}<ExternalLink href="https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/costanalysis/openedBy/AzurePortal">Azure Cost Analysis page.</ExternalLink>{' '}</li>
                    <li>Vinny Morris and Nikhil Bharani presented “Azure FinOps Best Practices” during DevOps Days 2023. You can watch the {' '}<ExternalLink href="https://carmax.sharepoint.com/:v:/r/sites/DevOpsDays/Shared Documents/Presentations/DevOps Days 2023/Video Recordings/Azure FinOps Best Practices - Vinny Morris %26 Nikhil Bharani - CarMax.mp4?csf=1&web=1&e=WSHVng">presentation</ExternalLink>{' '} and view the {' '}<ExternalLink href="https://carmax.sharepoint.com/:p:/r/sites/HCSCloudTeam/_layouts/15/Doc.aspx?sourcedoc=%7B314DE02D-87B3-4B40-B161-95FAAFBE81BB%7D&file=DevOpsDaysPresentation.pptx&action=edit&mobileredirect=true">deck.</ExternalLink>{' '}</li>
                    <li>If you have reviewed this information and still need help, please fill out the {' '}<ExternalLink href="https://onramp.carmax.com/provision/cloud-need-help">Needs Help Request</ExternalLink>{' '} in Onramp.</li>
                </ul>
            </p>
        </div>
    );
};
