import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

type TextFieldProps = {
    label: string;
    value: string;
    helperText?: string;
    error?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
};

const useStyles = makeStyles<OnrampTheme>(theme => ({ default: { width: '100%', marginBottom: theme.spacing(0.5) } }));

export const TextField = ({ label, value, helperText, error, onChange, onBlur, disabled }: TextFieldProps) => {
    const styles = useStyles();

    return (
        <MaterialTextField
            className={styles.default}
            label={label}
            value={value}
            helperText={helperText}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            margin="normal"
            disabled={disabled}
        />
    );
};
