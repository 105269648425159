import { Config } from '@backstage/config';

export type ApigeePluginConfig = {
    developerApiUrl: string;
    baseUrl: string;
};

export function readApigeePluginConfig(config: Config): ApigeePluginConfig {
    const provider: ApigeePluginConfig = { developerApiUrl: '', baseUrl: '' };

    provider.developerApiUrl = config.getString('developerApi.url');
    provider.baseUrl = config.getString('app.baseUrl');

    return provider;
}
