import { lightTheme, darkTheme } from './OnrampTheme';
import { ThemeRegistration } from './types';

export const themes: ThemeRegistration[] = [
    {
        id: 'onramp-light',
        title: 'Light',
        variant: 'light',
        theme: lightTheme,
    },
    {
        id: 'onramp-dark',
        title: 'Dark',
        variant: 'dark',
        theme: darkTheme,
    },
];
