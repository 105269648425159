/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

export default function useDebouncedPersistence<T>(
    formState: T,
    isValid: boolean,
    persist: (values: Partial<T>) => void,
): boolean {
    const [persisting, setPersisting] = useState(false);

    const persistUpdatedValues = (values: Partial<T>, isValidInner: boolean) => {
        if (isValidInner) {
            persist(values);
            setPersisting(false);
        }
    };

    const debounced = useCallback(
        debounce((formStateInner: Partial<T>, isValidInner: boolean) => {
            persistUpdatedValues(formStateInner, isValidInner);
        }, 200),
        [],
    );

    useEffect(() => {
        if (isValid) {
            setPersisting(true);
            debounced(formState, isValid);
        }
    }, [formState]);

    return persisting;
}

