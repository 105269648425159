/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../theme';

const useStyles = makeStyles<OnrampTheme, { height: string | number; width: string | number }>(_theme => {
    return {
        svg: props => ({
            width: props.width,
            height: props.height,
        }),
        'cls-1': {
            fill: '#193560',
            fillRule: 'evenodd',
        },
        'cls-2': {
            fill: '#fed900',
        },
    };
});

export interface OnrampLogoIconProps {
    width?: number | string;
    height?: number | string;
}

const OnrampLogoIcon = (props: OnrampLogoIconProps) => {
    const classes = useStyles({ height: props.height ?? 'auto', width: props.width ?? 'auto' });

    return (
        <svg className={classes.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 758.14 915.8">
            <g id="light">
                <path
                    className={classes['cls-1']}
                    d="M379.07,115.65c-144.04,0-260.85,116.81-260.85,260.85s116.81,260.86,260.85,260.86c18.71,0,36.98-1.99,54.58-5.73-4.14,47.05-15.43,65.39-32.16,90.18,97.54-59.43,238.42-205.33,238.42-345.3S523.1,115.65,379.07,115.65m44.19,326.68h-88.38l11.91-96.37,2.88-23.32h58.78l2.88,23.32,11.91,96.37Zm-71.09-139.78l12.1-97.85h29.61l12.1,97.85h-53.81Zm-37.58,303.92l17.81-144.05h93.35l17.81,144.05c67.76-18.93,123.4-66.98,152.56-129.8l-72.43-130.7-114.8-207.18c-7.69-.96-15.49-1.55-23.38-1.76l5.88,47.58h-24.64l5.88-47.58c-7.89,.21-15.69,.8-23.37,1.76l-114.81,207.18-72.43,130.7c29.17,62.82,84.79,110.86,152.56,129.8ZM647.44,110.7h0c-147.6-147.6-389.13-147.6-536.73,0C-.46,221.86-27.9,386.31,28.37,523.78c62.01,151.43,237.69,279.02,350.69,392.02,116.58-116.57,301.99-250.38,357.88-411.02,46.42-133.38,16.57-287.99-89.51-394.08Z"
                />
                <g>
                    <path
                        className={classes['cls-2']}
                        d="M332.37,462.36l-17.81,144.1c1.91,.64,4.12,1.4,0,0,24.11,8.22,37.44,8.82,64.32,8.82,22.4,0,44.08-3.11,64.64-8.9l-17.8-144.02h-93.35Z"
                    />
                    <path
                        className={classes['cls-2']}
                        d="M391.37,184.44l-5.86-47.41c-2.2-.06-4.41-.1-6.62-.1s-4.2,.03-6.29,.08l-5.86,47.43h24.64Z"
                    />
                    <polygon
                        className={classes['cls-2']}
                        points="411.32 345.86 408.44 322.53 349.66 322.53 346.77 345.86 334.86 442.26 423.24 442.26 411.32 345.86"
                    />
                    <polygon
                        className={classes['cls-2']}
                        points="393.85 204.54 364.24 204.54 352.14 302.43 405.95 302.43 393.85 204.54"
                    />
                </g>
            </g>
        </svg>
    );
};

export default OnrampLogoIcon;
