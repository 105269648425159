import { Entity } from "@backstage/catalog-model";
import { createApiRef, DiscoveryApi, FetchApi, IdentityApi } from "@backstage/core-plugin-api";
import { ResponseError } from "@backstage/errors";
import crossFetch from "cross-fetch";
import { GroupMember } from "./api";

export interface KmxGroupEntitiesApi {
    upsertGroup(
        name: string,
        displayName: string,
        email: string,
        parent: string,
        memberList: GroupMember[],
        entityId?: string,
    ): Promise<Response>;
}

export class KmxGroupEntitiesClient implements KmxGroupEntitiesApi {
    private readonly discoveryApi: DiscoveryApi;
    private readonly identityApi: IdentityApi;
    private readonly fetchApi: FetchApi;

    constructor(options: {
        discoveryApi: DiscoveryApi;
        identityApi: IdentityApi;
        fetchApi?: FetchApi;
    }) {
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
        this.fetchApi = options.fetchApi || { fetch: crossFetch };
    }

    async upsertGroup(
        name: string,
        displayName: string,
        email: string,
        parent: string,
        memberList: GroupMember[],
        entityId?: string,
    ): Promise<Response> {
        const entity = this.createGroupEntity(name, displayName, email, parent, memberList);
        const dbEntity = { entity_type: 'group', entity_obj: entity} as any;
        if(entityId) dbEntity.entity_id = entityId;

        const response = await this.apiRequest("POST", `/`, JSON.stringify(dbEntity));
        return response;
    }

    private async apiRequest(
        method: string,
        path: string,
        body: string,
    ): Promise<Response> {
        const token = (await this.identityApi.getCredentials()).token;
        const url = `${await this.discoveryApi.getBaseUrl('kmx-entities')}${path}`;
        const headers: Record<string, string> = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        const response = await this.fetchApi.fetch(url, { method: method, body: body, headers: headers });

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response;
    }

    private createGroupEntity = (
        name: string,
        displayName: string,
        email: string,
        parent: string,
        memberList: GroupMember[],
    ) => {

        const editUrl = `/manageGroup?action=edit&groupName=${name}`;
        const membersWithRoles = memberList.map(x => {
            return { name: x.name, role: x.role };
        });
        const members = memberList.map(x => {
            return x.name;
        });

        const groupObject: Entity = {
            apiVersion: 'backstage.io/v1alpha1',
            kind: 'Group',
            metadata: {
                name: name,
                description: displayName !== '' ? displayName : name,
                annotations: {
                    "backstage.io/managed-by-location": "db:kmxentities",
                    "backstage.io/managed-by-origin-location": "db:kmxentities",
                    'backstage.io/edit-url': editUrl,
                    userRoles: JSON.stringify(membersWithRoles),
                },
            },
            spec: {
                type: members.length > 0 ? 'team' : 'group',
                parent: parent,
                children: [],
                members: members,
            },
        }

        if(displayName || email)
        {
            groupObject.spec!.profile = {};
            if(displayName) groupObject.spec!.profile.displayName = displayName;
            if(email) groupObject.spec!.profile.email = email;
        }

        return groupObject;
    };
};

export const kmxGroupEntitiesApiRef = createApiRef<KmxGroupEntitiesApi>({
    id: 'kmxgroupsentityapi',
});
