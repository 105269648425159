import {
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const azureDevOpsPlugin = createPlugin({
    id: 'backstage-azure-devops-plugin',
    routes: {
        root: rootRouteRef,
    },
});

export const EntityAzureDevOpsPipelineStatusContent = azureDevOpsPlugin.provide(
    createRoutableExtension({
        name: 'EntityAzureDevOpsPipelineStatusContent',
        component: () =>
            import('./pages/EntityAzureDevOpsPipelineStatusContent').then(
                m => m.EntityAzureDevOpsPipelineStatusContent,
            ),
        mountPoint: rootRouteRef,
    }),
);

