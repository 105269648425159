import React, { useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/lab';

type ApiResponseBannerProps = {
    statusCode: number;
    resetCode: React.Dispatch<React.SetStateAction<number>>;
    successMessage: string;
    failureMessage: string;
};

export const ApiResponseBanner = ({
    statusCode,
    resetCode,
    successMessage,
    failureMessage,
}: ApiResponseBannerProps) => {
    const [open, setOpen] = React.useState(false);

    const vertical = 'top';
    const horizontal = 'center';

    const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        resetCode(0);
    };

    useEffect(() => {
        if (statusCode !== 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [statusCode]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical, horizontal }}
            key={vertical + horizontal}
        >
            <div>
                {(statusCode === 200 || statusCode === 204) && (
                    <Alert variant="filled" onClose={handleClose} severity="success">
                        {' '}
                        {successMessage}{' '}
                    </Alert>
                )}
                {statusCode >= 300 && (
                    <Alert variant="filled" onClose={handleClose} severity="error">
                        {' '}
                        {failureMessage}{' '}
                    </Alert>
                )}
            </div>
        </Snackbar>
    );
};
