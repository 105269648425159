import React from 'react';
import { Row, Column, RadioButton } from '.';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

export interface RadioOption<TValue> {
    label: string;
    value: TValue;
    disabled?: boolean;
}

export interface RadioControlProps<TValue> {
    onChange: (value: TValue) => void;
    name: string;
    value: TValue;
    label: string;
    orientation?: 'vertical' | 'horizontal';
    valueToStringFn: (value: TValue) => string;
    options: RadioOption<TValue>[];
}

const useStyles = makeStyles<OnrampTheme>(theme => ({
    wrapper: {
        marginTop: theme.spacing(2),
    },
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    horizontalContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));

export function RadioControl<TValue>(props: RadioControlProps<TValue>) {
    const styles = useStyles();

    const renderRadioButtons = () => {
        return (
            <>
                {props.options.map(x => (
                    <RadioButton
                        key={props.valueToStringFn(x.value)}
                        onChange={() => props.onChange(x.value)}
                        checked={x.value === props.value}
                        label={x.label}
                        name={props.name}
                        value={props.valueToStringFn(x.value)}
                        disabled={x.disabled}
                    />
                ))}
            </>
        );
    };

    return (
        <>
            <Row className={styles.wrapper}>
                <Column span={6}>
                    <p> {props.label}</p>
                </Column>
                <Column span={6}>
                    {props.orientation === 'vertical' ? (
                        <div className={styles.verticalContainer}>{renderRadioButtons()}</div>
                    ) : (
                        <div className={styles.horizontalContainer}>{renderRadioButtons()}</div>
                    )}
                </Column>
            </Row>
        </>
    );
}
