import { useLayoutEffect, useRef } from 'react';

export default function ImportHorizon() {
    // Only import Horizon once. Avoids importing against during re-rendering.
    const isHorizonImported = useRef(false);

    useLayoutEffect(() => {
        (async function (): Promise<void> {
            if (isHorizonImported.current) {
                return;
            }

            // @ts-ignore:next-line
            // Scoped element registry is required for certain Horizon components.
            await import('@webcomponents/scoped-custom-element-registry');
            // Register all Horizon components once for the application.
            import('@horizon/alert/hzn-alert');
            import('@horizon/badge/hzn-badge');
            import('@horizon/button/hzn-button');
            import('@horizon/card/hzn-card');
            import('@horizon/columns/hzn-columns');
            import('@horizon/columns/hzn-column');
            import('@horizon/divider/hzn-divider');
            import('@horizon/heading/hzn-heading');
            import('@horizon/inline/hzn-inline');
            import('@horizon/select/hzn-select');
            import('@horizon/stack/hzn-stack');
            import('@horizon/text/hzn-text');
            import('@horizon/text-link/hzn-text-link');

            isHorizonImported.current = true;
        })();
    }, []);

    return null;
}
