import { useEffect } from 'react';
import { useLocation } from 'react-router';

export function useNavigateToHash() {
    const loc = useLocation();
    useEffect(() => {
        const hash = loc.hash.slice(1); // Remove the '#' character from the hash
        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(hash);

                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        }
    }, [loc.hash]);
}
