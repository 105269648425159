import {
    kmxProxyApiRef,
    KmxProxyClient,
    onrampApiRef,
    OnrampClient,
    userProfileDataApiRef,
    UserProfileDataClient
} from '.';
import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    createComponentExtension,
    identityApiRef,
    discoveryApiRef,
    configApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { observePageRouteRef, createPageRouteRef, designPageRouteRef } from './routes';

export const onrampPlugin = createPlugin({
    id: 'backstage-onramp-plugin',
    apis: [
        createApiFactory({
            api: onrampApiRef,
            deps: {
                catalogApi: catalogApiRef,
                identityApi: identityApiRef,
            },
            factory: ({ catalogApi, identityApi }) =>
                new OnrampClient({
                    catalogApi,
                    identityApi,
                }),
        }),
        createApiFactory({
            api: kmxProxyApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                identityApi: identityApiRef,
                configApi: configApiRef
            },
            factory: ({ discoveryApi, identityApi, configApi }) => new KmxProxyClient({ discoveryApi, identityApi, configApi }),
        }),
        createApiFactory({
            api: userProfileDataApiRef,
            deps: {
                discoveryApi: discoveryApiRef,
                identityApi: identityApiRef,
                configApi: configApiRef,
            },
            factory: (
                {
                    discoveryApi,
                    identityApi,
                    configApi
                }) => new UserProfileDataClient(
                    {
                        discoveryApi,
                        identityApi,
                        configApi
                    }),
        }),
    ],
    routes: {
        observe: observePageRouteRef,
        design: designPageRouteRef,
        create: createPageRouteRef,
    },
    featureFlags: [],
});

export const AccessLandingPage = onrampPlugin.provide(
    createRoutableExtension({
        name: 'AccessLandingPage',
        component: () => import('./components/landing/AccessLandingPage').then(m => m.AccessLandingPage),
        mountPoint: designPageRouteRef,
    }),
);

export const ObserveLandingPage = onrampPlugin.provide(
    createRoutableExtension({
        name: 'ObserveLandingPage',
        component: () => import('./components/landing/ObserveLandingPage').then(m => m.ObserveLandingPage),
        mountPoint: observePageRouteRef,
    }),
);

export const CreateLandingPage = onrampPlugin.provide(
    createRoutableExtension({
        name: 'CreateLandingPage',
        component: () => import('./components/landing/CreateLandingPage').then(m => m.CreateLandingPage),
        mountPoint: createPageRouteRef,
    }),
);

export const DesignLandingPage = onrampPlugin.provide(
    createRoutableExtension({
        name: 'DesignLandingPage',
        component: () => import('./components/landing/DesignLandingPage').then(m => m.DesignLandingPage),
        mountPoint: designPageRouteRef,
    }),
);

export const UtilitiesLandingPage = onrampPlugin.provide(
    createRoutableExtension({
        name: 'UtilitiesLandingPage',
        component: () => import('./components/landing/UtilitiesLandingPage').then(m => m.UtilitiesLandingPage),
        mountPoint: designPageRouteRef,
    }),
);

export const LinksPageContent = onrampPlugin.provide(
    createComponentExtension({
        name: 'LinksPageContent',
        component: {
            lazy: () => import('./components/home/LinksPageContent').then(m => m.LinksPageContent),
        },
    }),
);

export const HomePageContent = onrampPlugin.provide(
    createComponentExtension({
        name: 'HomePageContent',
        component: {
            lazy: () => import('./components/home/HomePageContent').then(m => m.HomePageContent),
        },
    }),
);

export const CustomAnnotationYamlExample = onrampPlugin.provide(
    createComponentExtension({
        name: 'CustomAnnotationYamlExample',
        component: {
            lazy: () =>
                import('./components/common/CustomAnnotationYamlExample').then(m => m.CustomAnnotationYamlExample),
        },
    }),
);

export const ControlWithHelp = onrampPlugin.provide(
    createComponentExtension({
        name: 'ControlWithHelp',
        component: {
            lazy: () => import('./components/common/ControlWithHelp').then(m => m.ControlWithHelp),
        },
    }),
);

