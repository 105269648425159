import { createExternalRouteRef, createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
    id: 'backstage-devopsmetrics-plugin-root',
});

export const serviceDetailsRouteRef = createSubRouteRef({
    id: 'backstage-devopsmetrics-plugin-service-details',
    parent: rootRouteRef,
    path: '/services/:id',
});

export const teamDetailsRouteRef = createSubRouteRef({
    id: 'backstage-devopsmetrics-plugin-team-details',
    parent: rootRouteRef,
    path: '/teams/:id',
});

export const dashboardRouteRef = createRouteRef({
    id: 'backstage-devopsmetrics-plugin-entity-dashboard',
});

export const legendRouteRef = createSubRouteRef({
    id: 'backstage-devopsmetrics-plugin-legend',
    parent: rootRouteRef,
    path: '/legend',
});

export const entityDetailsRouteRef = createExternalRouteRef({
    id: 'backstage-devopsmetrics-plugin-entity',
    params: ['name', 'namespace', 'kind'],
});
