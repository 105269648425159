import { createApiRef, ConfigApi, IdentityApi } from '@backstage/core-plugin-api';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { KmxProxyApi } from '../onrampCommon';
import { CostItem, CostPluginAnnotations } from './classes';
import { readCostPluginConfig } from './config';
import { CostDisplayData, CostGroup, CostIssues, CostlyResource, GroupedCostlyResources, GroupedIssues, IssuePatch } from './types';

export interface AzureCostApi {
    getCostData(annotations: CostPluginAnnotations): Promise<CostItem[]>;
    getCostPrograms(): Promise<CostGroup[]>;
    getCostByProgram(startDate: DateTime | undefined, endDate: DateTime | undefined, programs: string[]): Promise<CostDisplayData[]>;
    getCostByOwner(startDate: DateTime | undefined, endDate: DateTime | undefined, owners: string[]): Promise<CostDisplayData[]>;
    getCostByResource(startDate: DateTime | undefined, endDate: DateTime | undefined,  owners: string[], resources: string[]): Promise<CostDisplayData[]>;
    getCostlyResourcesByOwner(owners: string[]): Promise<GroupedCostlyResources[]>;
    getCostIssuesByOwner(owners: string[]): Promise<GroupedIssues[]>;
    updateIssueStatus(id: string, status: string): Promise<void>;
}

export class AzureCostClient implements AzureCostApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly configApi: ConfigApi;
    private readonly costApiBaseUrl: string;
    private readonly issuesApiBaseUrl: string;
    private readonly identityApi: IdentityApi;

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi; identityApi: IdentityApi }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.configApi = options.configApi;
        this.costApiBaseUrl = readCostPluginConfig(this.configApi).costUrl;
        this.issuesApiBaseUrl = readCostPluginConfig(this.configApi).issuesUrl;
        this.identityApi = options.identityApi;
    }

    private getSpan = (startDate: DateTime, endDate: DateTime): string => {
        const diffInDays = endDate.diff(startDate, 'days');
        const daysAsInt = diffInDays.days
        return daysAsInt < 92 ? '1d' : '1m';
    };

    async getCostPrograms(): Promise<CostGroup[]> {
        const programsApiUrl = this.costApiBaseUrl.concat('/','programs');
        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: programsApiUrl, method: 'GET' }
        );

        return await response.json() as CostGroup[];
    }

    async getCostByProgram(startDate: DateTime | undefined, endDate: DateTime | undefined, programs: string[]): Promise<CostDisplayData[]> {
        let costs: CostDisplayData[] = [];
        if(startDate !== undefined && endDate !== undefined && programs.length > 0)
        {
            const span = this.getSpan(startDate, endDate);
            const body = {startDate: startDate.toISODate(), endDate: endDate.toISODate(), span: span, programs: programs};
            const costApiUrl = `${this.costApiBaseUrl}/program-costs`;

            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: costApiUrl, method: 'POST', body: body }
            );

            const apiProgramCosts = await response.json() as any[];
            costs = apiProgramCosts.map((x: any) => ({ identifier: x.program, amount: x.resourceCost, date: DateTime.fromISO(x.measurementDate)}));
        }
        return costs;
    }

    async getCostByOwner(startDate: DateTime | undefined, endDate: DateTime | undefined, owners: string[]): Promise<CostDisplayData[]> {
        let costs: CostDisplayData[] = [];
        if(startDate !== undefined && endDate !== undefined && owners.length > 0)
        {
            const span = this.getSpan(startDate, endDate);
            const body = {startDate: startDate.toISODate(), endDate: endDate.toISODate(), span: span, owners: owners};
            const costApiUrl = `${this.costApiBaseUrl}/owner-costs`;

            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: costApiUrl, method: 'POST', body: body }
            );

            const apiOwnerCosts = await response.json() as any[];
            costs = apiOwnerCosts.map((x: any) => ({ identifier: x.owner, amount: x.resourceCost, date: DateTime.fromISO(x.measurementDate)}));
        }
        return costs;
    }

    async getCostByResource(startDate: DateTime | undefined, endDate: DateTime | undefined, owners: string[], resources: string[]): Promise<CostDisplayData[]> {
        const costs: CostDisplayData[] = [];
        if(startDate !== undefined && endDate !== undefined && owners.length > 0)
        {
            const span = this.getSpan(startDate, endDate);
            const body = {startDate: startDate.toISODate(), endDate: endDate.toISODate(), span: span, owners: owners, resourceTypes: resources};
            const costApiUrl = `${this.costApiBaseUrl}/resource-costs`;

            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: costApiUrl, method: 'POST', body: body }
            );

            const apiResourceCosts = await response.json() as any[];

            const groupedByResourceType = groupBy(apiResourceCosts, 'resourceType');
            Object.keys(groupedByResourceType).forEach((resourceType) => {
                const groupedByDate = groupBy(groupedByResourceType[resourceType], 'measurementDate');
                Object.keys(groupedByDate).forEach((measurementDate) => {
                    const localData = groupedByDate[measurementDate];
                    const sumAmount = localData.map(x => x.resourceCost).reduce((a,b) => a+b);
                    costs.push({ identifier: resourceType, amount: sumAmount, date: DateTime.fromISO(measurementDate)})
                });
            });
        }
        return costs;
    }

    async getCostData(annotations: CostPluginAnnotations): Promise<CostItem[]> {
        const costApiUrl = this.costApiBaseUrl.concat(
            '?subscriptionId=',
            annotations.subscriptionId,
            '&resourceGroupFilter=',
            annotations.resourceGroups,
            '&applicationName=',
            annotations.applicationName,
        );

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: costApiUrl, method: 'GET' }
        );

        const values: {
            cost: number;
            endDate: string;
            startDate: string;
        }[] = await response.json();

        return values.map(v => new CostItem(v.startDate, v.endDate, v.cost));
    }

    async getCostlyResourcesByOwner(owners: string[]): Promise<GroupedCostlyResources[]> {
        const data: GroupedCostlyResources[] = [];
        if(owners.length > 0)
        {
            const body = {owners: owners};
            const costlyApiUrl = `${this.costApiBaseUrl}/costly-resources`;

            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: costlyApiUrl, method: 'POST', body: body }
            );

            const apiResources: CostlyResource[] = await response.json();
            const groupedApiResources = groupBy(apiResources, 'owner');
            Object.keys(groupedApiResources).forEach((resOwner) => {
                const localData = groupedApiResources[resOwner];
                data.push({owner: resOwner, resources: localData});
            });
        }
        return data;
    }

    async getCostIssuesByOwner(owners: string[]): Promise<GroupedIssues[]> {
        const data: GroupedIssues[] = [];
        if(owners.length > 0)
        {
            const body = {owners: owners};
            const response = await this.kmxProxyApi.performProxiedRequest(
                'kmxproxy', { url: this.issuesApiBaseUrl, method: 'POST', body: body }
            );

            const apiIssues: CostIssues = await response.json();
            const groupedApiIssues = groupBy(apiIssues.issues, 'issueType');
            Object.keys(groupedApiIssues).forEach((issue) => {
                const localData = groupedApiIssues[issue];
                data.push({issueType: issue, resources: localData.sort((a, b) => a.owner.localeCompare(b.owner))});
            });
        }
        return data;
    }

    async updateIssueStatus(id: string, status: string): Promise<void> {
        const userId = await this.identityApi.getBackstageIdentity();
        const name = userId.userEntityRef.split('/')[1];
        const issueApiUrl = `${this.issuesApiBaseUrl}/${id}`;
        const patch: IssuePatch[] = [{ op: 'add',  path: '/status', value: status}, { op: 'add',  path: '/lastUpdatedBy', value: name }];
        await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy', { url: issueApiUrl, method: 'PATCH', body: patch, headers: {'content-type': 'application/json-patch+json'} }
        );
    }
}

export const azureCostApiRef = createApiRef<AzureCostApi>({
    id: 'azurecost',
});

