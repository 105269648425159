/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import type { ValidationInfo } from '../common/validationHelpers';
import { getValidationMessage } from '../common/validationHelpers';
import { prettify, validate } from 'validate.js';
import { union, uniq } from 'ramda';

type FieldName<T, V> = (keyof T & string) | (keyof V & string);
interface TextFieldProps {
    helperText?: string;
    value: string;
    error?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export default function useTextField<T, V>(
    value: string | undefined,
    fieldName: FieldName<T, V>,
    setValue: (v: string) => void,
    currentState: Partial<T>,
    validationInfo?: ValidationInfo<V>,
    helperText?: string,
    validationFieldNames?: FieldName<T, V>[],
): TextFieldProps {
    const [internalValue, setInternalValue] = useState(value);
    const [validation, setValidation] = useState({});
    const [touched, setTouched] = useState(false);

    const getValidation = (valueInner: string | undefined) => {
        return validate({ ...currentState, [fieldName]: valueInner }, validationInfo?.rules, {
            prettify: (attr: keyof V & string) =>
                (validationInfo?.attributeAliases && validationInfo?.attributeAliases[attr]) || prettify(attr),
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(e.target.value);
        setValue(e.target.value);
        setValidation(getValidation(e.target.value));
    };

    const handleBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
        setTouched(true);
        setValidation(getValidation(value));
    };

    useEffect(() => {
        if (internalValue !== value) {
            // If the value was updated outside of the TextField (e.g. as part of another state update),
            // it could affect the validation status. When this happens, update the internal value and re-evaluate
            // the validation rules.
            setInternalValue(internalValue);
            setValidation(getValidation(value));
        }
    }, [value]);

    useEffect(() => {
        setValidation(getValidation(value));
    }, [value, validationFieldNames ? validationFieldNames.map(vf => (currentState as any)[vf]).join('--') : '']);

    useEffect(() => {
        if (value !== '') {
            const newValidationInfo = getValidation(value);
            // If the initial state causes a validation error, update the state appropriately so that the validation
            // message is visible without first requiring the text field to be focused.
            if (newValidationInfo) {
                setValidation(newValidationInfo);
                setTouched(true);
            }
        }
    }, []);

    const validationMessages = uniq(union([fieldName], validationFieldNames ?? []))
        .map(fn => getValidationMessage(validation, fn, { [fn]: fieldName === fn ? touched : true }))
        .filter(x => x !== undefined);

    const props = {
        helperText: validationMessages.length === 0 ? helperText : validationMessages.join('. '),
        value: value ?? '',
        error: validationMessages.length > 0 ? true : undefined,
        onChange: handleChange,
        onBlur: handleBlur,
    };

    return props;
}

