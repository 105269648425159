import { Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    gotoSpecificStep,
    selectCurrentSteps,
    selectMaxStepIndex,
} from '../../redux/slices/inProgressProvisioningRequestsSlice';
import { selectCurrentStepIndex } from '../../redux/slices/inProgressProvisioningRequestsSlice/selectors';

export function ProgressTracker() {
    const currentStepIndex = useAppSelector(selectCurrentStepIndex);
    const steps = useAppSelector(selectCurrentSteps);
    const maxStepIndex = useAppSelector(selectMaxStepIndex);
    const dispatch = useAppDispatch();

    return (
        <Stepper orientation="vertical" nonLinear>
            {steps.map((step, index) => (
                <Step
                    key={index}
                    completed={currentStepIndex !== index && maxStepIndex >= index}
                    active={currentStepIndex === index}
                    expanded={false}
                    disabled={maxStepIndex < index}
                    sx={{ cursor: 'pointer' }}
                >
                    <StepLabel
                        error={false}
                        onClick={() => {
                            if (maxStepIndex >= index) {
                                dispatch(gotoSpecificStep(index));
                            }
                        }}
                    >
                        {step.description}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
