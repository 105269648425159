/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Button } from '@mui/material';
import { Link, EmptyState } from '@backstage/core-components';
import { CustomAnnotationYamlExample } from '../../onrampCommon';

type MissingAnnotationProps = {
    annotation1: string;
    annotation2: string;
    moreInfoLink: string;
};

export const MissingAnnotation = ({ annotation1, annotation2, moreInfoLink }: MissingAnnotationProps) => {
    const description = (
        <>
            The <code>{annotation1}</code> and <code>{annotation2}</code> annotations are missing. You need to add those
            annotations to your component if you want to enable this tool.
        </>
    );
    return (
        <EmptyState
            missing="field"
            title="Missing Annotation"
            description={description}
            action={
                <>
                    <CustomAnnotationYamlExample
                        annotations={[
                            { name: annotation1, value: 'value' },
                            { name: annotation2, value: 'value' },
                        ]}
                    />
                    <Button color="primary" component={Link} to={moreInfoLink}>
                        Read more
                    </Button>
                </>
            }
        />
    );
};
