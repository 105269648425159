import { ConfigApi, createApiRef } from "@backstage/core-plugin-api";
import { KmxProxyApi } from "../../onrampCommon";

export interface KmxTechdocsApi {
    canClone(repository: string, branch?: string): Promise<Response>;
    canGenerate(repository: string, instanceId?: string): Promise<Response>;
    canMkdocs(repository: string, instanceId?: string): Promise<Response>;
    canPublish(repository: string, instanceId?: string): Promise<Response>;
    canCleanup(repository: string, instanceId?: string): Promise<Response>;
}

export class KmxTechdocsClient implements KmxTechdocsApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly techdocsApiBaseUrl: string;
    private readonly retryTimeout: number;
    private readonly retryLimit: number;

    constructor(options: {
        kmxProxyApi: KmxProxyApi;
        configApi: ConfigApi;
    }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.techdocsApiBaseUrl = options.configApi.getString('techdocsFunctions.baseUrl');
        this.retryLimit = options.configApi.getNumber('serviceRetry.lowLimit');
        this.retryTimeout = options.configApi.getNumber('serviceRetry.lowTimeout');
    }

    async canClone(repository: string, branch?: string): Promise<Response> {
        const path = '/api/CanClone'
        const body = {
            Repository: repository,
            Branch: branch ?? "",
        };
        const metricsUrl = `${this.techdocsApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: body}
        return await this.kmxProxyApi.performProxiedRequest('techdocsfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async canGenerate(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/CanGenerate'
        const body = { Repository: repository, InstanceId: instanceId };
        const metricsUrl = `${this.techdocsApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: body}
        return await this.kmxProxyApi.performProxiedRequest('techdocsfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async canMkdocs(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/CanMkdocs'
        const body = { Repository: repository, InstanceId: instanceId };
        const metricsUrl = `${this.techdocsApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: body}
        return await this.kmxProxyApi.performProxiedRequest('techdocsfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async canPublish(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/CanPublish'
        const body = { Repository: repository, InstanceId: instanceId };
        const metricsUrl = `${this.techdocsApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: body}
        return await this.kmxProxyApi.performProxiedRequest('techdocsfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }

    async canCleanup(repository: string, instanceId?: string): Promise<Response> {
        const path = '/api/CanCleanup'
        const body = { Repository: repository, InstanceId: instanceId };
        const metricsUrl = `${this.techdocsApiBaseUrl}${path}`;

        const request = { url: metricsUrl, method: 'POST', body: body}
        return await this.kmxProxyApi.performProxiedRequest('techdocsfunctionsproxy', request, this.retryLimit, this.retryTimeout);
    }
}

export const kmxTechdocsApiRef = createApiRef<KmxTechdocsApi>({
    id: 'kmxtechdocsapi',
});


