export interface CheckboxProps {
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function useCheckbox(value: boolean | undefined, setValue: (v: boolean) => void): CheckboxProps {
    const handleChange = (_e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(!value);
    };

    const props = {
        checked: value === undefined ? false : value.valueOf(),
        onChange: handleChange,
    };

    return props;
}

