import { RadarEntry } from '@backstage/plugin-tech-radar';
import { EntryData } from './types';
import { getRadarItems } from './util';

const radarEntries: EntryData[] = [
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'rest',
        title: 'REST',
        links: [
            {
                title: 'REST',
                url: 'https://restfulapi.net/',
            },
        ],
        description: 'An architectural style for building web services.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'bffs',
        title: 'BFFs',
        links: [
            {
                title: 'BFFs',
                url: 'https://samnewman.io/patterns/architectural/bff/',
            },
        ],
        description: 'A Backend For Frontend is a web service tightly coupled to supporting UI experiences.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'microservices',
        title: 'Microservices',
        links: [
            {
                title: 'Microservices',
                url: 'https://microservices.io/',
            },
        ],
        description: `An architectural style that structures an application as a collection of services that are 
            independently deployable, loosely coupled, and organized around a business capability.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'swagger',
        title: 'Open API',
        links: [
            {
                title: 'Open API',
                url: 'https://www.openapis.org/',
            },
        ],
        description: `A specification for a machine-readable interface definition language for describing, producing, 
            consuming and visualizing HTTP web services.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'ddd',
        title: 'Domain-Driven Design',
        links: [
            {
                title: 'Domain-Driven Design',
                url: 'https://learn.microsoft.com/en-us/dotnet/architecture/microservices/microservice-ddd-cqrs-patterns/ddd-oriented-microservice',
            },
        ],
        description: `DDD organizes code so it is aligned to the business problems using a aubiquitous language.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'designfirst',
        title: 'Design-First Approach to API Development',
        links: [
            {
                title: 'Design-First Approach to API Development',
                url: 'https://www.infoq.com/articles/design-first-api-development/',
            },
        ],
        description: `APIs are defined in an iterative way that both humans and computers can understand.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'iac',
        title: 'Infrastructure as Code',
        links: [
            {
                title: 'Infrastructure as Code',
                url: 'https://www.redhat.com/en/topics/automation/what-is-infrastructure-as-code-iac',
            },
        ],
        description: `IaC is the managing and provisioning of infrastructure through code instead of through manual processes.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'mfe',
        title: 'Micro Frontends',
        links: [
            {
                title: 'Micro Frontends',
                url: 'https://martinfowler.com/articles/micro-frontends.html',
            },
        ],
        description:
            'An architectural style where independently deliverable frontend applications are composed into a greater whole',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'websockets',
        title: 'Web Sockets',
        links: [
            {
                title: 'Web Sockets',
                url: 'https://en.wikipedia.org/wiki/WebSocket',
            },
        ],
        description:
            'A standardized way for the web server to send content to the client without being first requested by the client.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'mtls',
        title: 'mTLS',
        links: [
            {
                title: 'mTLS',
                url: 'https://www.cloudflare.com/learning/access-management/what-is-mutual-tls/',
            },
        ],
        description: `mTLS ensures that the parties at each end of a network connection are who they claim to be by 
            verifying that they both have the correct private key. The information within their respective TLS 
            certificates provides additional verification.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'sso',
        title: 'SSO',
        links: [
            {
                title: 'SSO',
                url: 'https://www.cloudflare.com/learning/access-management/what-is-sso/',
            },
        ],
        description: `SSO is a cloud security technology that reduces all user application logins to one login for greater security and convenience`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'asa',
        title: 'Application Security Assessments',
        links: [
            {
                title: 'Application Security Assessments',
                url: 'https://security.carmax.com/docs/design-patterns/application-security-architectures/application-security-architecture-asa-overview/',
            },
        ],
        description:
            'An ASA document describes what parts of your application are sensitive, business critical, and helps enumerate security controls to ensure that data is protected.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'dataencryption',
        title: 'Data Encryption',
        links: [
            {
                title: 'Data Encryption',
                url: 'https://security.carmax.com/docs/design-patterns/security-standards/encryption-standard/',
            },
        ],
        description: `Follow the recommended security standards for protecting CarMax data.`,
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'devops',
        title: 'DevOps',
        links: [
            {
                title: 'DevOps',
                url: 'https://en.wikipedia.org/wiki/DevOps',
            },
        ],
        description:
            'A methodology that integrates and automates the work of software development (Dev) and IT operations (Ops).',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'eda',
        title: 'Event-Driven Architecture',
        links: [
            {
                title: 'Event-Driven Architecture',
                url: 'https://learn.microsoft.com/en-us/azure/architecture/guide/architecture-styles/event-driven',
            },
        ],
        description:
            'An architecture that consists of event producers that generate a stream of events, and event consumers that listen for the events.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'tdd',
        title: 'Test-Driven Development',
        links: [
            {
                title: 'Test-Driven Development',
                url: 'https://en.wikipedia.org/wiki/Test-driven_development',
            },
        ],
        description:
            'A software development process relying on software requirements being converted to test cases before software is fully developed.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'cqrs',
        title: 'CQRS',
        links: [
            {
                title: 'CQRS',
                url: 'https://learn.microsoft.com/en-us/azure/architecture/patterns/cqrs',
            },
        ],
        description:
            'Command and Query Responsibility Segregation is a pattern that separates read and write operations for a data store.',
    },
    {
        ringId: 'adopt',
        date: new Date('2023-02-28'),
        key: 'goldenpaths',
        title: 'Golden Paths',
        links: [
            {
                title: 'Onramp',
                url: 'https://onramp.carmax.com/apx',
            },
            {
                title: 'Spotify blog',
                url: 'https://engineering.atspotify.com/2020/08/how-we-use-golden-paths-to-solve-fragmentation-in-our-software-ecosystem/',
            },
        ],
        description:
            'An opinionated and supported path to building software.  It may be a tutorial or an automated solution.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'ephemeralenvironments',
        title: 'Ephemeral Azure Environments',
        links: [
            {
                title: 'Ephemeral Azure Environments',
                url: 'https://humanitec.com/blog/ephemeral-environments-for-testing',
            },
        ],
        description: 'Short-lived testing environments that reflect only the changes you are interested in testing.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'containerizeddevelopment',
        title: 'Containerized Development',
        links: [
            {
                title: 'Containerized Development',
                url: 'https://www.ibm.com/topics/containerization',
            },
        ],
        description: `Containerization is the packaging of software code with just the operating system libraries 
            and dependencies required to run the code to create a single lightweight container.`,
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'eventstreaming',
        title: 'Event Streaming',
        links: [
            {
                title: 'Event Streaming',
                url: 'https://developer.confluent.io/patterns/event-stream/event-stream/',
            },
        ],
        description: 'Ordered events written to a durable log.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'graphql',
        title: 'GraphQL',
        links: [
            {
                title: 'GraphQL',
                url: 'https://graphql.org/',
            },
        ],
        description:
            'A query language for APIs and a runtime for fulfilling those queries with existing data or external resolvers.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'grpc',
        title: 'gRPC',
        links: [
            {
                title: 'gRPC',
                url: 'https://grpc.io/',
            },
        ],
        description: 'A high performance Remote Procedure Call (RPC) framework.',
    },
    {
        ringId: 'trial',
        date: new Date('2023-02-28'),
        key: 'adr',
        title: 'ADRs',
        links: [
            {
                title: 'ADRs',
                url: 'https://adr.github.io/',
            },
        ],
        description:
            'A justified software design choice that addresses a functional or non-functional requirement that is architecturally significant.',
    },
    {
        ringId: 'assess',
        date: new Date('2023-02-28'),
        key: 'modulefed',
        title: 'Module Federation',
        links: [
            {
                title: 'Module Federation',
                url: 'https://www.syncfusion.com/blogs/post/what-is-webpack-module-federation-and-why-does-it-matter.aspx',
            },
        ],
        description:
            'Webpack Module Federation loads code dynamically at runtime to reduce the frequency of code duplication, and the host application only downloads the missing dependencies.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'funcshostapi',
        title: 'Functions hosting HTTP APIs',
        links: [
            {
                title: 'HTTP Triggered Functions',
                url: 'https://learn.microsoft.com/en-us/azure/azure-functions/functions-bindings-http-webhook-trigger?tabs=in-process%2Cfunctionsv2&pivots=programming-language-csharp',
            },
        ],
        description:
            'There are subtle differences between Azure Functions and other hosting options for HTTP endpoints.  Prefer App Services or Container Apps.',
    },
    {
        ringId: 'hold',
        date: new Date('2023-02-28'),
        key: 'monolithfrontend',
        title: 'Monolithic Front End',
        links: [
            {
                title: 'Monolithic Front End',
                url: 'https://www.digitalocean.com/blog/monolithic-vs-microservice-architecture',
            },
        ],
        description: 'Prefer composable micro frontends',
    },
];

export const techniques = (): RadarEntry[] => getRadarItems(radarEntries, 'techniques');
