import React from 'react';
import { EntityLayout, EntitySwitch, isComponentType, isKind } from '@backstage/plugin-catalog';
import { Entity } from '@backstage/catalog-model';

import type { TabContent } from './EntityPageTabs';
import {
    ApiDefinitionTab,
    ApiOverviewTab,
    ApiTab,
    DefaultOverviewTab,
    DevOpsMetricsTab,
    DocsTab,
    DomainOverviewTab,
    GroupDependenciesTab,
    GroupOverviewTab,
    LibraryDependenciesTab,
    MetricsTab,
    PipelineStatusTab,
    SiteOrServiceDependenciesTab,
    SiteOrServiceOverviewTab,
    SystemDependenciesTab,
    SystemOverviewTab,
    UserOverviewTab,
    AdrTab,
    PagerDutyTab,
} from './EntityPageTabs';

type PageContent = { if?: (entity: Entity) => boolean; element: JSX.Element };

const renderTabContent = (...tabs: TabContent[]): JSX.Element => {
    return (
        <>
            {tabs.map((tab, i) => (
                <EntityLayout.Route key={i} path={tab.path} title={tab.title} if={tab.if}>
                    {tab.element}
                </EntityLayout.Route>
            ))}
        </>
    );
};

const makePage = (...tabs: TabContent[]): JSX.Element => <EntityLayout>{renderTabContent(...tabs)}</EntityLayout>;

const pages: PageContent[] = [
    {
        if: isComponentType('service'),
        element: makePage(
            SiteOrServiceOverviewTab,
            MetricsTab,
            PipelineStatusTab,
            ApiTab,
            SiteOrServiceDependenciesTab,
            DocsTab,
            AdrTab,
            DevOpsMetricsTab,
            PagerDutyTab,
        ),
    },
    {
        if: isComponentType('website'),
        element: makePage(
            SiteOrServiceOverviewTab,
            MetricsTab,
            PipelineStatusTab,
            SiteOrServiceDependenciesTab,
            DocsTab,
            AdrTab,
            DevOpsMetricsTab,
            PagerDutyTab,
        ),
    },
    {
        if: isComponentType('library'),
        element: makePage(DefaultOverviewTab, PipelineStatusTab, LibraryDependenciesTab, DocsTab, AdrTab, DevOpsMetricsTab),
    },
    { if: isKind('api'), element: makePage(ApiOverviewTab, ApiDefinitionTab) },
    { if: isKind('group'), element: makePage(GroupOverviewTab, GroupDependenciesTab) },
    { if: isKind('user'), element: makePage(UserOverviewTab) },
    { if: isKind('system'), element: makePage(SystemOverviewTab, SystemDependenciesTab, DocsTab, AdrTab,) },
    { if: isKind('domain'), element: makePage(DomainOverviewTab, DocsTab) },
    { element: makePage(DefaultOverviewTab, DocsTab, AdrTab) },
];

export const EntityPageContent = (
    <EntitySwitch>
        {pages.map((p, i) => (
            <EntitySwitch.Case key={i} if={p.if} children={p.element} />
        ))}
    </EntitySwitch>
);

