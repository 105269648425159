import { enterpriseMessagingApiRef, EnterpriseMessagingClient } from './apis';

import { configApiRef, createApiFactory, createPlugin } from '@backstage/core-plugin-api';
import { kmxProxyApiRef } from '../onrampCommon';

export const enterpriseMessagingPlugin = createPlugin({
    id: 'backstage-enterprise-messaging-plugin',
    apis: [
        createApiFactory({
            api: enterpriseMessagingApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi }) =>
                new EnterpriseMessagingClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
    externalRoutes: {},
    routes: {},
});

