import {
    Content,
    ContentHeader,
    CreateButton,
    PageWithHeader,
    TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
    CatalogFilterLayout,
    EntityKindPicker,
    EntityListProvider,
    EntityTagPicker,
    EntityTypePicker,
    UserListFilterKind,
    UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { groupColumns } from '../components/catalog/groupColumns';

/**
 * Props for root catalog pages.
 *
 * @public
 */

export interface DefaultCatalogPageProps {
    initiallySelectedFilter?: UserListFilterKind;
    initialKind?: string;
    tableOptions?: TableProps<CatalogTableRow>['options'];
}

export function KmxGroupCatalogPage(props: DefaultCatalogPageProps) {
    const { initiallySelectedFilter = 'owned', initialKind = 'group' } = props;
    const orgName = useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

    return (
        <PageWithHeader title={`${orgName} Groups`} themeId="home">
            <EntityListProvider>
                <Content>
                    <ContentHeader title="Groups">
                        <CreateButton title="Create Group" to="/manageGroup?action=new" />
                    </ContentHeader>
                    <CatalogFilterLayout>
                        <CatalogFilterLayout.Filters>
                            <EntityKindPicker initialFilter={initialKind} hidden={true}/>
                            <EntityTypePicker />
                            <UserListPicker initialFilter={initiallySelectedFilter} />
                            <EntityTagPicker />
                        </CatalogFilterLayout.Filters>
                        <CatalogFilterLayout.Content>
                            <CatalogTable
                                columns={groupColumns}
                                actions={[]}
                            />
                        </CatalogFilterLayout.Content>
                    </CatalogFilterLayout>
                </Content>
            </EntityListProvider>
        </PageWithHeader>
    );
}
