import {
    RadarRing,
    RadarQuadrant,
    RadarEntry,
    TechRadarLoaderResponse,
    TechRadarApi,
} from '@backstage/plugin-tech-radar';
import { languages, techniques, tools, platforms } from "./index";

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'Adopt', color: '#93c47d' });
rings.push({ id: 'trial', name: 'Trial', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'Assess', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'Hold', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'languages', name: 'Languages and Frameworks' });
quadrants.push({ id: 'platforms', name: 'Platforms' });
quadrants.push({ id: 'techniques', name: 'Techniques' });
quadrants.push({ id: 'tools', name: 'Tools' });

const entries = new Array<RadarEntry>();

entries.push(...languages());
entries.push(...tools());
entries.push(...techniques());
entries.push(...platforms());

export const productDeveloperRadarData: TechRadarLoaderResponse = {
    entries,
    quadrants,
    rings,
};

export class ProductDeveloperTechRadarApi implements TechRadarApi {
    async load() {
        return productDeveloperRadarData;
    }
}
