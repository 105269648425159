import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { HEALTH_CHECK_ANNOTATION } from './constants';
import { ServiceStatus } from './models';
import {
    StatusOK,
    StatusError,
    StatusWarning,
    StatusAborted,
} from '@backstage/core-components';

export const isPluginApplicableToEntity = (entity: Entity) => {
    const annotations = entity.metadata?.annotations ?? {};
    const annotationNames = Object.keys(annotations);
    return (
        annotationNames.filter(a => a.indexOf(HEALTH_CHECK_ANNOTATION) !== -1)
            .length > 0
    );
};

export const renderStatus = (
    status: ServiceStatus,
    children: React.ReactChild,
): React.ReactElement => {
    switch (status) {
        case 'healthy':
            return <StatusOK>{children}</StatusOK>;
        case 'down':
            return <StatusError>{children}</StatusError>;
        case 'degraded':
            return <StatusWarning>{children}</StatusWarning>;
        default:
            return <StatusAborted>{children}</StatusAborted>;
    }
};

