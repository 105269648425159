import { useEffect, useState } from 'react';
import type { ValidationInfo } from '../common/validationHelpers';
import { getValidationMessage } from '../common/validationHelpers';
import { validate, prettify } from 'validate.js';
import type { LookupListItem } from '../components/lookups';

export interface LookupProps<U> {
    id: string;
    selection: U | undefined;
    onSelect: (selection: U) => void;
    onBlur: () => void;
    validationText?: string;
}

export function useGenericLookup<T, U, V>(
    value: U | undefined,
    fieldName: (keyof T & string) | (keyof V & string),
    setValue: (v: U) => void,
    currentState: T,
    validationInfo?: ValidationInfo<V>,
): LookupProps<U> {
    const [touched, setTouched] = useState(false);
    const [validation, setValidation] = useState({});

    const getValidation = (selection: U | undefined) => {
        return validate({ ...currentState, [fieldName]: selection }, validationInfo?.rules, {
            prettify: (attr: keyof V & string) =>
                (validationInfo?.attributeAliases && validationInfo?.attributeAliases[attr]) || prettify(attr),
        });
    };

    const handleSelect = (selection: U) => {
        setValue(selection);
        setValidation(getValidation(selection));
    };

    const handleBlur = () => {
        setTouched(true);
        setValidation(getValidation(value));
    };

    useEffect(() => {
        // Sometimes validation is dependent on the rest of the form state, so we account for this here.
        setValidation(getValidation(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentState]);

    const validationText = getValidationMessage(validation, fieldName, { [fieldName]: touched });

    const props: LookupProps<U> = {
        id: fieldName,
        selection: value,
        onSelect: handleSelect,
        onBlur: handleBlur,
        validationText,
    };

    return props;
}

export function useLookupMany<T, V>(
    value: LookupListItem[] | undefined,
    fieldName: (keyof T & string) | (keyof V & string),
    setValue: (v: LookupListItem[]) => void,
    currentState: T,
    validationInfo?: ValidationInfo<V>,
): LookupProps<LookupListItem[]> {
    return useGenericLookup<T, LookupListItem[], V>(value, fieldName, setValue, currentState, validationInfo);
}

export default function useLookup<T, V>(
    value: LookupListItem | undefined,
    fieldName: (keyof T & string) | (keyof V & string),
    setValue: (v: LookupListItem) => void,
    currentState: T,
    validationInfo?: ValidationInfo<V>,
): LookupProps<LookupListItem> {
    return useGenericLookup<T, LookupListItem, V>(value, fieldName, setValue, currentState, validationInfo);
}
