import { useSearchModal } from '@backstage/plugin-search';
import React from 'react';
import { useEffect } from 'react';

export function SearchWithShortcutKey() {
    const { setOpen } = useSearchModal(true);
    useEffect(() => {
        const keyDownFn = function (evt: KeyboardEvent) {
            evt = evt || window.event;
            if (evt.ctrlKey && (evt.key === 'K' || evt.key === 'k')) {
                setOpen(true);
            }
        };
        document.addEventListener('keydown', keyDownFn);
        return () => {
            document.removeEventListener('keydown', keyDownFn);
        };
    }, []);

    return <></>;
}
