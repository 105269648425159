import React from 'react';
import _unescape from 'lodash/unescape';
import { Link } from '@backstage/core-components';
import { Divider, ListItem, ListItemText, ListItemIcon, Box, Chip } from '@mui/material';

type StackOverflowArticleSearchResultListItemProps = {
    result: any;
    icon?: React.ReactNode;
};

export const StackOverflowArticleSearchResultListItem = (props: StackOverflowArticleSearchResultListItemProps) => {
    const { location, title, text, score, tags } = props.result;

    return (
        <Link to={location}>
            <ListItem alignItems="center">
                {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
                <Box flexWrap="wrap">
                    <ListItemText
                        primaryTypographyProps={{ variant: 'h6' }}
                        primary={_unescape(title)}
                        secondary={`Author: ${text}`}
                    />
                    <Chip label={`Score: ${score}`} size="small" />
                    {tags && tags.map((tag: string) => <Chip key={tag} label={`Tag: ${tag}`} size="small" />)}
                </Box>
            </ListItem>
            <Divider />
        </Link>
    );
};
