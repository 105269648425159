import React, { ComponentProps } from 'react';
import { SignInPage } from '@backstage/core-components';
import { discoveryApiRef, IdentityApi, useApi, configApiRef } from '@backstage/core-plugin-api';
import { carmaxAuthApiRef } from '../../apis';
import { fullStoryApiRef } from '../../plugins/fullStory/apis';
import { parseEntityRef } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
const AUTHENTICATION_ENVIRONMENT_CONFIGURATION_KEY = 'auth.environment';
const AUTHENTICATION_ENVIRONMENT_OFFLINE = 'offline';
const AUTHENTICATION_PROVIDERS_GUEST_ID = 'guest';
const FULLSTORY_SIGNING_KEY = "fullStory.signingKey";

const oauth2Provider = {
    id: 'oauth2-provider',
    title: 'Ping Identity',
    message: 'Sign in using CarMax Ping',
    apiRef: carmaxAuthApiRef,
};

// Parses supplied JWT token and returns the payload
function parseJwt(token: string): { exp: number } {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                // eslint-disable-next-line
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

// Returns milliseconds until the supplied JWT token expires
function msUntilExpiry(token: string): number {
    const payload = parseJwt(token);
    const remaining = new Date(payload.exp * 1000).getTime() - new Date().getTime();
    return remaining;
}

// Calls the specified url regularly using an auth token to set a token cookie
// to authorize regular HTTP requests when loading techdocs
export async function setTokenCookie(url: string, identityApi: IdentityApi) {
    const { token } = await identityApi.getCredentials();
    if (!token) {
        return;
    }

    await fetch(url, {
        mode: 'cors',
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    // Call this function again a few minutes before the token expires
    const ms = msUntilExpiry(token) - 4 * 60 * 1000;
    setTimeout(
        () => {
            setTokenCookie(url, identityApi);
        },
        ms > 0 ? ms : 10000,
    );
}

type OnrampSignInPageProps = Omit<ComponentProps<typeof SignInPage>, 'provider'>;

export const OnrampSignInPage = (props: OnrampSignInPageProps) => {
    const configApi = useApi(configApiRef);
    const discoveryApi = useApi(discoveryApiRef);
    const fsClient = useApi(fullStoryApiRef);
    const catalogApi = useApi(catalogApiRef);

    const authEnvironment = configApi.getOptionalString(AUTHENTICATION_ENVIRONMENT_CONFIGURATION_KEY);
    const isOffline = authEnvironment === AUTHENTICATION_ENVIRONMENT_OFFLINE;

    // NOTE: offline-mode uses a named "guest" provider as a `string` while all other modes
    //       default to the provider as an `object`. The single provider behaves differently
    //       than with multiple providers as an `Array`.
    //       - Offline and the provider `Array`: the array supports `string` providers,
    //         displays the provider left-aligned to the page and "guest" allows the user to
    //         click to enter without a pop-up window.
    //       - The single provider (production): provides a sign-on experience centered on
    //         the page with an automatic pop-up window for authentication.
    // TIP: offline is specifically useful for testing pages in Onramp that do not
    //      require connectivity like the home page and newsletter.
    if (isOffline) {
        return (
            <SignInPage
                {...props}
                auto
                providers={[AUTHENTICATION_PROVIDERS_GUEST_ID]}
            />
        );
    }

    const key = configApi.getOptionalString(FULLSTORY_SIGNING_KEY) ?? "";

    return (
        <SignInPage
            {...props}
            auto
            provider={oauth2Provider}
            onSignInSuccess={async (identityApi: IdentityApi) => {
                setTokenCookie(await discoveryApi.getBaseUrl('cookie'), identityApi);

                props.onSignInSuccess(identityApi);

                const profile = await identityApi.getProfileInfo();
                const identity = await identityApi.getBackstageIdentity();
                const entityRef = parseEntityRef(identity.userEntityRef);
                const userEntity = await catalogApi.getEntityByRef(entityRef)!;
                const g = (str: string | number | boolean) => new Uint8Array([...unescape(encodeURIComponent(str))].map(c => c.charCodeAt(0))),
                    k = g(key),
                    m = g(identity.userEntityRef),
                    c = await crypto.subtle.importKey('raw', k, { name: 'HMAC', hash: 'SHA-256' }, true, ['sign']),
                    s = await crypto.subtle.sign('HMAC', c, m);
                const userId = btoa(String.fromCharCode(...new Uint8Array(s)))
                fsClient.identifyUser(userId,
                    profile.displayName ?? "",
                    userEntity?.metadata?.annotations?.["graph.microsoft.com/user-principal-name"] ?? "");
            }}
        />
    );
};
