import { Config } from '@backstage/config';

import { PLUGIN_SHORTCUTS_KEY } from './shortcuts';

export const PLUGIN_KEYS = {
    shortcuts: PLUGIN_SHORTCUTS_KEY,
};

const PLUGINS_CONFIG_KEY = "app.plugins";
const PLUGIN_CONFIG_KEY_NAME = "key";
const BACKEND_PLUGINS_CONFIG_KEY = "backend.plugins";

export const getAllPluginsConfiguration = (config: Config) => {
    return config.getOptionalConfigArray(PLUGINS_CONFIG_KEY) || [];
};

export const getPluginConfiguration = (config: Config, key: string) => {
    const pluginsConfig = getAllPluginsConfiguration(config);

    const pluginConfig = pluginsConfig.find((pluginConfig) => pluginConfig.getString(PLUGIN_CONFIG_KEY_NAME) == key);

    return pluginConfig;
};

export const getFullPluginConfigurationKeyPath = (key: string) => {
    return `${PLUGINS_CONFIG_KEY}[${PLUGIN_CONFIG_KEY_NAME}: "${key}"]`;
}

export const getAppDisabledPluginKeys = (config: Config) => {
    const pluginsConfig = getAllPluginsConfiguration(config);

    // find any disabled plugins
    const disabledPluginConfigKeys = pluginsConfig.filter((pluginConfig) => {
        const isEnabledConfigValue = pluginConfig.getOptionalBoolean('enabled')
        const isEnabled = isEnabledConfigValue === undefined ? true : Boolean(isEnabledConfigValue)

        return !isEnabled;
    }).map((pluginConfig) => pluginConfig.getString('key'));

    return disabledPluginConfigKeys;
};

export const getBackendDisabledPluginKeys = (config: Config) => {

    const pluginsConfig = config.getOptionalConfigArray(BACKEND_PLUGINS_CONFIG_KEY) || [];

    // find any disabled plugins
    const disabledPluginConfigKeys = pluginsConfig.filter((pluginConfig) => {
        const isEnabledConfigValue = pluginConfig.getOptionalBoolean('enabled')
        const isEnabled = isEnabledConfigValue === undefined ? true : Boolean(isEnabledConfigValue)

        return !isEnabled;
    }).map((pluginConfig) => pluginConfig.getString('key'));

    return disabledPluginConfigKeys;
};

export const isPluginEnabled = (config: Config, key: string) => {
    const pluginConfig = getPluginConfiguration(config, key)

    // default to enabled if not configured
    if (!pluginConfig) {
        return true;
    }

    const isPluginEnabledConfigValue = pluginConfig.getOptionalBoolean('enabled')

    // default to enabled if undefined
    if (isPluginEnabledConfigValue || isPluginEnabledConfigValue === undefined) {
        return true;
    }

    return false;
};
