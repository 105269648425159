import React from 'react';
import { Typography } from '@mui/material';

export interface BodyProps {
    className?: string;
    children: React.ReactNode;
}

export function Body(props: BodyProps) {
    return (
        <Typography variant="body1" className={props.className}>
            {props.children}
        </Typography>
    );
}
