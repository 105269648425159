import { ConfigApi, createApiRef } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { readAppInsightsPluginConfig } from './config';
import {
    MetricType,
    MetricsApiDataItem,
    MetricsApiDependencyItem,
    MetricsApiNamedDataItem,
    RequestRateResponseItem,
} from './types';
import { KmxProxyApi } from '../onrampCommon';

export interface AppInsightsApi {
    getMetrics(
        metricType: MetricType,
        appInsightsAppId: string,
        reqsIsSuccess?: boolean,
    ): Promise<MetricsApiDataItem[]>;

    getRequestMetrics(
        metricType: MetricType,
        appInsightsAppId: string,
        reqsIsSuccess?: boolean,
    ): Promise<MetricsApiNamedDataItem[]>;

    getDependencies(
        appInsightsAppId: string,
    ): Promise<MetricsApiDependencyItem[]>;

    getDependencyList(appInsightsAppId: string): Promise<string[]>;

    addClientApp ( appInsightsName: string, appId: string, apiKey: string): Promise<number>;
}

export class AppInsightsClient implements AppInsightsApi {
    private readonly kmxProxyApi: KmxProxyApi;
    private readonly configApi: ConfigApi;

    constructor(options: { kmxProxyApi: KmxProxyApi; configApi: ConfigApi }) {
        this.kmxProxyApi = options.kmxProxyApi;
        this.configApi = options.configApi;
    }

    async getMetrics(
        metricType: MetricType,
        appInsightsAppId: string,
        reqsIsSuccess?: boolean,
    ): Promise<MetricsApiDataItem[]> {
        const metricsPath = `/${metricType}?appInsightsAppId=${appInsightsAppId}${
            reqsIsSuccess === false ? '&isSuccess=false' : ''
        }`;

        const json = (await this.getMetricsApiResponse(metricsPath)) as Record<
            string,
            number
        >;

        return Object.entries(json).map(([date, numberValue]) => ({
            date: new Date(date),
            numberValue,
        }));
    }

    async getRequestMetrics(
        metricType: MetricType,
        appInsightsAppId: string,
        reqsIsSuccess?: boolean,
    ): Promise<MetricsApiNamedDataItem[]> {
        const metricsPath = `/${metricType}?appInsightsAppId=${appInsightsAppId}${
            reqsIsSuccess === false ? '&isSuccess=false' : ''
        }`;

        const results = await this.getMetricsApiResponse(metricsPath) as RequestRateResponseItem[];

        return results.map((result) => ({
            name: result.resultCode,
            date: new Date(result.timeGenerated),
            numberValue: result.count,
        }));
    }

    async getDependencies(
        appInsightsAppId: string,
    ): Promise<MetricsApiDependencyItem[]> {
        const dependenciesPath = `/dependencies/counts?appInsightsAppId=${appInsightsAppId}`;

        return await this.getMetricsApiResponse(dependenciesPath);
    }

    async getDependencyList(appInsightsAppId: string): Promise<string[]> {
        const dependenciesPath = `/dependencies?appInsightsAppId=${appInsightsAppId}`;

        return await this.getMetricsApiResponse(dependenciesPath);
    }

    async addClientApp(appInsightsName: string, appId: string, apiKey: string): Promise<number> {
        const metricsBaseUrl = readAppInsightsPluginConfig(this.configApi).metricsUrl;
        const metricsUrl = `${metricsBaseUrl}/clients`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy',
            {
                url: metricsUrl,
                method: 'PUT',
                body: {applicationInsightsName: appInsightsName, applicationId: appId, apiKey: apiKey}
            }
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return response.status;
    }

    private async getMetricsApiResponse(path: string): Promise<any> {
        const metricsBaseUrl = readAppInsightsPluginConfig(
            this.configApi,
        ).metricsUrl;

        const metricsUrl = `${metricsBaseUrl}${path}`;

        const response = await this.kmxProxyApi.performProxiedRequest(
            'kmxproxy',
            {
                url: metricsUrl,
                method: 'GET',
            }
        );

        if (!response.ok) {
            throw await ResponseError.fromResponse(response);
        }

        return await response.json();
    }
}

export const appInsightsApiRef = createApiRef<AppInsightsApi>({
    id: 'appinsights',
});

