import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    Grid,
    IconButton,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    CircularProgress,
} from '@mui/material';
import { InfoCard } from '@backstage/core-components';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { GroupMember, kmxGroupsApiRef } from '../../apis/api';
import { NewEmployeeRow, EditEmployeeRow } from '../..';
import { useApi } from '@backstage/core-plugin-api';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    tableHead: {
        fontSize: '1rem',
        lineHeight: 1.5,
        fontWeight: 400,
        color: 'black',
    },
    tableHeadAction: {
        marginTop: '1px',
    },
});

type GroupMembersTableProps = {
    memberList: GroupMember[];
    onSaveChangesButtonClick: () => Promise<void>;
    setMemberList: React.Dispatch<React.SetStateAction<GroupMember[]>>;
    isSaving?: boolean;
    isValid?: boolean;
};

export const GroupMembersTable = ({
    memberList,
    onSaveChangesButtonClick,
    setMemberList,
    isSaving,
    isValid,
}: GroupMembersTableProps) => {
    const classes = useStyles();
    const [employeeList, setEmployeeList] = useState<string[]>([]);
    const [isAddingNewEmployee, setIsAddingNewEmployee] = useState<boolean>(false);

    const kmxGroupsApi = useApi(kmxGroupsApiRef);

    useEffect(() => {
        const fetchEmployees = async () => {
            const employeeNames = await kmxGroupsApi.getEmployees();
            setEmployeeList(employeeNames);
        };
        fetchEmployees();
    }, [kmxGroupsApi]);

    const onAddEmployeeButtonClick = useCallback(
        (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>, edit: boolean) => {
            setIsAddingNewEmployee(edit);
        },
        [],
    );

    return (
        <InfoCard
            title="Group Members"
            action={
                <IconButton className={classes.tableHead} onClick={e => onAddEmployeeButtonClick(e, true)}>
                    <AddOutlined />
                    <Typography className={classes.tableHeadAction}>Add Member</Typography>
                </IconButton>
            }
        >
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Name</TableCell>
                                    <TableCell className={classes.tableHead} align="right">
                                        Position
                                    </TableCell>
                                    <TableCell className={classes.tableHead} align="right">
                                        Edit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(memberList ?? []).map((member, index) => {
                                    return (
                                        <EditEmployeeRow
                                            key={index}
                                            rowIndex={index}
                                            member={member}
                                            memberList={memberList}
                                            setMemberList={setMemberList}
                                        />
                                    );
                                })}
                                {isAddingNewEmployee && (
                                    <NewEmployeeRow
                                        employeeList={employeeList}
                                        onComplete={newEmployee => {
                                            setIsAddingNewEmployee(false);
                                            if (newEmployee) {
                                                setMemberList([...memberList, newEmployee]);
                                            }
                                        }}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px' }}>
                        <Button
                            onClick={onSaveChangesButtonClick}
                            variant="contained"
                            color="primary"
                            disabled={isAddingNewEmployee || isSaving || !isValid}
                        >
                            Save Team Changes
                        </Button>
                        {isSaving && <CircularProgress size="24px" />}
                    </div>
                </Grid>
            </Grid>
        </InfoCard>
    );
};
