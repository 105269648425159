import {
    configApiRef,
    createApiFactory,
    createComponentExtension,
    createPlugin,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { kmxProxyApiRef } from '../onrampCommon';
import { azureCostApiRef, AzureCostClient } from './api';

export const azureCostPlugin = createPlugin({
    id: 'backstage-azure-cost-plugin',
    apis: [
        createApiFactory({
            api: azureCostApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
                identityApi: identityApiRef
            },
            factory: ({ kmxProxyApi, configApi, identityApi }) =>
                new AzureCostClient({
                    kmxProxyApi,
                    configApi,
                    identityApi,
                }),
        }),
    ],
});

export const AzureCostCard = azureCostPlugin.provide(
    createComponentExtension({
        name: 'AzureCostCard',
        component: {
            lazy: () =>
                import('./components/AzureCostCard').then(m => m.AzureCostCard),
        },
    }),
);

