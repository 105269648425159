import React from 'react';
import { InfoCard } from '@backstage/core-components';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { CostlyResource, GroupedCostlyResources } from '../types';

import ExpandMoreOutlined from '@mui/icons-material/ExpandMoreOutlined';

type AzureCostlyResourcesAccordionProps = {
    costlyResources: GroupedCostlyResources[];
};

export const AzureCostlyResourcesAccordion = ({ costlyResources }: AzureCostlyResourcesAccordionProps) => {
    return (
        <InfoCard title="Azure Most Expensive Resources">
            <Grid item xs={12}>
                {(costlyResources ?? []).map((costRes, index) => {
                    return (
                        <Accordion id={costRes.owner} key={`s${index.toString()}`}>
                            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                                <Typography>{`${costRes.owner} - ${costRes.resources.length} found`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Resource Group</TableCell>
                                                    <TableCell>Resource Item</TableCell>
                                                    <TableCell>Daily Cost</TableCell>
                                                    <TableCell>30-day Cost</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {((costRes.resources as CostlyResource[]) ?? []).map((res, ind) => {
                                                    return (
                                                        <TableRow key={ind.toString()}>
                                                            <TableCell>{res.resourceGroup}</TableCell>
                                                            <TableCell>
                                                                <Link href={res.resourceUrl}>{res.name}</Link>
                                                            </TableCell>
                                                            <TableCell>${res.resourceCost}</TableCell>
                                                            <TableCell>${res.resourceCost30Days}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Grid>
        </InfoCard>
    );
};
