import React from 'react';
import { LinkText } from '../common';

type LinkProps = {
    href: string;
    children: React.ReactNode;
};

export function ExternalLink(props: LinkProps) {
    return (
        <LinkText href={props.href} target="_blank">
            {props.children}
        </LinkText>
    );
}

