import { devOpsMetricsApiRef, DevOpsMetricsClient, devOpsMetricsPluginApiRef, DevOpsMetricsPlugin } from './apis';
import {
    rootRouteRef,
    serviceDetailsRouteRef,
    teamDetailsRouteRef,
    dashboardRouteRef,
    legendRouteRef,
    entityDetailsRouteRef,
} from './routes';
import { configApiRef, createApiFactory, createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';
import { kmxProxyApiRef, onrampApiRef } from '../onrampCommon';

export const devOpsMetricsPlugin = createPlugin({
    id: 'backstage-devopsmetrics-plugin',
    apis: [
        createApiFactory({
            api: devOpsMetricsApiRef,
            deps: {
                onrampApi: onrampApiRef,
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ onrampApi, kmxProxyApi, configApi }) =>
                new DevOpsMetricsClient({
                    onrampApi,
                    kmxProxyApi,
                    configApi,
                }),
        }),
        createApiFactory({
            api: devOpsMetricsPluginApiRef,
            deps: {
                configApi: configApiRef,
            },
            factory: ({ configApi }) => new DevOpsMetricsPlugin({ configApi }),
        }),
    ],
    externalRoutes: {
        entity: entityDetailsRouteRef,
    },
    routes: {
        root: rootRouteRef,
        serviceDetails: serviceDetailsRouteRef,
        teamDetails: teamDetailsRouteRef,
        entityDashboard: dashboardRouteRef,
        legend: legendRouteRef,
    },
});

export const DevOpsMetricsRootPage = devOpsMetricsPlugin.provide(
    createRoutableExtension({
        name: 'DevOpsMetricsRootPage',
        component: () => import('./pages/DevOpsMetricsRootPage').then(m => m.DevOpsMetricsRootPage),
        mountPoint: rootRouteRef,
    }),
);

export const EntityDevOpsMetricsContent = devOpsMetricsPlugin.provide(
    createRoutableExtension({
        name: 'EntityDevOpsMetricsContent',
        component: () => import('./pages/EntityDevOpsMetricsContent').then(m => m.EntityDevOpsMetricsContent),
        mountPoint: dashboardRouteRef,
    }),
);
