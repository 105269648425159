import {
    rootRouteRef
} from './routes';
import {
    configApiRef,
    createApiFactory,
    createPlugin,
    createRoutableExtension,
} from '@backstage/core-plugin-api';

import { kmxProxyApiRef } from '../onrampCommon';
import { compressionApiRef, CompressionClient } from "../compression/api";

export const compressionPlugin = createPlugin({
    id: 'backstage-compression-plugin',
    apis: [
        createApiFactory({
            api: compressionApiRef,
            deps: {
                kmxProxyApi: kmxProxyApiRef,
                configApi: configApiRef,
            },
            factory: ({ kmxProxyApi, configApi, }) =>
                new CompressionClient({
                    kmxProxyApi,
                    configApi,
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const CompressionHomePage = compressionPlugin.provide(
    createRoutableExtension({
        name: 'CompressionHomePage',
        component: () =>
            import('./pages/CompressionHomePage').then(m => m.CompressionHomePage),
        mountPoint: rootRouteRef,
    }),
);

