import React from 'react';
import { Row, Column, RadioButton } from '.';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

export interface YesNoControlProps {
    onChange: (value: boolean) => void;
    name: string;
    value?: boolean;
    label: string;
    orientation?: 'vertical' | 'horizontal';
    disabled?: boolean;
    yesLabel?: string;
    noLabel?: string;
}

const useStyles = makeStyles<OnrampTheme>(theme => ({
    wrapper: {
        marginTop: theme.spacing(2),
    },
    verticalContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

export function YesNoControl(props: YesNoControlProps) {
    const styles = useStyles();
    const renderRadioButtons = () => {
        return (
            <>
                <RadioButton
                    onChange={() => props.onChange(true)}
                    checked={props.value === true}
                    label={props.yesLabel ?? 'Yes'}
                    name={props.name}
                    value="true"
                    disabled={props.disabled}
                />
                <RadioButton
                    onChange={() => props.onChange(false)}
                    checked={props.value === false}
                    label={props.noLabel ?? 'No'}
                    name={props.name}
                    value="false"
                    disabled={props.disabled}
                />
            </>
        );
    };

    return (
        <>
            <Row className={styles.wrapper}>
                <Column span={8}>
                    <p> {props.label}</p>
                </Column>
                <Column span={4}>
                    {props.orientation === 'vertical' ? (
                        <div className={styles.verticalContainer}>{renderRadioButtons()}</div>
                    ) : (
                        renderRadioButtons()
                    )}
                </Column>
            </Row>
        </>
    );
}
