import {
    rootRouteRef,
} from './routes';
import {
    createApiFactory,
    createPlugin,
    createRoutableExtension,
    discoveryApiRef,
    identityApiRef,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { kmxGroupsApiRef, KmxGroupsClient } from './apis/api';
import { kmxGroupEntitiesApiRef, KmxGroupEntitiesClient } from './apis/customEntityApi';

export const kmxGroupsPlugin = createPlugin({
    id: 'backstage-kmxgroups-plugin',
    apis: [
        createApiFactory({
            api: kmxGroupsApiRef,
            deps: {
                catalogApi: catalogApiRef,
            },
            factory: ({ catalogApi, }) =>
                new KmxGroupsClient({
                    catalogApi,
                }),
        }),
        createApiFactory({
            api: kmxGroupEntitiesApiRef,
            deps: {
                identityApi: identityApiRef,
                discoveryApi: discoveryApiRef,
            },
            factory: ({ identityApi, discoveryApi}) =>
                new KmxGroupEntitiesClient({
                    identityApi,
                    discoveryApi
                }),
        }),
    ],
    routes: {
        root: rootRouteRef,
    },
});

export const KmxGroupsManagePage = kmxGroupsPlugin.provide(
    createRoutableExtension({
        name: 'KmxGroupsManagePage',
        component: () =>
            import('./pages/KmxGroupsPage').then(
                m => m.KmxGroupsPage,
            ),
        mountPoint: rootRouteRef,
    }),
);