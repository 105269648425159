import React from 'react';
import { Button } from '@mui/material';
import { Modal } from './Modal';
import { makeStyles } from '@mui/styles';

type DialogProps = {
    cancelButtonLabel?: string;
    acceptButtonLabel?: string;
    acceptAction: () => void;
    cancelAction: () => void;
    children: React.ReactNode;
};

const useStyles = makeStyles(_theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
}));

export const Dialog = ({ children, acceptAction, cancelAction, acceptButtonLabel, cancelButtonLabel }: DialogProps) => {
    const styles = useStyles();

    return (
        <Modal>
            {children}

            <div className={styles.buttonContainer}>
                <Button onClick={cancelAction}>{cancelButtonLabel || 'Cancel'}</Button>
                <Button onClick={acceptAction}>{acceptButtonLabel || 'OK'}</Button>
            </div>
        </Modal>
    );
};
