import { createRouteRef, createSubRouteRef, createExternalRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
    id: 'backstage-servicehealth-plugin',
});

export const detailsRouteRef = createSubRouteRef({
    id: 'backstage-servicehealth-plugin-details',
    parent: rootRouteRef,
    path: '/:name',
});

export const entityDetailsRouteRef = createExternalRouteRef({
    id: 'backstage-servicehealth-plugin-entity',
    params: ['name', 'namespace', 'kind'],
});

