import { Card } from '@mui/material';
import React from 'react';
import { ControlWithHelp } from '../../../onrampCommon';
import { Display } from '../typography';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { OnrampTheme } from '../../../../theme';

type WorkflowCardProps = {
    children: React.ReactNode;
    className?: string;
    title?: string;
    helpContent?: React.ReactNode;
};

const useStyles = makeStyles<OnrampTheme>(theme => ({
    card: {
        height: '100%',
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        background: 'transparent',
    },
    title: {
        marginTop: '0px',
    },
}));

export const WorkflowCard = ({ title, className, helpContent, children, ...cardProps }: WorkflowCardProps) => {
    const styles = useStyles();

    return (
        <Card variant="outlined" className={classNames(styles.card, className)} {...cardProps}>
            <ControlWithHelp helpContent={helpContent}>
                <Display level={4} className={styles.title}>
                    {title}
                </Display>
            </ControlWithHelp>
            {children}
        </Card>
    );
};
