import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { Grid } from '@mui/material';
import { Content, PageWithHeader, ContentHeader } from '@backstage/core-components';
import { GroupMember, kmxGroupsApiRef } from '../apis/api';
import { kmxGroupEntitiesApiRef } from '../apis/customEntityApi';
import { ApiResponseBanner } from '../../../components/common';
import { JsonObject } from '@backstage/types';
import { GroupMetadata, ValidEmailPattern, ValidGroupNamePattern } from '../components/group/GroupMetadata';
import { GroupMembersTable } from '../components/group/GroupMembersTable';
import { groupApiFailureMessage, groupApiSuccessMessage } from '../constants';
import { Entity } from '@backstage/catalog-model';

export type GroupInfo = {
    name: string;
    email: string;
    description: string;
    parent: string;
    entityId: string;
};

export const KmxGroupsPage = () => {
    // page control stuff
    const [isAddingNewGroup, setIsAddingNewGroup] = useState<boolean>(true);
    const [isSaving, setIsSaving] = useState(false);
    const [apiStatusCode, setApiStatusCode] = useState<number>(0);
    // groups stuff
    const [group, setGroup] = useState<GroupInfo>({
        name: '',
        email: '',
        description: '',
        parent: '',
        entityId: '',
    });
    const [memberList, setMemberList] = useState<GroupMember[]>([]);
    const [groupEntities, setGroupEntities] = useState<Entity[]>([]);
    const [hierarchy, setHierarchy] = useState<string[]>([]);

    const kmxGroupsApi = useApi(kmxGroupsApiRef);
    const kmxGroupsEntityApi = useApi(kmxGroupEntitiesApiRef);

    const localUpdateGroup = useMemo(() => {
        return (updates: Partial<GroupInfo>) => {
            setGroup(existing => ({ ...existing, ...updates }));
        };
    }, [setGroup]);

    // load up all existing entities
    useEffect(() => {
        const fetchData = async () => {
            const apiGroups = await kmxGroupsApi.getGroupObjects();
            setGroupEntities(apiGroups);
        };
        // eslint-disable-next-line no-console
        fetchData().catch(console.error);
    }, [kmxGroupsApi]);

    // manage new or edit mode and page info
    useEffect(() => {
        const mode = kmxGroupsApi.getQueryStringValue('action', window.location.search);
        if (!mode) window.history.replaceState(null, '', '?action=new');
        setIsAddingNewGroup((mode as string) === 'new' ? true : false);
    }, [isAddingNewGroup, kmxGroupsApi, localUpdateGroup]);

    useEffect(() => {
        const fetchData = async () => {
            let groupName = kmxGroupsApi.getQueryStringValue('groupName', window.location.search) as string;
            let hierarchyLevels: string[] = [];

            if (groupName) {
                localUpdateGroup({ name: groupName });

                while (groupName) {
                    const groupByName = await kmxGroupsApi.getGroupByName(groupName);
                    if (groupByName) {
                        hierarchyLevels = [...hierarchyLevels, groupName];
                        groupName = (groupByName.spec?.parent ?? '').toString();
                    }
                }
            }
            setHierarchy(hierarchyLevels.slice(1));
        };
        // eslint-disable-next-line no-console
        fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kmxGroupsApi]);

    // get group from API and set team state object
    useEffect(() => {
        const updateGroup = (currentValue: GroupInfo, updateValue: Entity): GroupInfo => {
            let updatedTeam = { ...currentValue };

            const annotations = updateValue.metadata?.annotations as Record<string, string>;
            const entityId = annotations['carmax.com/db-entity-id'];
            if (entityId) updatedTeam = { ...updatedTeam, entityId: entityId };

            const spec = updateValue.spec as JsonObject;
            if (spec) updatedTeam = { ...updatedTeam, parent: spec.parent as string };

            const profile = spec.profile as JsonObject;
            if (profile.displayName) updatedTeam = { ...updatedTeam, description: profile.displayName as string };
            if (profile.email) updatedTeam = { ...updatedTeam, email: profile.email as string };

            return updatedTeam;
        };

        const fetchGroup = async () => {
            const apiGroup = await kmxGroupsApi.getGroupByName(group.name);
            if (apiGroup) {
                setGroup(existing => updateGroup(existing, apiGroup));
            }
        };

        fetchGroup();
    }, [group.name, kmxGroupsApi]);

    // get team members based on team name
    // this is a separate step since we want to show users if a group name is in use already
    useEffect(() => {
        const fetchMembers = async () => {
            const memberNames = await kmxGroupsApi.getGroupMembers(group.name);
            setMemberList(memberNames);
        };

        if (group.name) {
            fetchMembers();
        }
    }, [kmxGroupsApi, group.name]);

    const onSaveChangesButtonClick = useCallback(async () => {
        setIsSaving(true);
        const response = await kmxGroupsEntityApi.upsertGroup(
            group.name,
            group.description,
            group.email,
            group.parent,
            memberList,
            group.entityId,
        );
        setApiStatusCode(response.status);
        setIsSaving(false);
        // users tend to save, then edit, then save. If this is a new group,
        // we write the entity_id back to the object to prevent it from creating
        // a new object each time they hit save
        if (response.status === 200 || response.status === 204) {
            const currentGroup = { ...group };
            const body = await response.json();
            currentGroup.entityId = body.entity_id;
            setGroup(currentGroup);
        }
    }, [kmxGroupsEntityApi, memberList, group]);

    const isValidForSave = () => {
        const nameOk = ValidGroupNamePattern.test(group.name);
        const emailOk = group.email.length === 0 || ValidEmailPattern.test(group.email);
        const parentIsOk = group.parent.length > 0;
        return nameOk && emailOk && parentIsOk;
    };

    return (
        <PageWithHeader title="Onramp" themeId="tool">
            <Content>
                <ContentHeader title={`${isAddingNewGroup === true ? 'New' : 'Edit'} Group`} />
                <ApiResponseBanner
                    statusCode={apiStatusCode}
                    resetCode={setApiStatusCode}
                    successMessage={groupApiSuccessMessage}
                    failureMessage={groupApiFailureMessage}
                />
                <Grid container>
                    <Grid item xs={3}>
                        <GroupMetadata
                            isAddingNewGroup={isAddingNewGroup}
                            groupEntities={groupEntities}
                            group={group}
                            groupHierarchy={hierarchy}
                            updateGroup={localUpdateGroup}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <GroupMembersTable
                            memberList={memberList}
                            onSaveChangesButtonClick={onSaveChangesButtonClick}
                            setMemberList={setMemberList}
                            isSaving={isSaving}
                            isValid={isValidForSave()}
                        />
                    </Grid>
                </Grid>
            </Content>
        </PageWithHeader>
    );
};
